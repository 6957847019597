import React from 'react'
import _ from 'lodash'
import moment from 'moment'
import { Query } from 'react-apollo'
import { withRouter } from 'react-router-dom'
import Intents from 'views/Coach/Dashboard/Intents'
import CompareIntents from 'views/Coach/Dashboard/CompareIntents'
import Entities from 'views/Coach/Dashboard/Entities'
import IntentConfidence, { IntentConfidenceNavbarHeader } from 'views/Coach/Dashboard/IntentConfidence'
import EntityConfidence, { EntityConfidenceNavbarHeader } from 'views/Coach/Dashboard/EntityConfidence'
import IntentConfidenceDeviation from 'views/Coach/Dashboard/IntentConfidenceDeviation'
import IntentConfidenceDistribution from 'views/Coach/Dashboard/IntentConfidenceDistribution'
import IntentConfidenceRisks from 'views/Coach/Dashboard/IntentConfidenceRisks'
import IntentMismatchProbability, { IntentMismatchProbabilityNavbarHeader } from 'views/Coach/Dashboard/IntentMismatchProbability'
import IgnoredTestScripts from 'views/Coach/Dashboard/IgnoredTestScripts'
import CompareUtterancesNew from 'views/Coach/Dashboard/CompareUtterancesNew'
import CompareUtterancesMissing from 'views/Coach/Dashboard/CompareUtterancesMissing'
import CompareUtterancesOther from 'views/Coach/Dashboard/CompareUtterancesOther'
import CompareUtterancesSame from 'views/Coach/Dashboard/CompareUtterancesSame'
import CompareUtterancesAll from 'views/Coach/Dashboard/CompareUtterancesAll'
import IgnoredTestScriptSteps from 'views/Coach/Dashboard/IgnoredTestScriptSteps'
import NotResolvedToAnyIntent from 'views/Coach/Dashboard/NotResolvedToAnyIntent'
import ResolvedToWrongIntent from 'views/Coach/Dashboard/ResolvedToWrongIntent'
import JustExpectedEntities from 'views/Coach/Dashboard/JustExpectedEntities'
import JustActualEntitiesChecked from 'views/Coach/Dashboard/JustActualEntitiesChecked'
import JustActualEntitiesNotChecked from 'views/Coach/Dashboard/JustActualEntitiesNotChecked'
import CompareIntentWrongNowCorrect from 'views/Coach/Dashboard/CompareIntentWrongNowCorrect'
import CompareIntentCorrectNowWrong from 'views/Coach/Dashboard/CompareIntentCorrectNowWrong'
import CompareIntentWrongNowWrongOther from 'views/Coach/Dashboard/CompareIntentWrongNowWrongOther'
import CompareIntentWrongNowWrongSame from 'views/Coach/Dashboard/CompareIntentWrongNowWrongSame'
import EntityConfidenceRisks from 'views/Coach/Dashboard/EntityConfidenceRisks'
import IntentMismatchProbabilities from 'views/Coach/Dashboard/IntentMismatchProbabilities'
import Utterance from 'views/Coach/Dashboard/Utterance'
import NavbarHeader from 'components/Header/NavbarHeader'
import GridItem from 'components/Grid/GridItem'
import {NlpSessionProgressIcon} from 'views/TestSessions/helper'
import Chip from 'components/Chip/Chip'
import GridContainer from 'components/Grid/GridContainer'
import LoadingIndicator from 'components/Icon/LoadingIndicator'
import { TESTSESSION_QUERY } from 'views/TestSessions/gql'

const navbarName = (header) => {
  if (_.isFunction(header)) return withRouter((props) => <NavbarHeader text={header(props.match.params)}/>)
  else return () => <NavbarHeader text={header} />
}
const navbarNameTestSession = withRouter((props) => (props.match.params.testSessionId && <Query query={TESTSESSION_QUERY} variables={{id: props.match.params.testSessionId}}>{({loading, data}) => {
  if(loading) return <LoadingIndicator />
  return <GridContainer>
    <GridItem md={false} lg={false} noPaddingRight>
    <NlpSessionProgressIcon testSession={data.testsession} />
    </GridItem>
    <GridItem md={10} lg={11} noPaddingLeft smallPadding>
      <NavbarHeader text={`${data && data.testsession ? data.testsession.testProject.name + ' / ' + moment(data.testsession.updatedAt).format('lll') : ''}`}/>
    </GridItem>
    <GridItem md={12}>
      {data.testsession.namespace && <Chip namespace variant="info" href={`/namespaces/${data.testsession.namespace}`}  tooltip={`Namespaces: ${data.testsession.namespace}`} label={data.testsession.namespace} />}
      <Chip nlp tooltip={'Test Type'} variant="info" label="NLP"/>
    </GridItem>
  </GridContainer>
}}
</Query>) || null)


const coachRoutes = [
  {
    path: '/nlp/projects/view/:testProjectId/results/:testSessionId/dashboard',
    breadcrumb: 'Overview',
    navbarName: navbarNameTestSession
  },
  {
    path: '/nlp/projects/view/:testProjectId/results/:testSessionId/advice',
    breadcrumb: 'Suggestion',
    navbarName: navbarNameTestSession
  },
  {
    path: '/nlp/projects/view/:testProjectId/results/:testSessionId/progress',
    breadcrumb: 'Training Progress',
    navbarName: navbarNameTestSession
  },
  {
    path: '/nlp/projects/view/:testProjectId/results/:testSessionId/mismatchprobability',
    breadcrumb: 'Mismatch Probability Risks',
    navbarName: navbarNameTestSession
  },
  {
    path: '/nlp/projects/view/:testProjectId/results/:testSessionId/confusionmatrix/',
    breadcrumb: 'Confusion Matrix',
    navbarName: navbarNameTestSession
  },
  {
    path: '/nlp/projects/view/:testProjectId/results/:testSessionId/confidencetreshold',
    breadcrumb: 'Confidence Threshold',
    navbarName: navbarNameTestSession
  },
  {
    path: '/nlp/projects/view/:testProjectId/results/:testSessionId/downloads',
    breadcrumb: 'Download Results',
    navbarName: navbarNameTestSession
  },
  {
    path: '/nlp/projects/view/:testProjectId/results/:testSessionId/intents/:orderBy',
    navbarName: navbarName('Predicted Intents Score'),
    component: Intents
  },
  {
    path: '/nlp/projects/view/:testProjectId/results/:testSessionId/compareintents/:secondaryTestSessionId',
    navbarName: navbarName('Intent Training Progress'),
    component: CompareIntents
  },
  {
    path: '/nlp/projects/view/:testProjectId/results/:testSessionId/entities/:orderBy',
    navbarName: navbarName('Predicted Entities Score'),
    component: Entities
  },
  {
    path: '/nlp/projects/view/:testProjectId/results/:testSessionId/intentconfidence/expectedintentname/:expectedIntentName/actualintentname/:actualIntentName',
    navbarName: withRouter(IntentConfidenceNavbarHeader),
    component: IntentConfidence
  },
  {
    path: '/nlp/projects/view/:testProjectId/results/:testSessionId/intentconfidence/expectedintentname/:expectedIntentName',
    navbarName: withRouter(IntentConfidenceNavbarHeader),
    component: IntentConfidence
  },
  {
    path: '/nlp/projects/view/:testProjectId/results/:testSessionId/intentconfidence/correctedintentname/:correctedIntentName',
    navbarName: withRouter(IntentConfidenceNavbarHeader),
    component: IntentConfidence
  },
  {
    path: '/nlp/projects/view/:testProjectId/results/:testSessionId/intentconfidence/actualintentname/:actualIntentName',
    navbarName: withRouter(IntentConfidenceNavbarHeader),
    component: IntentConfidence
  },
  {
    path: '/nlp/projects/view/:testProjectId/results/:testSessionId/intentconfidence/intentname/:intentName',
    navbarName: withRouter(IntentConfidenceNavbarHeader),
    component: IntentConfidence
  },
  {
    path: '/nlp/projects/view/:testProjectId/results/:testSessionId/entityconfidence/entityname/:entityName',
    navbarName: withRouter(EntityConfidenceNavbarHeader),
    component: EntityConfidence
  },
  {
    path: '/nlp/projects/view/:testProjectId/results/:testSessionId/entityconfidence/actualentityname/:actualEntityName',
    navbarName: withRouter(EntityConfidenceNavbarHeader),
    component: EntityConfidence
  },
  {
    path: '/nlp/projects/view/:testProjectId/results/:testSessionId/entityconfidence/actualentityid/:actualEntityId',
    navbarName: withRouter(EntityConfidenceNavbarHeader),
    component: EntityConfidence
  },
  {
    path: '/nlp/projects/view/:testProjectId/results/:testSessionId/intentconfidencedeviation/:intent',
    navbarName: navbarName(({ intent }) => `Confidence Deviation for Intent "${decodeURIComponent(intent)}"`),
    component: IntentConfidenceDeviation
  },
  {
    path: '/nlp/projects/view/:testProjectId/results/:testSessionId/intentconfidencedistribution/:from/:to/:showDataByExpected',
    navbarName: navbarName(({ from, to }) => `Intent confidence distribution between ${from} and ${to}`),
    component: IntentConfidenceDistribution
  },
  {
    path: '/nlp/projects/view/:testProjectId/results/:testSessionId/intentconfidencerisks/:showDataByExpected/secondaryTestSessionId/:secondaryTestSessionId',
    navbarName: navbarName('Intent Confidence Risks'),
    component: IntentConfidenceRisks
  },
  {
    path: '/nlp/projects/view/:testProjectId/results/:testSessionId/intentconfidencerisks/:showDataByExpected/intentFilter/:intentFilter/secondaryTestSessionId/:secondaryTestSessionId',
    navbarName: navbarName('Intent Confidence Risks'),
    component: IntentConfidenceRisks
  },
  {
    path: '/nlp/projects/view/:testProjectId/results/:testSessionId/intentconfidencerisks/:showDataByExpected/intentFilter/:intentFilter',
    navbarName: navbarName('Intent Confidence Risks'),
    component: IntentConfidenceRisks
  },
  {
    path: '/nlp/projects/view/:testProjectId/results/:testSessionId/intentconfidencerisks/:showDataByExpected',
    navbarName: navbarName('Intent Confidence Risks'),
    component: IntentConfidenceRisks
  },
  {
    path: '/nlp/projects/view/:testProjectId/results/:testSessionId/intentmismatchprobability/:utteranceKey',
    navbarName: withRouter(IntentMismatchProbabilityNavbarHeader),
    component: IntentMismatchProbability
  },
  {
    path: '/nlp/projects/view/:testProjectId/results/:testSessionId/ignoredtestscripts',
    navbarName: navbarName('Ignored Test Scripts'),
    component: IgnoredTestScripts
  },
  {
    path: '/nlp/projects/view/:testProjectId/results/:testSessionId/comparenewutterances/:secondaryTestSessionId',
    navbarName: navbarName('New utterances in test data'),
    component: CompareUtterancesNew
  },
  {
    path: '/nlp/projects/view/:testProjectId/results/:testSessionId/comparemissingutterances/:secondaryTestSessionId',
    navbarName: navbarName('Missing utterances in test data'),
    component: CompareUtterancesMissing
  },
  {
    path: '/nlp/projects/view/:testProjectId/results/:testSessionId/comparesameintent/:secondaryTestSessionId',
    navbarName: navbarName('Utterances predicted same intent'),
    component: CompareUtterancesSame
  },
  {
    path: '/nlp/projects/view/:testProjectId/results/:testSessionId/compareotherintent/:secondaryTestSessionId',
    navbarName: navbarName('Utterances predicted different intent'),
    component: CompareUtterancesOther
  },
  {
    path: '/nlp/projects/view/:testProjectId/results/:testSessionId/compareallutterances/:secondaryTestSessionId',
    navbarName: navbarName('Utterances'),
    component: CompareUtterancesAll
  },
  {
    path: '/nlp/projects/view/:testProjectId/results/:testSessionId/compareintentutterances/:secondaryTestSessionId/:intentName',
    navbarName: navbarName('Utterances'),
    component: CompareUtterancesAll
  },
  {
    path: '/nlp/projects/view/:testProjectId/results/:testSessionId/ignoredtestscriptsteps',
    navbarName: navbarName('Ignored Test Script Steps'),
    component: IgnoredTestScriptSteps
  },
  {
    path: '/nlp/projects/view/:testProjectId/results/:testSessionId/notresolvedtoanyintent',
    navbarName: navbarName('Utterances without any intent prediction'),
    component: NotResolvedToAnyIntent
  },
  {
    path: '/nlp/projects/view/:testProjectId/results/:testSessionId/resolvedtowrongintent',
    navbarName: navbarName('Utterances with unexpected predicted intent'),
    component: ResolvedToWrongIntent
  },
  {
    path: '/nlp/projects/view/:testProjectId/results/:testSessionId/justexpectedentities',
    navbarName: navbarName('Expected but not predicted entities'),
    component: JustExpectedEntities
  },
  {
    path: '/nlp/projects/view/:testProjectId/results/:testSessionId/justactualentitieschecked',
    navbarName: navbarName('Predicted but not expected entities'),
    component: JustActualEntitiesChecked
  },
  {
    path: '/nlp/projects/view/:testProjectId/results/:testSessionId/justactualentitiesnotchecked',
    navbarName: navbarName('Predicted but ignored entities'),
    component: JustActualEntitiesNotChecked
  },
  {
    path: '/nlp/projects/view/:testProjectId/results/:testSessionId/compareintentwrongnowcorrect/:secondaryTestSessionId',
    navbarName: navbarName('Utterances now predicted as expected'),
    component: CompareIntentWrongNowCorrect
  },
  {
    path: '/nlp/projects/view/:testProjectId/results/:testSessionId/compareintentcorrectnowwrong/:secondaryTestSessionId',
    navbarName: navbarName('Utterances now not predicted as expected anymore'),
    component: CompareIntentCorrectNowWrong
  },
  {
    path: '/nlp/projects/view/:testProjectId/results/:testSessionId/compareintentwrongnowwrongother/:secondaryTestSessionId',
    navbarName: navbarName('Utterances prediction changed, but still not predicted as expected'),
    component: CompareIntentWrongNowWrongOther
  },
  {
    path: '/nlp/projects/view/:testProjectId/results/:testSessionId/compareintentwrongnowwrongsame/:secondaryTestSessionId',
    navbarName: navbarName('Utterances still not predicted as expected'),
    component: CompareIntentWrongNowWrongSame
  },
  {
    path: '/nlp/projects/view/:testProjectId/results/:testSessionId/entityconfidencerisks/entityFilter/:entityFilter/secondaryTestSessionId/:secondaryTestSessionId',
    navbarName: navbarName('Entity Confidence Risks'),
    component: EntityConfidenceRisks
  },
  {
    path: '/nlp/projects/view/:testProjectId/results/:testSessionId/entityconfidencerisks/secondaryTestSessionId/:secondaryTestSessionId',
    navbarName: navbarName('Entity Confidence Risks'),
    component: EntityConfidenceRisks
  },
  {
    path: '/nlp/projects/view/:testProjectId/results/:testSessionId/entityconfidencerisks/entityFilter/:entityFilter',
    navbarName: navbarName('Entity Confidence Risks'),
    component: EntityConfidenceRisks
  },
  {
    path: '/nlp/projects/view/:testProjectId/results/:testSessionId/entityconfidencerisks',
    navbarName: navbarName('Entity Confidence Risks'),
    component: EntityConfidenceRisks
  },
  {
    path: '/nlp/projects/view/:testProjectId/results/:testSessionId/intentmismatchprobabilities/:intentFilter/:secondaryTestSessionId',
    navbarName: navbarName('Intent Mismatch Probability Risks'),
    component: IntentMismatchProbabilities
  },
  {
    path: '/nlp/projects/view/:testProjectId/results/:testSessionId/intentmismatchprobabilities/:intentFilter',
    navbarName: navbarName('Intent Mismatch Probability Risks'),
    component: IntentMismatchProbabilities
  },
  {
    path: '/nlp/projects/view/:testProjectId/results/:testSessionId/intentmismatchprobabilities',
    navbarName: navbarName('Intent Mismatch Probability Risks'),
    component: IntentMismatchProbabilities
  },
  {
    path: '/nlp/projects/view/:testProjectId/results/:testSessionId/utterance/:utterance',
    navbarName: navbarName(({ utterance }) => `Utterance "${decodeURIComponent(utterance)}"`),
    component: Utterance
  }
]

export default coachRoutes
