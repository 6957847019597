import React from 'react'
import Text from '../../components/Typography/Text'

export const renderFactCheckerIndexStatus = (indexSession) => {
  return <Text
  successBg={indexSession.status === 'READY'}
  dangerBg={indexSession.status === 'FAILED'}
  warningBg={indexSession.status === 'PENDING' || indexSession.status === 'EXTRACTING'  || indexSession.status === 'INDEXING'  || indexSession.status === 'SAMPLING'}
  icon={
    indexSession.status === 'READY' ? 'check' : '' ||
    indexSession.status === 'FAILED' ? 'exclamation' : '' ||
    indexSession.status === 'PENDING' ? 'hourglass-half' : '' ||
    indexSession.status === 'EXTRACTING' ? 'hourglass-half' : ''  ||
    indexSession.status === 'INDEXING' ? 'hourglass-half' : ''  ||
    indexSession.status === 'SAMPLING' ? 'hourglass-half' : ''
  }>
  {indexSession.status}
</Text>
}
