import React from 'react'
import PropTypes from 'prop-types'
import { PieChart, Pie, Cell } from 'recharts'


function DonutChart({ ...props }) {
  const { data } = props


  return (
      <PieChart width={300} height={150}>
        <Pie
          data={data.series.map((e) => ({ value: e }))}
          cx={130}
          cy={75}
          innerRadius={35}
          outerRadius={65}
          fill="#8884d8"
          paddingAngle={0}
          dataKey="value"
        >
          {data.series.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={data.fill[index % data.fill.length]} />
          ))}
        </Pie>
      </PieChart>
  )
}

DonutChart.propTypes = {
  data: PropTypes.any,
  options: PropTypes.any,
  onDraw: PropTypes.func,
}

export default (DonutChart)
