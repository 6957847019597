import React from 'react'
import {connect} from 'react-redux'
import {compose, graphql} from 'react-apollo'
import _ from 'lodash'
import {NavLink} from 'react-router-dom'
// @material-ui
import withStyles from '@material-ui/core/styles/withStyles'

// core components
import GridItem from 'components/Grid/GridItem'
import GridContainer from 'components/Grid/GridContainer'
import Table from 'components/Table/AdvancedTable'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import {setAlertErrorMessage, setAlertSuccessMessage} from 'actions/alert'



import DangerOrSuccessText from 'components/Typography/DangerOrSuccessText'
import dashboardStyle from 'assets/jss/material-dashboard-react/views/dashboardStyle'
import {renderProgressOrError} from '../../helper'
import {toFixedSafe, testsessionLabel} from './helper'
import {UTTERANCES_FOR_INTENT_QUERY, TRAINER_SESSION_ROOT_QUERY} from '../gql'
import {decodeURIComponentWeak} from 'views/helper'
import ConfirmationDialog from 'components/Dialog/ConfirmationDialog'
import Utterance from './UtteranceSummary'
import LinkButton from 'components/Button/LinkButton'
import ShowIcon from 'components/Icon/ShowIcon'
import Button from 'components/Button/Button'
import Text from 'components/Typography/Text'

class IntentConfidenceDeviation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false
    }
  }

  getRootPath = () => {
    const path = this.props.location.pathname.split('/').filter(p => p)
    return '/' + path[0] + '/' + path[1] + '/' + path[2] + '/' + path[3]
  }

  renderTable(penaltyApplied) {
    const {utterancesData, match} = this.props
    const { testSessionId } = match.params
    let progressOrError = renderProgressOrError(utterancesData)
    if (progressOrError) {
      return progressOrError
    }

    const data = _.sortBy(utterancesData.trainerUtterances, (entry) => entry.intent.confidenceDifferenceActual)

    return (
      <Table
        tableHeaderColor="primary"
        tableHead={['Utterance', 'Expected Intent', 'Predicted Intent', 'Prediction Confidence', 'Deviation']}
        tableData={data.map(entry => {
          return [
            () => <LinkButton onClick={() => this.setState({ open: true, utterance: entry.utterance, testSessionId: testSessionId })} data-unique={`btnCoachIntentConfidenceUtterance_${encodeURIComponent(entry.utterance)}`}>{entry.utterance}</LinkButton>,
            () => <>{entry.intent.expected}</>,
            () => <>{entry.intent.actual}</>,
            () =>
              <DangerOrSuccessText successful={entry.intent.matches}>
                {penaltyApplied ? toFixedSafe(entry.intent.confidenceCorrected) : toFixedSafe(entry.intent.confidenceReal)}
              </DangerOrSuccessText>
            ,
            () => (
              <DangerOrSuccessText successful={entry.intent.confidenceDifferenceActual >= 0}>
               {toFixedSafe(entry.intent.confidenceDifferenceActual)}
              </DangerOrSuccessText>
            )
          ]
        })}
      />
    )
  }

  render() {
    const { match, testSessionData} = this.props

    const { testSessionId } = match.params
    return (<>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
            <GridContainer>
                <GridItem xs={12}>
                  <NavLink to={`/nlp/projects/view/${match.params.testProjectId}/results/${testSessionId}`} data-unique={`btnBack_${testSessionId}`}>
                    <Button justIcon Border >
                       <ShowIcon icon="arrow-left" />
                   </Button> <Text inline>BACK / Test Session: {testSessionData && testSessionData.testsession && testsessionLabel(testSessionData.testsession)}</Text>
                  </NavLink>
                </GridItem>
                <GridItem xs={12}>
                  {this.renderTable()}
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <ConfirmationDialog
      maxWidth="lg"
      open={this.state.open}
      onOk={() => this.setState({ open: false })}
      title={`Utterance name: ${this.state.utterance}`}
    >
      <Utterance utterance={this.state.utterance} testSessionId={this.state.testSessionId} />
    </ConfirmationDialog>
    </>
    )
  }
}

export default compose(
  withStyles(dashboardStyle),
  connect(
    state => state,
    {setAlertSuccessMessage, setAlertErrorMessage},
  ),
  graphql(UTTERANCES_FOR_INTENT_QUERY, {
    props: ({data}) => ({
      utterancesData: data,
    }),
    options: (props) => {
      return {
        variables: {
          testSessionId: props.match.params.testSessionId,
          intent: decodeURIComponentWeak(props.match.params.intent),
        },
      }
    }
  }),
  graphql(TRAINER_SESSION_ROOT_QUERY, {
    props: ({data}) => ({
      testSessionData: data
    }),
    options: (props) => {
      return {
        variables: {
          testSessionId: props.match.params.secondaryTestSessionId,
          id: props.match.params.testSessionId
        },
      }
    }
  }),
  graphql(TRAINER_SESSION_ROOT_QUERY, {
    props: ({data}) => ({
      secondaryTestSessionData: data
    }),
    options: (props) => {
      return {
        variables: {
          id: props.match.params.secondaryTestSessionId
        },
      }
    },
    skip: (props) => !props.match.params.secondaryTestSessionId
  })
)(IntentConfidenceDeviation)
