import React from 'react'
import {connect} from 'react-redux'
import {compose, graphql} from 'react-apollo'
import _ from 'lodash'
// @material-ui
import withStyles from '@material-ui/core/styles/withStyles'

// core components
import GridItem from 'components/Grid/GridItem'
import GridContainer from 'components/Grid/GridContainer'
import Table from 'components/Table/AdvancedTable'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import {setAlertErrorMessage, setAlertSuccessMessage} from 'actions/alert'
import Button from 'components/Button/Button'
import Text from 'components/Typography/Text'
import ShowIcon from 'components/Icon/ShowIcon'


import DangerOrSuccessText from 'components/Typography/DangerOrSuccessText'
import dashboardStyle from 'assets/jss/material-dashboard-react/views/dashboardStyle'
import {LIST_INTENT_COMPARE_QUERY, TRAINER_SESSION_ROOT_QUERY} from '../gql'
import {renderProgressOrError} from '../../helper'
import {toFixedSafe, testsessionLabel} from './helper'

import ConfirmationDialog from 'components/Dialog/ConfirmationDialog'
import Utterance from './UtteranceSummary'
import LinkButton from 'components/Button/LinkButton'

class CompareIntentBase extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
    }
  }

  renderTable() {
    const { comparedData, filter, match } = this.props

    const testSessionId = match.params.testSessionId

    const progressOrError = renderProgressOrError(comparedData)
    if (progressOrError) {
      return progressOrError
    }

    let data = comparedData.trainerListIntentCompare
    if (filter) {
      data = comparedData.trainerListIntentCompare.filter(filter)
    }
    data = _.orderBy(data, [ (i) => i.primary.intent.confidenceCorrected ], [ 'asc' ])

    return (
      <Table
        tableHeaderColor="primary"
        tableHead={['Utterance', 'Expected Intent', 'Predicted Intent', 'Prediction Confidence']}
        tableData={data.map(entry => {
          return [
            () =>  entry.primary && <LinkButton onClick={() => this.setState({ open: true, utterance: entry.primary.utterance, testSessionId: testSessionId })} data-unique={`btnCoachIntentConfidenceUtterance_${encodeURIComponent(entry.primary.utterance)}`}>{entry.primary.utterance}</LinkButton>,
            () => entry.primary && <>
              <Text md bold rightMarginxs displayInline>{entry.primary.intent.expected}</Text>
              <Text topMarginsm inline md regular><ShowIcon icon="exchange" /> {entry.secondary.intent.expected}</Text>
              </>,
            () => entry.primary && <>
              <Text md bold rightMarginxs displayInline>{entry.primary.intent.actual}</Text>
              <Text topMarginsm inline md regular><ShowIcon icon="exchange" /> {entry.secondary.intent.actual}</Text>
              </>,
            () => (entry.primary && entry.secondary && <>
              <DangerOrSuccessText successful={entry.primary.intent.matches}>
                {toFixedSafe(entry.primary.intent.confidenceCorrected) +
                ((
                    entry.primary.intent.confidenceReal !== entry.primary.intent.confidenceCorrected)
                    ? ` (${toFixedSafe(entry.primary.intent.confidenceReal)})`
                    : ''
                )}
              </DangerOrSuccessText> <ShowIcon icon="exchange" /> <DangerOrSuccessText successful={entry.secondary.intent.matches}>
                {toFixedSafe(entry.secondary.intent.confidenceCorrected) +
                ((
                    entry.secondary.intent.confidenceReal !== entry.secondary.intent.confidenceCorrected)
                    ? ` (${toFixedSafe(entry.secondary.intent.confidenceReal)})`
                    : ''
                )}
              </DangerOrSuccessText>
              </>),
          ]
        })}
      />
    )
  }

  render() {
    const { match, history, testSessionData, secondaryTestSessionData } = this.props
    return (<>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
            <GridContainer>
                <GridItem xs={12}>
                  <Button justIcon Border onClick={() => history.goBack()} >
                      <ShowIcon icon="arrow-left" />
                  </Button> <Text inline>BACK</Text>
                </GridItem>
                <GridItem xs={12}>
                  <Text inline rightMarginxs>Test Sessions: {testSessionData && testSessionData.testsession && testsessionLabel(testSessionData.testsession)}</Text>
                  <Text inline ><ShowIcon icon="exchange" /> {secondaryTestSessionData && secondaryTestSessionData.testsession && testsessionLabel(secondaryTestSessionData.testsession)}</Text>
                </GridItem>
                <GridItem xs={12}>
                  {this.renderTable()}
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <ConfirmationDialog
      maxWidth="lg"
      open={this.state.open}
      onOk={() => this.setState({ open: false })}
      title={`Utterance name: ${this.state.utterance}`}
    >
      <Utterance utterance={this.state.utterance} testSessionId={this.state.testSessionId} testProjectId={match.params.testProjectId} />
    </ConfirmationDialog>
    </>)
  }
}

export default compose(
  withStyles(dashboardStyle),
  connect(
    () => ({}),
    {setAlertSuccessMessage, setAlertErrorMessage},
  ),
  graphql(LIST_INTENT_COMPARE_QUERY, {
    props: ({data}) => ({
      comparedData: data,
    }),
    options: (props) => {
      return {
        variables: {
          testSessionId: props.match.params.testSessionId,
          secondaryTestSessionId: props.match.params.secondaryTestSessionId,
          fieldName: props.fieldToProcess
        },
      }
    }
  }),
  graphql(TRAINER_SESSION_ROOT_QUERY, {
    props: ({data}) => ({
      testSessionData: data
    }),
    options: (props) => {
      return {
        variables: {
          id: props.match.params.testSessionId
        },
      }
    }
  }),
  graphql(TRAINER_SESSION_ROOT_QUERY, {
    props: ({data}) => ({
      secondaryTestSessionData: data
    }),
    options: (props) => {
      return {
        variables: {
          id: props.match.params.secondaryTestSessionId
        },
      }
    },
    skip: (props) => !props.match.params.secondaryTestSessionId
  }),
)(CompareIntentBase)
