import React from 'react'
import {connect} from 'react-redux'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import { Form } from 'react-final-form'
import Field from 'components/Form/OptionalField'
import { OnChange } from 'react-final-form-listeners'
// apollo
import { Mutation, compose, graphql } from 'react-apollo'
// core components
import Button from 'components/Button/Button'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import Card from 'components/Card/Card.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import Text from 'components/Typography/Text.jsx'

import {
  renderTextField,
  renderSelect,
  required,
  FormActionsToolbar
} from 'components/Form/Form'
import { setAlertSuccessMessage, setAlertErrorMessage } from 'actions/alert'

import ShowIcon from 'components/Icon/ShowIcon'
import QueryStatus from 'components/Info/QueryStatus'

import TestSetContentSelector from '../TestSets/TestSetContentSelector.jsx'
import testsetsStyle from 'assets/jss/material-dashboard-react/views/testsetsStyle.jsx'

import {
  TESTSET_QUERY,
  CLONETESTSETOPTIONS_QUERY,
  CLONE_MODIFY_TESTSET,
  RefetchTestSetQueries,
} from '../TestSets/gql'

import ClientFeatureSection from '../Settings/ClientFeatureSection'
import LoadingIndicator from 'components/Icon/LoadingIndicator.jsx'

class TranslatorWizard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      emptyTranslate: {
        testSetId: props.testSetId || props.match.params.testSetId,
        outputToFolder: false,
        translateFrom: null,
        translateTo: null,
        local: true,
        repositories: [],
        folders: [],
        downloadlinks: []
      }
    }
  }

  renderForm() {
    const { testSetData, cloneOptionsData, setAlertSuccessMessage, setAlertErrorMessage, history } = this.props
    const { emptyTranslate, translationRunning } = this.state

    return (<GridContainer>
      <GridItem md={12} lg={8}>
        <Card><CardBody>
          <QueryStatus queries={[testSetData, cloneOptionsData]} query={['testset', 'clonetestsetoptions']}>{({ testset, clonetestsetoptions }) => {
            if (!emptyTranslate.name) emptyTranslate.name = `${testset.name} - Translated`

            return (<Mutation
              mutation={CLONE_MODIFY_TESTSET}
              onCompleted={data => {
                this.setState({ translationRunning: false })
                setAlertSuccessMessage('Test Set translation completed')
                history.push(`/testsets/view/${data.cloneAndModifyTestSet.id}`)
              }}
              onError={error => {
                this.setState({ translationRunning: false })
                setAlertErrorMessage(`Test Set translation failed`, error)
              }}
              refetchQueries={() => {
                return [
                  ...RefetchTestSetQueries()
                ]
              }}
            >
              {(cloneAndModifyTestSet, {loading, error}) => (
                <Form
                  onSubmit={values => {
                    this.setState({ translationRunning: true })
                    cloneAndModifyTestSet({
                      variables: {
                        id: testset.id,
                        options: {
                          name: values.name,
                          output: values.outputToFolder ? 'FOLDER' : 'LOCAL',
                          translateFrom: values.translateFrom,
                          translateTo: values.translateTo,
                          local: !!values.local,
                          repositoryIds: values.repositories || [],
                          folderIds: values.folders || [],
                          downloadlinkIds: values.downloadlinks || []
                        },
                      },
                    })
                  }}
                  initialValues={emptyTranslate}
                  validate={async (values) => {
                    const errors = {}
                    if (values.translateFrom && values.translateTo && values.translateFrom === values.translateTo) {
                      errors['translateFrom'] = errors['translateTo'] = 'Select different languages'
                    }
                    if (this.state.contentValidate) {
                      Object.assign(errors, await this.state.contentValidate(values))
                    }
                    return errors
                  }}
                  render={({handleSubmit, invalid, submitting, form: { change }}) => (
                    <form onSubmit={handleSubmit}>
                      <GridContainer>
                        <GridItem xs={12}>
                          <Text submitting bottomMarginsm bold500 labelText>Test Cases to translate</Text>
                        </GridItem>

                        <TestSetContentSelector allowChangeTestSet={false} showConvos={true} showUtterances={true} addFormValidate={(fn) => this.setState({ contentValidate: fn })}/>
                        <GridItem xs={12} largeMarginTop>
                          <Text header>Configuration</Text>
                        </GridItem>
                        <GridItem xs={12} sm={6} largeMarginTop>
                          <Field
                            name="translateFrom"
                            component={renderSelect}
                            label="Select Test Set language"
                            helperText="You can leave this empty to detect the language automatically"
                            data-unique="selTestSetTranslationFrom"
                            items={clonetestsetoptions.translationFrom.map(l => ({
                              key: l.value,
                              label: l.name,
                              flagIcon: l.value
                            }))}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={6} largeMarginTop>
                          <Field
                            name="translateTo"
                            component={renderSelect}
                            label="Select target language"
                            validate={required}
                            data-unique="selTestSetTranslationTo"
                            items={clonetestsetoptions.translationTo.map(l => ({
                              key: l.value,
                              label: l.name,
                              flagIcon: l.value
                            }))}
                          />
                          <OnChange name="translateTo">
                            {(value, previous) => {
                              if (value && value !== previous) {
                                change('name', `${testset.name} - ${clonetestsetoptions.translationTo.find(c => c.value === value).name}`)
                              }
                            }}
                          </OnChange>
                        </GridItem>

                        <GridItem xs={12}>
                          <Field
                            name="name"
                            component={renderTextField}
                            label="New Test Set Name"
                            validate={required}
                            data-unique="txtTestSetTranslationNewTestSetName"
                          />
                        </GridItem>
                        <GridItem xs={12} largePadding>
                          <FormActionsToolbar rightButtons={
                            <Button
                              type="submit"
                              disabled={translationRunning || submitting}
                              data-unique="btnTestSetDownloadAndTranslate"
                            >
                              {translationRunning && <><LoadingIndicator alt /> Translation is running ...</>}
                              {!translationRunning && <><ShowIcon icon="play-circle" />Translate</>}
                            </Button>
                          }/>
                        </GridItem>
                      </GridContainer>
                    </form>
                  )}
                />
              )}
            </Mutation>)
          }}</QueryStatus>
        </CardBody></Card>
      </GridItem>
    </GridContainer>)
  }

  render() {
    return <ClientFeatureSection feature="translation">{this.renderForm()}</ClientFeatureSection>
  }
}

export default compose(
  withStyles(testsetsStyle),
  connect(
    state => ({ user: state.token.user, license: state.settings.license, settings: state.settings }),
    { setAlertSuccessMessage, setAlertErrorMessage },
  ),
  graphql(TESTSET_QUERY, {
    options: (props) => ({
      variables: {
        id: props.testSetId || props.match.params.testSetId
      },
    }),
    props: ({ data }) => ({
      testSetData: data,
    }),
  }),
  graphql(CLONETESTSETOPTIONS_QUERY, {
    props: ({ data }) => ({
      cloneOptionsData: data,
    }),
  })
)(TranslatorWizard)
