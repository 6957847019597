import React from 'react'
import {connect} from 'react-redux'
import {compose, graphql} from 'react-apollo'
import {NavLink} from 'react-router-dom'
// @material-ui
import withStyles from '@material-ui/core/styles/withStyles'

// core components
import GridItem from 'components/Grid/GridItem'
import GridContainer from 'components/Grid/GridContainer'
import Table from 'components/Table/AdvancedTable'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import {setAlertErrorMessage, setAlertSuccessMessage} from 'actions/alert'

import SimpleBar from 'components/Stats/Charts/SimpleBar'
import dashboardStyle from 'assets/jss/material-dashboard-react/views/dashboardStyle'
import {renderProgressOrError, normalizeOrderBy} from '../../helper'
import {toFixedSafe, testsessionLabel} from './helper'
import {CHART_ENTITY_QUERY, TRAINER_SESSION_ROOT_QUERY} from '../gql'

class Entities extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  renderTable() {
    const {
      match,
      entitiesData
    } = this.props

    let progressOrError = renderProgressOrError(entitiesData)
    if (progressOrError) {
      return progressOrError
    }

    return (
      <Table
        tableHeaderColor="primary"
        tableHead={['Predicted Entity', 'Prediction Confidence', 'Confidence Deviation', 'Occurrences']}
        tableData={entitiesData.trainerChartEntity.map(entry => {
          return [
            () => <NavLink to={`/nlp/projects/view/${match.params.testProjectId}/results/${match.params.testSessionId}/entityconfidence/actualentityid/${entry.id}`} data-unique={`btnCoachEntitiesName_${entry.id}`}>
              {entry.name}
            </NavLink>,
            () => <SimpleBar progress={entry.avg}>{toFixedSafe(entry.avg)}</SimpleBar>,
            () => <SimpleBar progress={entry.deviation} inverse>{toFixedSafe(entry.deviation)}</SimpleBar>,
            () => <NavLink to={`/nlp/projects/view/${match.params.testProjectId}/results/${match.params.testSessionId}/entityconfidence/actualentityid/${entry.id}`} data-unique={`btnCoachEntitiesCount_${entry.id}`}>
              {entry.count}
            </NavLink>
          ]
        })}
      />
    )
  }

  render() {
    const {testSessionData} = this.props

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem xs={12}>
                  Test Session: {testSessionData && testSessionData.testsession && testsessionLabel(testSessionData.testsession)}
                </GridItem>
                <GridItem xs={12}>
                  {this.renderTable()}
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    )
  }
}

export default compose(
  withStyles(dashboardStyle),
  connect(
    () => ({}),
    {setAlertSuccessMessage, setAlertErrorMessage},
  ),
  graphql(CHART_ENTITY_QUERY, {
    props: ({data}) => ({
      entitiesData: data,
    }),
    options: (props) => {
      return {
        variables: {
          testSessionId: props.match.params.testSessionId,
          entityFilter: '',
          orderBy: normalizeOrderBy(props.match.params.orderBy)
        },
      }
    }
  }),
  graphql(TRAINER_SESSION_ROOT_QUERY, {
    props: ({data}) => ({
      testSessionData: data
    }),
    options: (props) => {
      return {
        variables: {
          id: props.match.params.testSessionId
        },
      }
    }
  })
)(Entities)
