import { gql } from 'apollo-boost'
import { deleteListQueryFromCache } from 'helper/cacheHelper'
import { NAMESPACES_QUERY } from 'views/Settings/gql'
import { LIST_TESTSESSION_FRAGMENT } from '../TestSessions/gql'

const FULL_FACTCHECKERPROJECT_FRAGMENT = gql`
  fragment FullFactCheckerProject on FactCheckerProject {
    id
    createdAt
    updatedAt
    createdBy { id name }
    updatedBy { id name }
    name
    description
    namespace
    indexNamespace
    documentBaseDir
    crawlerDepth
    urls
    chatbot {
      id
      name
      avatar
      containermode
    }
    lastIndexSession {
      status
    }
    lastFactCheckerSession {
      id
    }
    testSet {
      id
      name
      statsUpdatedAt
      statsConvoCount
      statsPartialConvoCount
      statsUtterancesRefCount
      statsUtterancesCount
      statsScriptingMemoryCount
    }
  }
`

export const LIST_FACTCHECKERTESTSESSION_FRAGMENT = gql`
  fragment ListFactCheckerTestSession on FactCheckerSession {
    id
    createdAt
    updatedAt
    createdBy { id name }
    updatedBy { id name }
    totalCount
    failedCount
    correctCount
    incorrectCount
    unknownCount
  }
`

const FULL_FACTCHECKERINDEXSESSION_FRAGMENT = gql`
  fragment FullFactCheckerIndexSession on FactCheckerIndexSession {
    id
    createdAt
    updatedAt
    createdBy { id name }
    updatedBy { id name }
    extract
    index
    sample
    status
    log
    err
    project { id name }
    testSet { id name }
  }
`

const STATUS_FACTCHECKERINDEXSESSION_FRAGMENT = gql`
  fragment StatusFactCheckerIndexSession on FactCheckerIndexSession {
    id
    createdAt
    updatedAt
    createdBy { id name }
    updatedBy { id name }
    extract
    index
    sample
    status
    err
    project { id name }
  }
`


export const FACTCHECKERPROJECTS_QUERY = gql`
  query FactCheckerProjectsQuery {
    factcheckerprojects {
      ...FullFactCheckerProject
    }
  }
  ${FULL_FACTCHECKERPROJECT_FRAGMENT}
`


export const FACTCHECKERPROJECTS_PAGINATED_QUERY = gql`
  query FactCheckerProjectsPaginatedQuery($where: FactCheckerProjectWhereInput, $orderBy: String, $skip: Int, $first: Int) {
    factcheckerprojectswithlastfactcheckersession(where: $where, orderBy: $orderBy, skip: $skip, first: $first) {
      ...FullFactCheckerProject
      lastFactCheckerSession {
        testSession {
          ...ListTestSession
        }
      }
    }
  }
  ${FULL_FACTCHECKERPROJECT_FRAGMENT}
  ${LIST_TESTSESSION_FRAGMENT}
`

export const FACTCHECKERPROJECTS_COUNT_QUERY = gql`
  query FactCheckerProjectsCountQuery($where: FactCheckerProjectWhereInput) {
    factcheckerprojectsCount(where: $where)
  }
`

export const FACTCHECKERPROJECT_QUERY = gql`
  query FactCheckerProjectQuery($id: ID!) {
    factcheckerproject(id: $id) {
      ...FullFactCheckerProject
    }
  }
  ${FULL_FACTCHECKERPROJECT_FRAGMENT}
`

export const CREATE_FACTCHECKERPROJECT = gql`
  mutation CreateFactCheckerProject($input: CreateFactCheckerProjectInput!) {
    createFactCheckerProject(input: $input) {
      ...FullFactCheckerProject
    }
  }
  ${FULL_FACTCHECKERPROJECT_FRAGMENT}
`

export const UPDATE_FACTCHECKERPROJECT = gql`
  mutation UpdateFactCheckerProject($id: ID!, $factCheckerProject: FactCheckerProjectInput!) {
    updateFactCheckerProject(id: $id, factCheckerProject: $factCheckerProject) {
      ...FullFactCheckerProject
    }
  }
  ${FULL_FACTCHECKERPROJECT_FRAGMENT}
`

export const UPDATE_FACTCHECKERPROJECT_URLS = gql`
  mutation UpdateFactCheckerProjectURLS($id: ID!, $urls: [String!]!) {
    updateFactCheckerProjectUrls(id: $id, urls: $urls) {
      ...FullFactCheckerProject
    }
  }
  ${FULL_FACTCHECKERPROJECT_FRAGMENT}
`

export const DELETE_FACTCHECKERPROJECT = gql`
  mutation DeleteFactCheckerProject($id: ID!) {
    deleteFactCheckerProject(id: $id)
  }
`

export const FACTCHECKERINDEXSESSIONS_QUERY = gql`
  query FactCheckerIndexSessionsQuery($id: ID!, $skip: Int, $first: Int) {
    factcheckerindexsessions(id: $id, skip: $skip, first: $first) {
      ...FullFactCheckerIndexSession
    }
  }
  ${FULL_FACTCHECKERINDEXSESSION_FRAGMENT}
`
export const FACTCHECKERINDEXSESSIONS_COUNT_QUERY = gql`
  query FactCheckerIndexSessionsCountQuery($id: ID!) {
    factcheckerindexsessionsCount(id: $id)
  }
`

export const START_FACTCHECKER_UPDATE_INDEX = gql`
  mutation UpdateFactCheckerProjectIndex($id: ID!) {
    updateFactCheckerProjectIndex(id: $id) {
      ...FullFactCheckerIndexSession
    }
  }
  ${FULL_FACTCHECKERINDEXSESSION_FRAGMENT}
`

export const START_FACTCHECKER_CREATE_SAMPLES = gql`
  mutation CreateFactCheckerSampleQueries($id: ID!) {
    createFactCheckerSampleQueries(id: $id) {
      ...FullFactCheckerIndexSession
    }
  }
  ${FULL_FACTCHECKERINDEXSESSION_FRAGMENT}
`

export const START_FACTCHECKERPROJECT = gql`
  mutation StartFactCheckerProject($id: ID!, $debug: Boolean) {
    startFactCheckerProject(id: $id, debug: $debug) {
      ...ListTestSession
    }
  }
  ${LIST_TESTSESSION_FRAGMENT}
`

export const START_FACTCHECKERPROJECTS = gql`
  mutation StartFactCheckerProjects($factCheckerProjectIds: [ID!], $debug: Boolean!) {
    startFactCheckerProjects(factCheckerProjectIds: $factCheckerProjectIds, debug: $debug) {
      ...ListTestSession
    }
  }
  ${LIST_TESTSESSION_FRAGMENT}
`

export const FACTCHECKERINDEXSESSION_PROGRESS_QUERY = gql`
  query FactCheckerIndexSessionProgressQuery($id: ID!) {
    factcheckerindexsession(id: $id) {
      ...FullFactCheckerIndexSession
    }
  }
  ${FULL_FACTCHECKERINDEXSESSION_FRAGMENT}
`

export const FACTCHECKERINDEXSESSION_PROGRESS_SUBSCRIPTION = gql`
  subscription FactCheckerIndexSessionProgressSubscription($id: ID!) {
    factCheckerIndexSessionProgress(factCheckerIndexSessionId: $id) {
      ...FullFactCheckerIndexSession
    }
  }
  ${FULL_FACTCHECKERINDEXSESSION_FRAGMENT}
`

export const FACTCHECKERPROJECT_PROGRESS_QUERY = gql`
  query FactCheckerProjectProgressQuery($id: ID!) {
    factcheckerlastindexsession(id: $id) {
      ...StatusFactCheckerIndexSession
    }
  }
  ${STATUS_FACTCHECKERINDEXSESSION_FRAGMENT}
`

export const FACTCHECKERPROJECT_PROGRESS_SUBSCRIPTION = gql`
  subscription FactCheckerProjectProgressSubscription($id: ID!) {
    factCheckerProjectProgress(factCheckerProjectId: $id) {
      ...StatusFactCheckerIndexSession
    }
  }
  ${STATUS_FACTCHECKERINDEXSESSION_FRAGMENT}
`

export const DeleteFactCheckerListsFromCache = (store) => {
  deleteListQueryFromCache(store, /^factcheckerprojects/)
}

export const RefetchFactCheckerProjectQueries = () => [
  {
    query: NAMESPACES_QUERY
  },
  {
    query: FACTCHECKERPROJECTS_QUERY,
  },
  {
    query: FACTCHECKERPROJECTS_COUNT_QUERY,
  }
]

export const FACTCHECKERPROJECTS_DROPDOWN_QUERY = gql`
  query FactCheckerProjectsQuery {
    factcheckerprojects {
      id
      name
      namespace
    }
  }
`

export const DeleteFactCheckerIndexListsFromCache = (store) => {
  deleteListQueryFromCache(store, /^factcheckerindexsessions/)
}

export const RefetchFactCheckerProjectIndexQueries = (factCheckerProjectId) => [
  {
    query: FACTCHECKERPROJECTS_QUERY,
  },
  {
    query: FACTCHECKERPROJECTS_PAGINATED_QUERY,
  },
  {
    query: FACTCHECKERPROJECTS_COUNT_QUERY,
  },
  {
    query: FACTCHECKERPROJECTS_DROPDOWN_QUERY,
  },
]

const FULL_MISUSECHECKERPROJECT_FRAGMENT = gql`
  fragment FullMisuseCheckerProject on MisuseCheckerProject {
    id
    createdAt
    updatedAt
    createdBy { id name }
    updatedBy { id name }
    name
    description
    namespace
    chatbot {
      id
      name
      avatar
      containermode
    }
    lastMisuseCheckerSession {
      id
    }
    testSet {
      id
      name
      statsUpdatedAt
      statsConvoCount
      statsPartialConvoCount
      statsUtterancesRefCount
      statsUtterancesCount
      statsScriptingMemoryCount
    }
  }
`

export const LIST_MISUSECHECKERSESSION_FRAGMENT = gql`
  fragment ListMisuseCheckerTestSession on MisuseCheckerSession {
    id
    createdAt
    updatedAt
    createdBy { id name }
    updatedBy { id name }
    totalCount
    failedCount
    correctCount
    incorrectCount
    unknownCount
  }
`

export const MISUSECHECKERPROJECTS_QUERY = gql`
  query MisuseCheckerProjectsQuery {
    misusecheckerprojects {
      ...FullMisuseCheckerProject
    }
  }
  ${FULL_MISUSECHECKERPROJECT_FRAGMENT}
`

export const MISUSECHECKERPROJECTS_PAGINATED_QUERY = gql`
  query MisuseCheckerProjectsPaginatedQuery($where: MisuseCheckerProjectWhereInput, $orderBy: String, $skip: Int, $first: Int) {
    misusecheckerprojectswithlastmisusecheckersession(where: $where, orderBy: $orderBy, skip: $skip, first: $first) {
      ...FullMisuseCheckerProject
      lastMisuseCheckerSession {
        testSession {
          ...ListTestSession
        }
      }
    }
  }
  ${FULL_MISUSECHECKERPROJECT_FRAGMENT}
  ${LIST_TESTSESSION_FRAGMENT}
`

export const MISUSECHECKERPROJECTS_COUNT_QUERY = gql`
  query MisuseCheckerProjectsCountQuery($where: MisuseCheckerProjectWhereInput) {
    misusecheckerprojectsCount(where: $where)
  }
`

export const MISUSECHECKERPROJECT_QUERY = gql`
  query MisuseCheckerProjectQuery($id: ID!) {
    misusecheckerproject(id: $id) {
      ...FullMisuseCheckerProject
    }
  }
  ${FULL_MISUSECHECKERPROJECT_FRAGMENT}
`

export const CREATE_MISUSECHECKERPROJECT = gql`
  mutation CreateMisuseCheckerProject($input: CreateMisuseCheckerProjectInput!, $start: Boolean) {
    createMisuseCheckerProject(input: $input, start: $start) {
      ...FullMisuseCheckerProject
    }
  }
  ${FULL_MISUSECHECKERPROJECT_FRAGMENT}
`

export const UPDATE_MISUSECHECKERPROJECT = gql`
  mutation UpdateTestProject($id: ID!, $misuseCheckerProject: MisuseCheckerProjectInput!) {
    updateMisuseCheckerProject(id: $id, misuseCheckerProject: $misuseCheckerProject) {
      ...FullMisuseCheckerProject
    }
  }
  ${FULL_MISUSECHECKERPROJECT_FRAGMENT}
`

export const DELETE_MISUSECHECKERPROJECT = gql`
  mutation DeleteMisuseCheckerProject($id: ID!) {
    deleteMisuseCheckerProject(id: $id)
  }
`

export const START_MISUSECHECKERPROJECT = gql`
  mutation StartTestProject($id: ID!, $debug: Boolean) {
    startMisuseCheckerProject(id: $id, debug: $debug) {
      ...ListTestSession
    }
  }
  ${LIST_TESTSESSION_FRAGMENT}
`

export const START_MISUSECHECKERPROJECTS = gql`
  mutation StartMisuseCheckerProjects($misuseCheckerProjectIds: [ID!], $debug: Boolean!) {
    startMisuseCheckerProjects(misuseCheckerProjectIds: $misuseCheckerProjectIds, debug: $debug) {
      ...ListTestSession
    }
  }
  ${LIST_TESTSESSION_FRAGMENT}
`

export const SOURCE_OF_TRUTH_WIZARD = gql`
  mutation SourceOfTruthWizard($factCheckerProjectId: ID, $testSetName: String!, $namespace: String, $urls: [String!], $files: [CreateFactCheckerProjectFile!]) {
    sourceOfTruthWizard(factCheckerProjectId: $factCheckerProjectId, testSetName: $testSetName, namespace: $namespace, urls: $urls, files: $files) {
       ...FullFactCheckerIndexSession
    }
  }
  ${FULL_FACTCHECKERINDEXSESSION_FRAGMENT}
`

export const DeleteMisuseCheckerListsFromCache = (store) => {
  deleteListQueryFromCache(store, /^misusecheckerprojects/)
}

export const RefetchMisuseCheckerProjectQueries = () => [
  {
    query: NAMESPACES_QUERY
  },
  {
    query: MISUSECHECKERPROJECTS_QUERY,
  },
  {
    query: MISUSECHECKERPROJECTS_COUNT_QUERY,
  }
]

