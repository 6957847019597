import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
// @material-ui/core components
import { Form } from 'react-final-form'
// apollo
import { compose, graphql } from 'react-apollo'
import { Query } from 'react-apollo'
// core components
import Field from 'components/Form/OptionalField'
import ConfirmationDialog from 'components/Dialog/ConfirmationDialog'
import GridItem from 'components/Grid/GridItem'
import GridContainer from 'components/Grid/GridContainer'
import ErrorFormat from 'components/Info/ErrorFormat'
import { renderSelect, required } from 'components/Form/Form'

import { TESTPROJECTS_QUERY } from 'views/TestProjects/gql'
import { TESTSETS_DROPDOWN_QUERY } from 'views/TestSets/gql'
import { TESTSESSIONS_QUERY } from 'views/TestSessions/gql'
import { CHATBOTS_DROPDOWN_QUERY } from 'views/Chatbots/gql'

import { WHERE_TEST_SESSION_WITH_TRAINER_SESSION } from 'botium-box-shared/trainer/helper'
import LoadingIndicator from 'components/Icon/LoadingIndicator'
import { safeGetNamespaceFilteredList } from '../../helper'
import { testsessionLabel } from './helper'

class TestProjectDialog extends React.Component {
  render() {
    const { title, open, onSelected, onCancel, selectedTestSessionId, excludeTestSessionId, selectedTestProjectId } = this.props

    const testprojects = safeGetNamespaceFilteredList(this.props.testProjectsData.testprojects, this.props.namespace, selectedTestProjectId)
    //const chatbots = safeGetNamespaceFilteredList(this.props.chatbotsData.chatbots, this.props.namespace)
    const testsets = safeGetNamespaceFilteredList(this.props.testSetsData.testsets, this.props.namespace)
    return (
      <Form
        onSubmit={values => {
          onSelected(values.testSessionId)
        }}
        initialValues={{
          testSessionId: selectedTestSessionId,
          testProjectId: selectedTestProjectId
        }}
        render={({ handleSubmit, form: { reset }, submitting, values }) => {
          const where = Object.assign({}, WHERE_TEST_SESSION_WITH_TRAINER_SESSION)
          if (values.testProjectId) {
            where.testProject = { id: values.testProjectId }
          }
          if (values.testSetId) {
            where.testSets_some = { id: values.testSetId }
          }
          if (values.chatbotId) {
            where.chatbot = { id: values.chatbotId }
          }
          const orderBy = 'createdAt_DESC'

          return (<ConfirmationDialog
            open={open}
            onCancel={() => {
              reset()
              onCancel()
            }}
            okDisabled={!values.testSessionId}
            onOk={() => handleSubmit()}
            title={title || 'Select Test Session'}
          >
            <form onSubmit={handleSubmit}>
              <GridContainer>
                <GridItem xs={12}>
                  <Field
                    name="testProjectId"
                    component={renderSelect}
                    label="Filter by Test Project"
                    data-unique="selTestProjectDialogTestProjectId"
                    items={testprojects && testprojects.filter(tp => tp.nlpAnalytics).map(tp => {
                      return {
                        key: tp.id,
                        label: tp.name
                      }})}
                    />
                </GridItem>
                <GridItem xs={12}>
                  <Field
                    name="testSetId"
                    component={renderSelect}
                    label="Filter by Test Set"
                    data-unique="selTestProjectDialogTestSetId"
                    items={testsets && testsets.map(t => {
                      return {
                        key: t.id,
                        label: t.name
                      }
                    })}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <Query query={TESTSESSIONS_QUERY}
                    variables={{ where, orderBy }}
                    fetchPolicy={'network-only'}
                    notifyOnNetworkStatusChange={true}>
                    {({ loading, error, data }) => {
                      if (loading) {
                        return <LoadingIndicator large />
                      }
                      if (error) {
                        return <ErrorFormat err={error} />
                      }

                      const items = []
                      safeGetNamespaceFilteredList(data.testsessions.filter(t => t.id !== excludeTestSessionId), this.props.namespace, selectedTestSessionId).forEach(ts => {
                        items.push({
                          key: ts.id,
                          label: testsessionLabel(ts),
                          })
                      })

                      return (
                        <Field
                          name="testSessionId"
                          component={renderSelect}
                          label="Select Test Session"
                          data-unique="selTestProjectDialogTestSessionId"
                          items={items}
                          validate={required}
                        />
                      )
                    }}
                  </Query>
                </GridItem>
              </GridContainer>
            </form>
          </ConfirmationDialog>)
        }}
      />
    )
  }
}

TestProjectDialog.propTypes = {
  title: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onSelected: PropTypes.func,
  onCancel: PropTypes.func,
  selectedTestSessionId: PropTypes.string,
  excludeTestSessionId: PropTypes.string,
  selectedTestProjectId: PropTypes.string
}

export default compose(
  connect(state => ({ namespace: state.namespace })),
  graphql(TESTPROJECTS_QUERY, {
    props: ({ data }) => ({
      testProjectsData: data
    }),
  }),
  graphql(TESTSETS_DROPDOWN_QUERY, {
    props: ({ data }) => ({
      testSetsData: data
    }),
  }),
  graphql(CHATBOTS_DROPDOWN_QUERY, {
    props: ({ data }) => ({
      chatbotsData: data
    }),
  })
)(TestProjectDialog)
