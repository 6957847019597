import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
// @material-ui/core
import withStyles from '@material-ui/core/styles/withStyles'
import withTheme from '@material-ui/core/styles/withTheme'
import CircularProgress from '@material-ui/core/CircularProgress'
import NumberFormat from 'react-number-format'
// apollo
import { Query, compose, graphql } from 'react-apollo'
import { gql } from 'apollo-boost'
// @material-ui/icons
import TrendingUpIcon from '@material-ui/icons/TrendingUp'
import TrendingDownIcon from '@material-ui/icons/TrendingDown'
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat'
// core components
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import Card from 'components/Card/Card.jsx'
import CardHeader from 'components/Card/CardHeader.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import DateFormat from 'components/Info/DateFormat'
import ErrorFormat from 'components/Info/ErrorFormat'
import Feed from 'components/RSS/Feed.jsx'
import Tooltip from 'components/Tooltip/Tooltip'
import TestProjectsEmbeddedTable from '../TestProjects/TestProjectsEmbeddedTable.jsx'
import LLMProjectTypes from '../LLMprojects/LLMprojects.jsx'
import dashboardStyle from 'assets/jss/material-dashboard-react/views/dashboardStyle.jsx'
import typographyStyle from 'assets/jss/material-dashboard-react/components/typographyStyle.jsx'

import { clearDashboardSettings, setDashboardSettings } from 'actions/dashboard'

import { hasPermission } from 'botium-box-shared/security/permissions'

import { TESTPROJECTS_FAILING_QUERY } from '../TestProjects/gql'
import StatsText from 'components/Stats/StatsText'
import Text from 'components/Typography/Text'
import LinkButton from 'components/Button/LinkButton'
import LoadingIndicator from 'components/Icon/LoadingIndicator'

const RATIOBYTESTPROJECTBYDAY_QUERY = gql`
  query ChartRatioByTestProjectByDay($timeFrame: ChartTimeFrame!) {
    chartRatioByTestProjectByDay(timeFrame: $timeFrame) {
      id
      name
      href { testProjectId }
      hrefs { testSessionId }
      data
    }
  }
`

export const TESTPROJECTS_COUNT_QUERY = gql`
  query ChartTestProjectResultCount($timeFrame: ChartTimeFrame!) {
    chartTestProjectResultCount(timeFrame: $timeFrame) {
      id
      name
      href { testProjectId }
      lastRun
      lastTestSessionId
      testCaseLastTotalCount
      testCaseLastSuccessCount
      testCaseLastSuccessRate
      previousRun
      previousTestSessionId
      testCasePreviousTotalCount
      testCasePreviousSuccessCount
      testCasePreviousSuccessRate
      testCaseTrend
    }
  }
`

const RATIOBYDAY_QUERY = gql`
  query ChartRatioByDay(
    $timeFrame: ChartTimeFrame!
  ) {
    chartRatioByDay(timeFrame: $timeFrame) {
      totalCount
      successCount
      failedCount
    }
  }
`

const TESTSESSIONCOUNT_QUERY = gql`
  query ChartTestSessionsCountQuery {
    chartTestSessionsCount {
      testSessionTotalToday
      testSessionFailedToday
      testSessionTotalLastMonth
      testSessionFailedLastMonth
      testSessionTotalLastYear
      testSessionFailedLastYear
      convoTotalToday
      convoFailedToday
      convoTotalLastMonth
      convoFailedLastMonth
      convoTotalLastYear
      convoFailedLastYear
    }
  }
`

class Dashboard extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      testProjectActivityMore: false
    }
  }

  renderRssFeed() {
    const rssUrl = 'https://cyara.com/blog/tag/botium/feed/'
    return (
      <Card>
        <CardHeader color="info">
          <Text header>Botium News</Text>
          <Text subheader>
            News from the Botium universe
          </Text>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={12}>
              <Feed moreHref="https://cyara.com/blog/?fwp_tags=botium" name="botium-box-feed" url={rssUrl} top={4} sm={12} divider/>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    )
  }

  renderTestProjectActivity() {
    const { ratioByTestProjectData, testProjectCountData } = this.props

    if (ratioByTestProjectData.error) {
      return <ErrorFormat err={ratioByTestProjectData.error} />
    }
    if (testProjectCountData.error) {
      return <ErrorFormat err={testProjectCountData.error} />
    }

    if (ratioByTestProjectData.chartRatioByTestProjectByDay) {
      return (<GridContainer data-unique="grdTestProjectActivity" style={{ paddingBottom: 15 }}>
        {(this.state.testProjectActivityMore ? ratioByTestProjectData.chartRatioByTestProjectByDay : ratioByTestProjectData.chartRatioByTestProjectByDay.slice(0,4)).map((chart, i) => {
          const stats = testProjectCountData.chartTestProjectResultCount && testProjectCountData.chartTestProjectResultCount.find(stats => chart.href.testProjectId === stats.href.testProjectId)

          return <GridItem md={6} lg={3} grid key={i}>{this.renderTestProjectActivityCell(chart, stats, i)}</GridItem>
        })}
        {ratioByTestProjectData.chartRatioByTestProjectByDay.length > 4 && <GridItem xs={12} center>
          <Tooltip title={this.state.testProjectActivityMore ? 'Show Less' : 'Show More'} placement="bottom" >
            <LinkButton data-unique="btnShowTestProjectsMore" onClick={() => this.setState({ testProjectActivityMore: !this.state.testProjectActivityMore })}>{this.state.testProjectActivityMore ? 'Show Less' : 'Show More'}</LinkButton>
          </Tooltip>
       </GridItem>}
      </GridContainer>)
    } else {
      return null
    }
  }

  renderRecentTestResults() {
    const { classes } = this.props

    return (
      <GridContainer>
        <GridItem md={12}><Text header>Recent Test Projects</Text></GridItem>
        <GridItem md={12} className={classes.projectsListEmbeddedTable}>
          <TestProjectsEmbeddedTable disableFilter disableOrderBy disableFooter pageSize={5} name="TestProjects" />
        </GridItem>
      </GridContainer>
    )
  }

  renderRecentLLMProjects() {

    return (
      <GridContainer>
        <GridItem md={12} marginTop largePaddingBottom><Text header>AI Trust Suite</Text></GridItem>
        <GridItem md={12}>
          <LLMProjectTypes />
        </GridItem>
      </GridContainer>
    )
  }

  render() {
    const { user, dashboard, history } = this.props
    const hasSessionAccess = hasPermission(user, 'TESTSESSIONS_SELECT')

    return (<>
      <GridContainer>
        {hasSessionAccess &&
          <GridItem xs={12}>
            {this.renderRecentTestResults()}
          </GridItem>
        }
        {history &&
          <GridItem xs={12}>
            {this.renderRecentLLMProjects()}
          </GridItem>
        }
        {!dashboard.hideBotiumNews &&
          <GridItem xs={12}>
            {this.renderRssFeed()}
          </GridItem>
        }
      </GridContainer>
    </>)
  }

  ___renderFailingTestProjectCell(testproject) {
    const { classes } = this.props

    const failingCount = testproject.testCaseLastTotalCount - testproject.testCaseLastSuccessCount
    let failingDiff = testproject.previousTestSessionId ? failingCount - (testproject.testCasePreviousTotalCount - testproject.testCasePreviousSuccessCount) : null

    const tooltipText = `${failingCount} Test Cases failed${failingDiff >= 0 ? (testproject.testCaseTrend === 'BAD' ? `, ${failingDiff} new failed Test Case(s) compared to previous Test Session` : (testproject.testCaseTrend === 'CONSTANT' ? ', no new failed Test Case(s) compared to previous Test Session' : '')) : ''}`

    return (
      <Card dense >
        <CardBody style={{ minHeight: 90 }}>
          <NavLink className={classes.testProjectCardHeader} to={`/regression/projects/view/${testproject.href.testProjectId}`} title={testproject.name}>{testproject.name}</NavLink>
            <h2 className={classes.statsDangerTitle}>
              <Tooltip title={tooltipText}>
                  {testproject.testCaseTrend ==='GOOD' && <TrendingDownIcon />}
                  {testproject.testCaseTrend ==='BAD' && failingDiff > 0 && <TrendingUpIcon />}
                  {testproject.testCaseTrend === 'CONSTANT' && <TrendingFlatIcon />}
                  {failingCount}{testproject.previousTestSessionId && <span style={{ fontSize: 16 }}>{(failingDiff > 0 ? `(+${failingDiff})` : (failingDiff === 0 ? '' : `(${failingDiff})`))}</span>}
              </Tooltip>
              <NavLink
                to={`/regression/projects/view/${testproject.id}/results/${testproject.lastTestSessionId}`}
                className={classes.lastRunLink}
              >
                <DateFormat fromNow>{testproject.lastRun}</DateFormat>
              </NavLink>
              <Text danger className={classes.failedBox}>
                <NumberFormat
                  value={100 - testproject.testCaseLastSuccessRate}
                  displayType={'text'}
                  decimalScale={1}
                />
                % failed
              </Text>
            </h2>
        </CardBody>
      </Card>
    )
  }
  ___renderFailingTestProjects() {
    return (
      <Query
        query={TESTPROJECTS_FAILING_QUERY}
        variables={{ timeFrame: 'LAST2WEEKS' }}
        fetchPolicy={'network-only'}
        notifyOnNetworkStatusChange={true}
      >
        {({ loading, error, data }) => {
          if (loading) {
            return <LoadingIndicator large />
          }
          if (error) {
            return <ErrorFormat err={error} />
          }
          if (data.chartTestProjectResultCount) {
            return (<GridContainer data-unique="chartTestProjectResultCount" style={{ paddingBottom: 15 }}>
              {data.chartTestProjectResultCount.map((testproject, i) => <GridItem xs={12} sm={6} md={6} lg={4} xl={3} key={i}>{this.renderFailingTestProjectCell(testproject)}</GridItem>)}
            </GridContainer>)
          } else {
            return null
          }
        }}
      </Query>
    )
  }

  ___renderSessionCountChart() {
    const { classes } = this.props

    return (
      <Card>
        <CardHeader color="info">
          <Text header>Botium Activity</Text>
          <Text subheader>Test Sessions and Test Cases</Text>
        </CardHeader>
        <CardBody>
          <Query
            query={TESTSESSIONCOUNT_QUERY}
            fetchPolicy={'network-only'}
            notifyOnNetworkStatusChange={true}
          >
            {({ loading, error, data }) => {
              if (loading) {
                return <LoadingIndicator large />
              }
              if (error) {
                return <ErrorFormat err={error} />
              }

              if (!data || !data.chartTestSessionsCount) {
                return null
              }
              const c = data.chartTestSessionsCount

              return (<GridContainer data-unique="chartTestSessionsCount">
                <GridItem xs={12}>
                  <p className={classes.largeText + ' ' + classes.infoText}>
                    {c.testSessionTotalToday} Test Session(s) today {c.testSessionFailedToday > 0 && <React.Fragment>(<b className={classes.dangerText}>{c.testSessionFailedToday} failed</b>)</React.Fragment>}
                    <br/>
                    {c.testSessionTotalLastMonth} Test Session(s) last month {c.testSessionFailedLastMonth > 0 && <React.Fragment>(<b className={classes.dangerText}>{c.testSessionFailedLastMonth} failed</b>)</React.Fragment>}
                    <br/>
                    {c.testSessionTotalLastYear} Test Session(s) last year {c.testSessionFailedLastYear > 0 && <React.Fragment>(<b className={classes.dangerText}>{c.testSessionFailedLastYear} failed</b>)</React.Fragment>}
                  </p>
                  <p className={classes.largeText + ' ' + classes.infoText}>
                    {c.convoTotalToday} Test Case(s) today {c.convoFailedToday > 0 && <React.Fragment>(<b className={classes.dangerText}>{c.convoFailedToday} failed</b>)</React.Fragment>}
                    <br/>
                    {c.convoTotalLastMonth} Test Case(s) last month {c.convoFailedLastMonth > 0 && <React.Fragment>(<b className={classes.dangerText}>{c.convoFailedLastMonth} failed</b>)</React.Fragment>}
                    <br/>
                    {c.convoTotalLastYear} Test Case(s) last year {c.convoFailedLastYear > 0 && <React.Fragment>(<b className={classes.dangerText}>{c.convoFailedLastYear} failed</b>)</React.Fragment>}
                  </p>
                </GridItem>
              </GridContainer>)
            }}
          </Query>
        </CardBody>
      </Card>
    )
  }
  ___renderActivityCards() {
    const { classes } = this.props

    return (
          <Query
            query={TESTSESSIONCOUNT_QUERY}
            fetchPolicy={'network-only'}
            notifyOnNetworkStatusChange={true}
          >
            {({ loading, error, data }) => {
              if (loading) {
                return <LoadingIndicator large />
              }
              if (error) {
                return <ErrorFormat err={error} />
              }

              if (!data || !data.chartTestSessionsCount) {
                return null
              }
              const c = data.chartTestSessionsCount

              return (<GridContainer data-unique="chartTestSessionsCount">
                <GridItem xs={2}>
                  <Card secondary>
                    <CardBody>
                      <StatsText
                        error={c.testSessionFailedToday > 0}
                        success={c.testSessionFailedToday === 0}
                        primaryText={c.testSessionFailedToday}
                        primarySuffix="Failed"
                        secondaryText={<><b>{c.testSessionTotalToday}</b> Test Session(s)</>}
                      />
                      <div className={classes.recentlyFailedCardProgress}>
                        <CircularProgress
                          size={20}
                          variant="static"
                          color="inherit"
                          value={(c.testSessionFailedToday / c.testSessionTotalToday) * 100}
                        />
                      </div>
                      <div className={classes.recentlyFailedCardTimeFrame}>Today</div>
                    </CardBody>
                  </Card>
                </GridItem>
                <GridItem xs={2}>
                  <Card secondary>
                    <CardBody>
                      <StatsText
                        error={c.testSessionFailedLastMonth > 0}
                        success={c.testSessionFailedLastMonth === 0}
                        primaryText={c.testSessionFailedLastMonth}
                        primarySuffix="Failed"
                        secondaryText={<><b>{c.testSessionTotalLastMonth}</b> Test Session(s)</>}
                      />
                      <div className={classes.recentlyFailedCardProgress}>
                        <CircularProgress
                          size={20}
                          variant="static"
                          color="inherit"
                          value={(c.testSessionFailedLastMonth / c.testSessionTotalLastMonth) * 100}
                        />
                      </div>
                      <div className={classes.recentlyFailedCardTimeFrame}>Last Month</div>
                    </CardBody>
                  </Card>
                </GridItem>
                <GridItem xs={2}>
                  <Card secondary>
                    <CardBody>
                      <StatsText
                        error={c.testSessionFailedLastYear > 0}
                        success={c.testSessionFailedLastYear === 0}
                        primaryText={c.testSessionFailedLastYear}
                        primarySuffix="Failed"
                        secondaryText={<><b>{c.testSessionTotalLastYear}</b> Test Session(s)</>}
                      />
                      <div className={classes.recentlyFailedCardProgress}>
                        <CircularProgress
                          size={20}
                          variant="static"
                          color="inherit"
                          value={(c.testSessionFailedLastYear / c.testSessionTotalLastYear) * 100}
                        />
                      </div>
                      <div className={classes.recentlyFailedCardTimeFrame}>Last Year</div>
                    </CardBody>
                  </Card>
                </GridItem>
                <GridItem xs={2}>
                  <Card secondary>
                    <CardBody>
                      <StatsText
                        error={c.convoFailedToday > 0}
                        success={c.convoFailedToday === 0}
                        primaryText={c.convoFailedToday}
                        primarySuffix="Failed"
                        secondaryText={<><b>{c.convoTotalToday}</b> Convo(s)</>}
                      />
                      <div className={classes.recentlyFailedCardProgress}>
                        <CircularProgress
                          size={20}
                          variant="static"
                          color="inherit"
                          value={(c.convoFailedToday / c.convoTotalToday) * 100}
                        />
                      </div>
                      <div className={classes.recentlyFailedCardTimeFrame}>Today</div>
                    </CardBody>
                  </Card>
                </GridItem>
                <GridItem xs={2}>
                  <Card secondary>
                    <CardBody>
                      <StatsText
                        error={c.convoFailedLastMonth > 0}
                        success={c.convoFailedLastMonth === 0}
                        primaryText={c.convoFailedLastMonth}
                        primarySuffix="Failed"
                        secondaryText={<><b>{c.convoTotalLastMonth}</b> Convo(s)</>}
                      />
                      <div className={classes.recentlyFailedCardProgress}>
                        <CircularProgress
                          size={20}
                          variant="static"
                          color="inherit"
                          value={(c.convoFailedLastMonth / c.convoTotalLastMonth) * 100}
                        />
                      </div>
                      <div className={classes.recentlyFailedCardTimeFrame}>Last Month</div>
                    </CardBody>
                  </Card>
                </GridItem>
                <GridItem xs={2}>
                  <Card secondary>
                    <CardBody>
                      <StatsText
                        error={c.convoFailedLastYear > 0}
                        success={c.convoFailedLastYear === 0}
                        primaryText={c.convoFailedLastYear}
                        primarySuffix="Failed"
                        secondaryText={<><b>{c.convoTotalLastYear}</b> Convo(s)</>}
                      />
                      <div className={classes.recentlyFailedCardProgress}>
                        <CircularProgress
                          size={20}
                          variant="static"
                          color="inherit"
                          value={(c.convoFailedLastYear / c.convoTotalLastYear) * 100}
                        />
                      </div>
                      <div className={classes.recentlyFailedCardTimeFrame}>Last Year</div>
                    </CardBody>
                  </Card>
                </GridItem>
              </GridContainer>)
            }}
          </Query>
    )
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default compose(
  withTheme(),
  withStyles((theme) => ({ ...dashboardStyle(theme), ...typographyStyle(theme) })),
  connect(
    state => ({ user: state.token.user, license: state.settings.license, dashboard: state.dashboard }),
    { clearDashboardSettings, setDashboardSettings }
  ),
  graphql(TESTPROJECTS_COUNT_QUERY, {
    skip: (props) => !hasPermission(props.user, 'TESTSESSIONS_SELECT'),
    options: {
      variables: { timeFrame: 'LASTWEEK'},
      fetchPolicy: 'network-only'
    },
    props: ({data}) => ({
      testProjectCountData: data,
    }),
  }),
  graphql(RATIOBYTESTPROJECTBYDAY_QUERY, {
    skip: (props) => !hasPermission(props.user, 'TESTSESSIONS_SELECT'),
    options: {
      variables: { timeFrame: 'LASTWEEK'},
      fetchPolicy: 'network-only'
    },
    props: ({data}) => ({
      ratioByTestProjectData: data,
    }),
  }),
  graphql(RATIOBYDAY_QUERY, {
    skip: (props) => !hasPermission(props.user, 'TESTSESSIONS_SELECT'),
    options: {
      variables: { timeFrame: 'LAST2WEEKS'},
      fetchPolicy: 'network-only'
    },
    props: ({data}) => ({
      ratioByDayData: data,
    }),
  })
)(Dashboard)
