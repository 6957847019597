import React from 'react'
import TestSetSelector from './TestSetSelector'

export default function () {
  return <TestSetSelector
    link="/testdatawizards/translator/"
    title="Select Test Set for Translation"
    queryVariables={
    { where:
        {
          OR: [
            { statsUtterancesCount_gt: 0 },
            { statsConvoCount_gt: 0 },
            { statsPartialConvoCount_gt: 0 }
          ]
        }
    }}
  />
}
