import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import withStyles from '@material-ui/core/styles/withStyles'
//import { NavLink } from 'react-router-dom'
import dialogStyle from 'assets/jss/material-dashboard-react/components/dialogStyle.jsx'

import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import ConfirmationDialog from 'components/Dialog/ConfirmationDialog.jsx'

import { CustomCheckbox } from 'components/Form/Form'
import moment from 'moment'

import config from '../../config'
import { hideMaintenanceId } from 'actions/alert'
import Text from 'components/Typography/Text'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import { Paper } from '@material-ui/core'


//image
import scheduledmaintenanceImg from 'assets/img/scheduledmaintenance.png'


class MaintenancePopup extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      show: false,
      notShowAgain: false,
      fetching: false,
      maintenance: null
    }
  }

  componentDidMount() {
    this.reloadMaintenance()
  }

  async reloadMaintenance() {
    if (window.Cypress) return

    try {
      this.setState({ fetching: true, maintenance: null })
      const maintenanceResponse = await fetch(`${config.api.ext}/maintenance`, {
        method: 'GET',
        credentials: 'include'
      })
      const maintenanceContent = await maintenanceResponse.json()
      if (maintenanceContent && maintenanceContent.id) {
        this.setState({ fetching: false, maintenance: maintenanceContent })
      } else {
        this.setState({ fetching: false, maintenance: null })
      }
    } catch (err) {
      this.setState({ fetching: false, maintenance: null })
    }
    
  }

  showPopup() {
    const { show, fetching, maintenance } = this.state
    const { hideMaintenanceIds } = this.props

    if (window.Cypress) return false
    if (!show) return false
    if (fetching) return false
    if (!maintenance || !maintenance.id) return false

    if (hideMaintenanceIds.indexOf(maintenance.id) >= 0) return false
    return true
  }

  render() {
    const { maintenance } = this.state
    const { hideMaintenanceId, classes } = this.props
    if (!maintenance || !maintenance.id) return false

    const closeDialog = () => {
      this.setState({ show: false })
      if (this.state.notShowAgain) {
        hideMaintenanceId(maintenance.id)
      }
    }

    return (<ConfirmationDialog
      open={this.showPopup()}
      okText="Close"
      onOk={closeDialog}
      extraButtonLeft={
        <CustomCheckbox useCheckbox dense
          label=" Do not show this scheduled maintenance hint again"
          input={{
            onChange: (e) => this.setState({ notShowAgain: e.target.checked }),
            checked: this.state.notShowAgain
          }}
          data-unique="chkNotShowAgain"
        />
      }
      data-unique="dlgScheduledMaintenance"
    >

      <Paper className={classes.paperPosition}>
        <Card>
          <CardBody>
            <GridContainer>
              <GridItem xs={4}>
                <img alt="Scheduled Maintenance" src={scheduledmaintenanceImg} />
              </GridItem>
              <GridItem xs={8}>
                <Text lg bold >Scheduled Maintenance</Text>
                <Text md><b>{moment(maintenance.start).format('MMMM Do')}</b> {moment(maintenance.start).format('YYYY, h:mm a')} to {moment(maintenance.end).format('h:mm a')}</Text>
                <Text topMargin>Release Notes are available, <a href="https://support.botium.ai/hc/en-us/sections/10736099277327-Release-Notes" target="_blank" rel="noopener noreferrer">here</a> </Text>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </Paper>
    </ConfirmationDialog>)
  }
}

const MaintenancePopupState = connect(
  state => ({
    hideMaintenanceIds: (state.maintenancepopup && state.maintenancepopup.hideMaintenanceIds) || []
  }),
  { hideMaintenanceId }
)(withRouter(MaintenancePopup))



export default withStyles(dialogStyle) (MaintenancePopupState)