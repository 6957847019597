import React from 'react'
import { connect } from 'react-redux'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import { Form } from 'react-final-form'
// apollo
import { Mutation, compose, graphql, withApollo } from 'react-apollo'
// core components
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import Button from 'components/Button/Button'
import UnsavedFormSpy from 'components/Form/UnsavedFormSpy'
import Field from 'components/Form/OptionalField'
import LoadingIndicator from 'components/Icon/LoadingIndicator'

import ShowIcon from 'components/Icon/ShowIcon'

import {
  renderTextField,
  renderSelect,
  renderFileUpload,
  required,
  url,
  FormActionsToolbar, renderNamespaceField
} from 'components/Form/Form'
import { setAlertSuccessMessage, setAlertErrorMessage } from 'actions/alert'
import { getConnector } from 'actions/settings'

import testprojectsStyle from 'assets/jss/material-dashboard-react/views/testprojectsStyle.jsx'

import { CREATE_FACTCHECKERPROJECT, START_FACTCHECKER_UPDATE_INDEX, START_FACTCHECKER_CREATE_SAMPLES, DeleteFactCheckerIndexListsFromCache, RefetchFactCheckerProjectIndexQueries, DeleteFactCheckerListsFromCache, RefetchFactCheckerProjectQueries } from './gql'
import { CHATBOTS_DROPDOWN_QUERY } from '../Chatbots/gql'
import { RefetchTestSetQueries } from '../TestSets/gql'
import { safeGetNamespaceFilteredList } from '../helper.jsx'

import { hasPermission } from 'botium-box-shared/security/permissions'
import FactCheckerProjectsEmbeddedTable from './FactCheckerProjectsEmbeddedTable.jsx'

class FactCheckerProjects extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      newProjectExpanded: false,
      emptyFactCheckerProject: {
        name: 'New FactCheck Project',
        namespace: props.namespace.selected ? props.namespace.selected.name : null,
        chatbot: null,
        filename: null,
        filecontent: null,
        fileupload: null
      }
    }
  }

  renderStartForm() {
    const { setAlertSuccessMessage, setAlertErrorMessage, history, chatbotsData } = this.props


    return (
      <Mutation
        mutation={CREATE_FACTCHECKERPROJECT}
        refetchQueries={[
          ...RefetchFactCheckerProjectQueries(),
          ...RefetchTestSetQueries()
        ]}
        update={DeleteFactCheckerListsFromCache}
      >
        {(createFactCheckerProject, { loading, error }) => {
            return <Mutation
                mutation={START_FACTCHECKER_UPDATE_INDEX}
                onCompleted={data => {
                  setAlertSuccessMessage('FactCheck Indexing started ...')
                  //this.setState({ newIndexSessionCount: this.state.newIndexSessionCount + 1 })
                }}
                onError={error => {
                  setAlertErrorMessage('FactCheck Indexing failed', error)
                }}
                refetchQueries={[
                  ...RefetchFactCheckerProjectIndexQueries()
                ]}
                update={DeleteFactCheckerIndexListsFromCache}
              >
                {(startFactCheckerIndex, { loading, error }) =>
                  <Mutation
                    mutation={START_FACTCHECKER_CREATE_SAMPLES}
                    onCompleted={data => {
                      setAlertSuccessMessage('FactCheck Sampling started ...')
                      this.setState({ newIndexSessionCount: this.state.newIndexSessionCount + 1 })
                    }}
                    onError={error => {
                      setAlertErrorMessage('FactCheck Sampling failed', error)
                    }}
                    refetchQueries={[
                      ...RefetchFactCheckerProjectIndexQueries()
                    ]}
                    update={DeleteFactCheckerIndexListsFromCache}
                  >
                    {(startFactCheckerSampling, { loading, error }) =>
                      <Form
                        onSubmit={async (values, form) => {
                          try {
                            const res = await createFactCheckerProject({
                              variables: {
                                input: {
                                  name: values.name,
                                  namespace: values.namespace,
                                  chatbotId: values.chatbotId,
                                  files: values.filename ? [{
                                    filename: values.filename,
                                    filecontent: values.filecontent
                                  }] : [],
                                  urls: values.url ? [values.url] : []
                                }
                              },
                            })
                            form.initialize(this.state.emptyFactCheckerProject)
                            setAlertSuccessMessage('FactCheck Project registered')
                            history.push(`/factcheck/projects/view/${res.data.createFactCheckerProject.id}/content`)
                          } catch (error) {
                            setAlertErrorMessage(`FactCheck Project registration failed`, error)
                          }
                        }}
                        initialValues={this.state.emptyFactCheckerProject}
                        render={({
                          handleSubmit,
                          submitting,
                          invalid,
                          values,
                          form: { change }
                        }) => (
                          <form onSubmit={handleSubmit}>
                            <UnsavedFormSpy />
                            <GridContainer>
                              <GridItem md={12} lg={6}>
                                <Field
                                  name="name"
                                  component={renderTextField}
                                  label="FactCheck Project Name"
                                  validate={required}
                                  data-unique="txtFactCheckerProjectRegisterName"
                                />
                              </GridItem>
                              <GridItem md={12} lg={6}>
                                <Field
                                  name="namespace"
                                  component={renderNamespaceField}
                                  forWrite
                                  label="Namespace"
                                  data-unique="txtCrawlerProjectNamespace"
                                />
                              </GridItem>
                              <GridItem md={12} lg={6}>
                                <Field
                                  name="chatbotId"
                                  component={renderSelect}
                                  label="Select a Chatbot"
                                  data-unique={`selFactCheckerProjectRegisterChatbot`}
                                  validate={required}
                                  items={chatbotsData && chatbotsData.chatbots && safeGetNamespaceFilteredList(chatbotsData.chatbots, this.props.namespace).map(c => {
                                    return {
                                      key: c.id,
                                      chatbot: c
                                    }
                                  })
                                  }
                                />
                              </GridItem>
                              <GridItem md={12} lg={6}>
                                <Field
                                  name="url"
                                  component={renderTextField}
                                  label="Download source of truth"
                                  validate={url}
                                  data-unique="txFactCheckerProjectRegisterUrl"
                                  helperText="Botium FactCheck will download the source of truth from this website"
                                />
                              </GridItem>
                              <GridItem md={12}>
                                <Field
                                  name="fileupload"
                                  component={renderFileUpload}
                                  data-unique={`uploadFactCheckerProjectRegisterFileupload`}
                                  label={`Upload source of truth (.pdf, .docx, .html, .txt) - max. 50 MB per file allowed`}
                                  values={values}
                                  change={change}
                                  accept=".txt,.pdf,.html,.docx,.doc"
                                  processingFiles={submitting}
                                  onFileLoaded={(filename, filecontent) => {
                                    change('filename', filename)
                                    change('filecontent', filecontent)
                                  }}
                                />
                              </GridItem>
                              <GridItem xs={12}>
                                <FormActionsToolbar
                                  rightButtons={<>
                                    <Button
                                      secondary
                                      disabled={submitting}
                                      onClick={() => this.setState({ newProjectExpanded: false })}
                                      data-unique="btnTestProjectRegisterCancel" 
                                    >
                                      Cancel
                                    </Button>
                                    <Button
                                      type="submit"
                                      disabled={submitting || invalid}
                                      data-unique="btnFactCheckerProjectRegisterSave"
                                    >
                                      {submitting && <LoadingIndicator alt />}
                                      Start
                                    </Button>

                                  </>}
                                />
                              </GridItem>
                            </GridContainer>
                          </form>
                        )}
                      />
                    }
                  </Mutation>
                }
              </Mutation>
          }
        }
      </Mutation>
    )
  }

  render() {
    if (this.state.newProjectExpanded) {
      return this.renderStartForm()
    }

    return this.renderTableMode()
  }

  renderTableMode() {
    const { user, classes } = this.props
    return (
      <GridContainer noPadding>
        <GridItem xs={12} right>
          <Button noMargin
            onClick={() => this.setState({ newProjectExpanded: true })}
            data-unique="btnQuickstartGoToTestProjects"
            disabled={!hasPermission(user, 'TESTPROJECTS_CREATE')}
          >
            <ShowIcon icon="plus" />
            Create Test
          </Button>
        </GridItem>
        <GridItem xs={12} className={classes.projectsListEmbeddedTable}>
          <FactCheckerProjectsEmbeddedTable name="FactCheckerProjects" disableOrderBy disableFooter={false}  />
        </GridItem>
      </GridContainer>
    )
  }
}

export default compose(
  withStyles(testprojectsStyle),
  connect(
    state => ({ user: state.token.user, settings: state.settings, license: state.settings.license, namespace: state.namespace }),
    { getConnector, setAlertSuccessMessage, setAlertErrorMessage },
  ),
  graphql(CHATBOTS_DROPDOWN_QUERY, {
    props: ({ data }) => ({
      chatbotsData: data,
    }),
  }),
  withApollo
)(FactCheckerProjects)
