import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
// apollo
import { Query } from 'react-apollo'
// core components
import Table from 'components/Table/AdvancedTable.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import Card from 'components/Card/Card.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import CustomTabs from 'components/Tabs/CustomTabs.jsx'
import DateFormat from 'components/Info/DateFormat'
import { setAlertSuccessMessage, setAlertErrorMessage } from 'actions/alert'
import { removeRecentListEntry } from 'actions/activity'
import QueryStatus from 'components/Info/QueryStatus'
import ShowIcon from 'components/Icon/ShowIcon'
import ObjectChips from 'components/Chip/ObjectChips'
import ConfirmationDialog from 'components/Dialog/ConfirmationDialog.jsx'
import Text from 'components/Typography/Text.jsx'
import LinkButton from 'components/Button/LinkButton'
import TestSessionProgress from './TestSessionProgress.jsx'
import Divider from 'components/Divider/Divider.js'
import { getRootPath } from './helper'
import Tooltip from 'components/Tooltip/Tooltip'
import testsessionsStyle from 'assets/jss/material-dashboard-react/views/testsessionsStyle.jsx'
import Button from 'components/Button/Button'
import { CustomCheckbox } from 'components/Form/Form'

import {
  TESTSESSION_QUERY,
  TESTSESSION_PROGRESS_FACTCHECKER_QUERY,
  TESTSESSIONPROGRESS_FACTCHECKER_SUBSCRIPTION
} from './gql.js'

class FactCheckerSession extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      testSessionProgressKey: 0,
      resultErr: null,
      resultQuery: null,
      filterAccurate: false,
      filterInAccurate: false,
      filterOutOfScope: false,
      filterResultExplanation: false,
      filterShowError: false,
    }
  }

  handleFilterChange = (filterName) => (event) => {
    this.setState({ [filterName]: event.target.checked })
  }

  countCheckedCheckboxes() {
    const { filterAccurate, filterInAccurate, filterOutOfScope, filterResultExplanation, filterShowError, filterFailedOrUnknown } = this.state
    const checkboxes = [filterAccurate, filterInAccurate, filterOutOfScope, filterResultExplanation, filterShowError, filterFailedOrUnknown]
    return checkboxes.filter(checked => checked).length
  }


  renderDashboard(testsession, loading) {
    const { classes } = this.props
    const { filterAccurate, filterInAccurate, filterOutOfScope, filterResultExplanation, filterShowError } = this.state
    const checkedCount = this.countCheckedCheckboxes()


    return (<GridContainer>
      <TestSessionProgress
        key={this.state.testSessionProgressKey}
        query={TESTSESSION_PROGRESS_FACTCHECKER_QUERY}
        subscription={TESTSESSIONPROGRESS_FACTCHECKER_SUBSCRIPTION}
        testSession={testsession}>
        {({ testSessionProgress }) => {
          const isDone = testSessionProgress && (testSessionProgress.status === 'READY' || testSessionProgress.status === 'FAILED' || testSessionProgress.status === 'CANCELLED')
          const totalCount = (testSessionProgress.factCheckerSession && testSessionProgress.factCheckerSession.totalCount) || 0
          const dangerCount = (testSessionProgress.factCheckerSession && testSessionProgress.factCheckerSession.incorrectCount) || 0
          const unknownCount = (testSessionProgress.factCheckerSession && (testSessionProgress.factCheckerSession.failedCount + testSessionProgress.factCheckerSession.unknownCount)) || 0
          const correctCount = (testSessionProgress.factCheckerSession && testSessionProgress.factCheckerSession.correctCount) || 0


          return (
            <React.Fragment>
              <GridItem xs={12} right middle smallPadding smallMarginRight>
                <>
                  {testsession.id && testsession.testProject && <React.Fragment>
                    <Text primary>Recent Test Sessions</Text><Text paddingLeftRight>  |  </Text><Text primary><DateFormat seconds>{testsession.createdAt}</DateFormat></Text>
                  </React.Fragment>
                  }
                </>
                <GridItem smallMarginRight ></GridItem>
              </GridItem>
              <GridItem xs={12} grid>
                <GridContainer border borderRadius noMargin>
                  <GridItem lg borderRight>
                    <Card noBorder noMarginBottom noMarginTop>
                      <CardBody LargePadding>
                        <GridContainer fullWidth>
                          <GridItem lg={12} middle noPaddingLeft><Text regular>Total</Text></GridItem>
                          <GridItem lg={12} middle className={classes.textLeftBorderDefault}><Text mlg bold>{isDone ? totalCount : '?'}</Text></GridItem>
                        </GridContainer>
                      </CardBody>
                    </Card>
                  </GridItem>
                  <GridItem lg borderRight>
                    <Card noBorder noMarginBottom noMarginTop>
                      <CardBody LargePadding>
                        <GridContainer fullWidth>
                          <GridItem lg={12} middle noPaddingLeft><Text regular>Accurate</Text></GridItem>
                          <GridItem xs={12} middle className={classes.textLeftBorderGreen}>
                            {!isDone && <Text mlg bold>?</Text>}
                            {isDone && correctCount > 0 && <Text mlg bold>{correctCount}</Text>}
                            {isDone && correctCount === 0 && <Text mlg bold>{correctCount}</Text>}
                          </GridItem>
                        </GridContainer>
                      </CardBody>
                    </Card>
                  </GridItem>
                  <GridItem lg borderRight>
                    <Card noBorder noMarginBottom noMarginTop>
                      <CardBody LargePadding>
                        <GridContainer fullWidth>
                          <GridItem lg={12} middle noPaddingLeft><Text regular>Inaccurate</Text></GridItem>
                          <GridItem lg={12} middle className={classes.textLeftBorderRed}>
                            {!isDone && <Text mlg bold>?</Text>}
                            {isDone && dangerCount > 0 && <Text mlg bold>{dangerCount}</Text>}
                            {isDone && dangerCount === 0 && <Text mlg bold>{dangerCount}</Text>}
                          </GridItem>
                        </GridContainer>
                      </CardBody>
                    </Card>
                  </GridItem>
                  <GridItem lg borderRight>
                    <Card noBorder noMarginBottom noMarginTop>
                      <CardBody LargePadding>
                        <GridContainer fullWidth>
                          <GridItem lg={12} middle noPaddingLeft><Text regular>Out of Scope</Text></GridItem>
                          <GridItem lg={12} middle className={classes.textLeftBorderYellow}>
                            {!isDone && <Text mlg bold>?</Text>}
                            {isDone && unknownCount > 0 && <Text mlg bold>{unknownCount}</Text>}
                            {isDone && unknownCount === 0 && <Text mlg bold>{unknownCount}</Text>}
                          </GridItem>
                        </GridContainer>
                      </CardBody>
                    </Card>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </React.Fragment>
          )
        }}
      </TestSessionProgress>
      <GridItem lg={12}>
        <ObjectChips dataUniquePrefix="btnTestSession" factCheckerProject={testsession.factCheckerSession && testsession.factCheckerSession.project} chatbot={testsession.chatbot} testSets={testsession.testSets} deviceSets={testsession.deviceSets} />
      </GridItem>
      <GridItem xs={12}>
        <Card noMarginTop>
          <CardBody>
            <Text bold lg>Results</Text>
            <TestSessionProgress
              key={this.state.testSessionProgressKey}
              query={TESTSESSION_PROGRESS_FACTCHECKER_QUERY}
              subscription={TESTSESSIONPROGRESS_FACTCHECKER_SUBSCRIPTION}
              testSession={testsession}>
              {({ testSessionProgress, testSessionProgressLoading, testSessionProgressErr }) => {
                const queries = (testSessionProgress && testSessionProgress.factCheckerSession && testSessionProgress.factCheckerSession.queries) || []

                const filteredQueries = queries.filter(query => {
                  if(!filterAccurate && !filterInAccurate && !filterOutOfScope && !filterResultExplanation && !filterShowError) {
                    return true
                  }
                  if((filterAccurate && query.status === 'CORRECT') || (filterInAccurate && query.status === 'INCORRECT') || (filterOutOfScope && (query.status === 'FAILED' || query.status === 'UNKNOWN'))
                    || (filterResultExplanation && query.result) || (filterShowError && query.err)) {
                    return true
                  } else {
                    return false
                  }
                })

                return <Table
                  tableHeaderColor="primary"
                  disableFilter
                  customActionsRight={
                    <>
                      <GridContainer >
                        <GridItem md={12} right>
                          <Button
                            className={classes.testStatusButtonPosition}
                            onClick={() => this.setState({
                              filtersTestResultsExpanded: !this.state.filtersTestResultsExpanded,
                              filtersTestTypeExpanded: false
                            })}

                            label="Chatbot"
                            dropdownSelectAvtive={this.state.filtersTestResultsExpanded}
                            dropdownSelect
                            data-unique="btnFilterViewResultsExpanded"
                          >
                            Test Status {checkedCount > 0 ? <span className={classes.valuebuble}>{checkedCount}</span> : <span className={classes.valuebubleempty}>0</span>}
                            <ShowIcon icon={this.state.filtersTestResultsExpanded ? 'caret-up' : 'caret-down'} />
                          </Button>
                        </GridItem>
                        <GridItem md={12} right >
                          <GridContainer paddingTop>
                            {this.state.filtersTestResultsExpanded && <><GridItem middle>
                              <CustomCheckbox data-unique="chkFilterViewCorrect"
                                formControlProps={{ className: classes.statustypefilter }}
                                useCheckbox
                                input={{
                                  onChange: this.handleFilterChange('filterAccurate'),
                                  checked: filterAccurate
                                }}
                                label="ACCURATE"
                              />
                              <CustomCheckbox data-unique="chkFilterViewIncorrect"
                                formControlProps={{ className: classes.statustypefilter }}
                                useCheckbox
                                input={{
                                  onChange: this.handleFilterChange('filterInAccurate'),
                                  checked: filterInAccurate
                                }}
                                label="INACCURATE"
                              />
                              <CustomCheckbox data-unique="chkFilterViewIncorrect"
                                formControlProps={{ className: classes.statustypefilter }}
                                useCheckbox
                                input={{
                                  onChange: this.handleFilterChange('filterOutOfScope'),
                                  checked: filterOutOfScope
                                }}
                                label="OUT OF SCOPE"
                              />
                            </GridItem>
                              <Divider orientation="vertical" secondary />
                              <GridItem>
                                <CustomCheckbox data-unique="chkFilterViewIncorrect"
                                  formControlProps={{ className: classes.statustypefilter }}
                                  useCheckbox
                                  input={{
                                    onChange: this.handleFilterChange('filterResultExplanation'),
                                    checked: filterResultExplanation
                                  }}
                                  label="Result Explanation"
                                />
                                <CustomCheckbox data-unique="chkFilterViewIncorrect"
                                  formControlProps={{ className: classes.statustypefilter }}
                                  useCheckbox
                                  input={{
                                    onChange: this.handleFilterChange('filterShowError'),
                                    checked: filterShowError
                                  }}
                                  label="Show Error"
                                />
                              </GridItem></>}
                          </GridContainer>
                        </GridItem>
                      </GridContainer>
                    </>
                  }


                  tableHead={[
                    { name: 'Question', width: 'medium'},
                    { name: 'Answer', width: 'medium' },
                    { name: 'Status'},
                    '',
                    ''
                  ]}
                  pageErr={testSessionProgressErr}
                  name={`TestSession_${testsession.id}_FactChecker`}
                    tableData={_.orderBy(filteredQueries, [(i) => i.status === 'INCORRECT' ? 0 : i.status === 'CORRECT' ? 1 : 2, 'query'], ['asc', 'asc']).map(query => {
  
                    return [
                      () => <Text>{query.query}</Text>,
                      () => <Text>{query.answer}</Text>,
                      () => <Text noWrap
                          successBg={query.status === 'CORRECT'}
                          dangerBg={query.status === 'INCORRECT'}
                          warningBg={query.status !== 'CORRECT' && query.status !== 'INCORRECT'}
                          icon={query.status === 'CORRECT' ? 'check' : query.status === 'INCORRECT' ? 'exclamation' : 'question'}
                        >
                        {(query.status === 'CORRECT' ? 'ACCURATE' : query.status === 'INCORRECT' ? 'INACCURATE' : 'OUT OF SCOPE')}
                        </Text>,
                      () => query.result && <LinkButton onClick={() => this.setState({ resultQuery: { ...query, result: JSON.parse(query.result) }, resultErr: null })}><Text noWrap>Result Explanation</Text></LinkButton>,
                      () => query.err && <LinkButton onClick={() => this.setState({ resultQuery: null, resultErr: query.err})}><Text noWrap>Show Error</Text></LinkButton>,
                    ]
                  })}
                
                />
              }}
            </TestSessionProgress>
            <ConfirmationDialog
              open={!!this.state.resultErr}
              onOk={() => this.setState({ resultErr: null })}
              title="FactCheck Failure"
            >
              <Text pre>{this.state.resultErr}</Text>
            </ConfirmationDialog>
            <ConfirmationDialog
              maxWidth="lg"
              open={!!this.state.resultQuery}
              onOk={() => this.setState({ resultQuery: null })}
              title="Result Explanation"
            >
              {this.state.resultQuery &&
                <GridContainer>
                  <GridItem xs={12} >
                    <Card>
                      <CardBody>
                        <GridContainer>
                          <GridItem md={12} largeMarginBottom>
                            <Text header>Question</Text>
                            <Text pre>{this.state.resultQuery.query}</Text>
                          </GridItem>
                          <GridItem md={5}>
                            <Text header>Bot Response</Text>
                            <Text pre>{this.state.resultQuery.answer}</Text>
                          </GridItem>
                          <GridItem md={2} center>
                            <Divider orientation="vertical" secondary />
                          </GridItem>
                          <GridItem md={5}>
                            <Text header>Aligned Response
                              <Tooltip title="The fact checker uses the source of truth you provided to adjust the bot's answer, creating what we call the 'Aligned Response.' Botium compares this aligned response with the original bot response to determine any discrepancies.">
                                <ShowIcon icon="question-circle" />
                              </Tooltip>
                            </Text>
                            <Text pre>{_.isArray(this.state.resultQuery.result.fixed_statement) ? this.state.resultQuery.result.fixed_statement[0] : this.state.resultQuery.result.fixed_statement}</Text>
                          </GridItem>
                        </GridContainer>
                      </CardBody>
                    </Card>
                  </GridItem>
                  <GridItem xs={12}>
                    <Card>
                      <CardBody>
                        <GridContainer>
                          <GridItem md={12} largeMarginBottom><Text header>Decision Breakdown</Text></GridItem>
                          <GridItem md={2} smallPaddingBottom></GridItem>
                          <GridItem md={5} smallPaddingBottom><Text subheader bold >Reason</Text></GridItem>
                          <GridItem md={5} smallPaddingBottom><Text subheader bold >Decision</Text></GridItem>
                          {(this.state.resultQuery.result.reasoning || []).map((reason, i) => <React.Fragment key={i}>
                            <GridItem md={2}>
                              {reason.status === 'INCORRECT' && <Text noWrap dangerBg icon="exclamation">INACCURATE</Text>}
                              {reason.status === 'CORRECT' && <Text noWrap successBg icon="check">ACCURATE</Text>}
                              {reason.status === 'UNKNOWN' && <Text noWrap warningBg icon="question">OUT OF SCOPE</Text>}
                            </GridItem>
                            <GridItem md={5}><Text>{reason.reason}</Text></GridItem>
                            <GridItem md={5}><Text>{reason.decision}</Text></GridItem>
                            <GridItem xs={12}>
                              <Divider orientation="horicontal" secondary />
                            </GridItem>
                          </React.Fragment>)}
                        </GridContainer>
                      </CardBody>
                    </Card>
                  </GridItem>
                </GridContainer>
              }
            </ConfirmationDialog>
          </CardBody>
        </Card>


      </GridItem>
    </GridContainer>)
  }

  getRootPath = () => {
    return getRootPath(this.props.location)
  }

 render() {
    const { match } = this.props
    const testSessionId = match.params.id

    return (
      <GridContainer>
        <GridItem xs={12}>
          <Query query={TESTSESSION_QUERY} variables={{ id: testSessionId }}>
            {(queryResult) => <QueryStatus {...queryResult} query="testsession" card loadable>{({ testsession, loading }) => {
              if (!testsession) testsession = { id: testSessionId, results: [], jobs: [], testProject: { nlpAnalytics: false } }
              return (
                <CustomTabs
                  name={`tabFactCheckerSession_${testSessionId}`}
                  headerColor="info"
                  plainTabs
                  tabs={[
                    {
                      tabName: 'Overview',
                      tabIcon: <ShowIcon icon="tachometer-alt" />,
                      tabContent: this.renderDashboard(testsession, loading),
                      dataUnique: 'btnTestSessionNavigationDashboard'
                    }
                  ]}
                />
              )
            }}</QueryStatus>}
          </Query>
        </GridItem>
      </GridContainer>
    )
  }
}

export default connect(
  state => ({ token: state.token.token, user: state.token.user, license: state.settings.license, features: state.settings.features }),
  { setAlertSuccessMessage, setAlertErrorMessage, removeRecentListEntry },
)(withStyles(testsessionsStyle, { withTheme: true })(FactCheckerSession))
