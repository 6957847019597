import {
  successColor,
  dangerColor,
  defaultBoxShadow
} from 'assets/jss/material-dashboard-react.jsx'
//import { nonExecutableDefinitionMessage } from 'graphql/validation/rules/ExecutableDefinitions'

const convoStyle = theme => ({
  senderErr: {
    ...defaultBoxShadow,
    minHeight: 40,
    '& span': {
      color: '#fff !Important',
    },
    transition: 'none',
    //backgroundColor: '#2A3F54',
    backgroundColor: 'none',
    borderRadius: 6,
    //borderBottomLeftRadius: 3,
    //borderTopRightRadius: 3,
    fontWeight: '300',
    padding: theme.spacing.unit * 1.5,
    marginLeft: theme.spacing.unit * 10,
    marginRight: theme.spacing.unit * 10,
    whiteSpace: 'pre-wrap',
    '&:focus': {
      border: `2px solid ${theme.colors.common.primary}`
    },
    '&:focus-visible': {
      border: `2px solid ${theme.colors.common.primary}`
    },
  },
  senderMe: {
    ...defaultBoxShadow,
    minHeight: 40,
    transition: 'none',
    display: 'flex',
    justifyContent: 'flex-end',
    color: theme.colors.common.primary,
    backgroundColor: '#ffffff',
    borderRadius: 15,
    borderBottomRightRadius: 3,
    padding: theme.spacing.unit * 1.0,
    marginLeft: theme.spacing.unit * 8,
    marginRight: theme.spacing.unit,
    whiteSpace: 'pre-wrap',
    paddingLeft: '5px!Important',
    '&:focus': {
      border: `2px solid ${theme.colors.common.primary}`
    },
    '&:focus-visible': {
      border: `2px solid ${theme.colors.common.primary}`
    },
    '& button': {
      borderColor: theme.colors.common.primary,
      '& svg': {
        color: theme.colors.common.primary,
      }
    }
  },
  senderInclude: {
    //...defaultBoxShadow,
    minHeight: 40,
    display: 'block',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    borderRadius: 15,
    borderColor: theme.colors.senderIncludeborder,
    borderWidth: 1,
    borderStyle: 'solid',
    padding: '8px!Important',
    marginLeft: theme.spacing.unit * 8,
    marginRight: theme.spacing.unit * 8,
    whiteSpace: 'pre-wrap',
    '&:focus': {
      border: `2px solid ${theme.colors.visibleborderColor}`
    },
    '&:focus-visible': {
      border: `2px solid ${theme.colors.visibleborderColor}`
    },
    '&:first-child': {
      padding: 'revert-layer'
    },
    '& button': {
      width: '100%',
      margin: '5px 0px',
    }
  },
  senderNone: {
    //...defaultBoxShadow,
    minHeight: 40,
    transition: 'none',
    display: 'flex',
    backgroundColor: 'transparent',
    borderColor: theme.colors.senderIncludeborder,
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 15,
    padding: theme.spacing.unit,
    marginLeft: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 8,
    whiteSpace: 'pre-wrap',
    '&:focus': {
      border: `2px solid ${theme.colors.visibleborderColor}`
    },
    '&:focus-visible': {
      border: `2px solid ${theme.colors.visibleborderColor}`
    },
  },

  listitemroot: {
    alignItems: 'normal!Important',
  },
 
  senderBot: {
    ...defaultBoxShadow,
    minHeight: 40,
    transition: 'none',
    backgroundColor: theme.colors.senderBotbg,
    borderRadius: 15,
    borderBottomLeftRadius: 3,
    color: 'white',
    padding: '9px',
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit * 8,
    whiteSpace: 'pre-wrap',
    '&:focus': {
      border: `2px solid ${theme.colors.common.white}`
    },
    '&:focus-visible': {
      border: `2px solid ${theme.colors.common.white}`
    },
    '& button': {
      borderColor: theme.colors.common.white,
      '& svg': {
        color: theme.colors.common.white,
      }
    }
  },
  senderOptional: {
    border: `3px dashed ${theme.colors.common.secondary}`
  },
  senderMeAvatar: {
    paddingRight: theme.spacing.unit * 8,
    paddingLeft: theme.spacing.unit * 2,
    
  },
  listItemModifyIcon: {
    color: `${theme.colors.primary}`
  },
  senderBotAvatar: {
    paddingRight: theme.spacing.unit * 2,
    paddingLeft: theme.spacing.unit * 8,
    position: 'relative',
    top: 0,
  },
  popupAttachments: {
    ...defaultBoxShadow,
    backgroundColor: theme.colors.backgroundPrimary,
    borderColor: theme.colors.primary,
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 30,
    borderTopRightRadius: 3,
    padding: theme.spacing.unit * 3
  },
  bubbleAttachments: {
    borderLeft: `5px solid #eee`,
    padding: 10,
    paddingLeft: 25,
    marginLeft: 10,
  },
  botMetadataBubble: {
    marginTop: 3,
    marginLeft: 3,
    fontSize: 11,
    display: 'inline-block',
    color: theme.colors.common.white
  },
  backgroundSuccess: {
    backgroundColor: successColor
  },
  backgroundDanger: {
    backgroundColor: dangerColor
  },
  colorSuccess: {
    color: successColor
  },
  colorDanger: {
    color: 'red'
  },
  borderSuccess: {
    borderColor: successColor,
    borderWidth: 2,
    borderStyle: 'solid'
  },
  borderDanger: {
    borderColor: dangerColor,
    borderWidth: 2,
    borderStyle: 'solid'
  },
  card: {
    //borderColor: theme.colors.common.primary,
    //borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: 5,
    //padding: 15,
    marginTop: theme.spacing.unit,
    marginButtom: theme.spacing.unit,
    //backgroundColor: theme.colors.common.white20,
    overflow: 'hidden'
  },

  customForm: {
    '& input':{
        color: `${theme.colors.common.primarydark} !Important`,
        height: '43px !Important',
        fontSize: '13px !Important',
        fontWeight: '500 !Important',
        boxSizing: 'border-box',
        borderRadius: 6,
        paddingLeft: '12px !Important',
        backgroundColor: 'transparent!Important',
        border: `1px solid ${theme.colors.common.primarydarkHover}`,
        '&:hover':{
          border: `1px solid ${theme.colors.common.primarydark}`,
        }
    },

    '&::placeholder':{
      fontWeight: 400,
      color: '#5e6c84',
      opacity: 1
    },

    '&:-ms-input-placeholder':{
      fontWeight: 400,
      color: '#5e6c84',
      opacity: 1
    },

    '&::-ms-input-placeholder':{
      fontWeight: 400,
      color: '#5e6c84',
      opacity: 1
    },

    '& input[type="date"]::-webkit-calendar-picker-indicator': {
      //display: none;
      //-webkit-appearance: none;
      fontSize: '25px !Important',
      marginRight: '12px !Important',
      filter: 'invert(0.8)',
      color: `${theme.colors.common.primarydark} !Important`,
    },

    '& input[type="time"]::-webkit-calendar-picker-indicator': {
    //display: none;
    //-webkit-appearance: none;
    fontSize: '25px !Important',
    marginRight: '12px !Important',
    filter: 'invert(0.8)',
    color: `${theme.colors.common.primarydark} !Important`,
    
    },

    '& input[type="checkbox"]': {
      position: 'relative',
      width: '22px',
      height: '22px!Important',
      color: `${theme.colors.common.primarydark} !Important`,
      border: `1px solid ${theme.colors.common.primarydark}`,
      borderRadius: '3px',
      '-webkit-appearance': 'none',
      '-moz-appearance': 'none',
      'appearance': 'none',
      outline: 0,
      cursor: 'pointer',
      transition: 'background 175ms cubic-bezier(0.1, 0.1, 0.25, 1)',
      marginBottom: '15px!Important',
    },

    '& input[type="checkbox"]::before': {
      position: 'absolute',
      content: '""',
      display: 'block',
      top: '2px',
      left: '7px',
      width: '5px',
      height: '10px',
      borderStyle: 'solid',
      borderColor: `${theme.colors.common.primary} !Important`,
      borderWidth: '0 2px 2px 0',
      transform: 'rotate(45deg)',
      opacity: 0,
     
    },

    '& input[type="checkbox"]:checked': {
      color: '#fff',
      borderColor: `${theme.colors.common.primarydark} !Important`,
      backgroundColor: `${theme.colors.common.primarydark} !Important`,
      },

      '& input[type="checkbox"]:checked::before': {
        opacity: 1,
        },

        '& label': {
          position: 'relative',
          top: '-8px!Important',
        },

    '& textarea':{
      color: `${theme.colors.common.primarydark} !Important`,
        minHeight: '43px !Important',
        fontSize: '13px !Important',
        fontWeight: '500 !Important',
        boxSizing: 'border-box',
        borderRadius: 6,
        paddingLeft: '12px !Important',
        paddingTop: '12px !Important',
        backgroundColor: 'transparent',
        border: `1px solid ${theme.colors.common.primarydarkHover}`,
        '&:hover':{
          border: `1px solid ${theme.colors.common.primarydark}`,
        }
    },

    '& select':{
      color: `${theme.colors.common.primarydark} !Important`,
      minHeight: '43px !Important',
      fontSize: '13px !Important',
      fontWeight: '500 !Important',
      boxSizing: 'border-box',
      border: `1px solid ${theme.colors.common.primarydark}`,
      borderRadius: 6,
      backgroundColor: 'transparent',
      paddingLeft: '12px !Important',
      paddingRight: '12px !Important',
      backgroundImage: `linear-gradient(45deg, transparent 50%, ${theme.colors.common.primarydark} 60%),linear-gradient(135deg, ${theme.colors.common.primarydark} 40%, transparent 50%) !important`,
      backgroundPosition: 'calc(100% - 30px) 16px,calc(100% - 23px) 16px,100% 0',
      backgroundSize: '7px 7px, 7px 7px',
      backgroundRepeat: 'no-repeat',
      '-webkit-appearance': 'none',
      '-moz-appearance': 'none',
  },

  '& button':{
    minHeight: 'auto',
    minWidth: 'auto',
    backgroundColor: theme.colors.common.secondary,
    color: '#FFFFFF',
    border: 'none',
    borderRadius: '5px',
    position: 'relative',
    padding: '14px 20px',
    margin: '.3125rem 10px',
    fontSize: '12px',
    fontWeight: '400',
    textTransform: 'uppercase',
    letterSpacing: '0',
    willChange: 'box-shadow, transform',
    lineHeight: '1.42857143',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    touchAction: 'manipulation',
    cursor: 'pointer',

  },

  

  '& p': {
    color: 'white',
    textAlign: 'left',
  },
    backgroundColor: 'transparent'
  },

  btnAddUserConvo: {
    backgroundColor: '#F2F2F2',
    color: '#2A3F54',
    borderColor: theme.colors.primary,
    borderWidth: 1,
    borderStyle: 'solid',
    fontWeight: 'bold',
    '&:hover,&:focus': {
      color: '#2A3F54',
      backgroundColor: '#F2F2F2',
      borderColor: theme.colors.primary,
      borderWidth: 1,
      borderStyle: 'solid',
    }
  },
  btnAddInclude: {
    backgroundColor: '#87dde8',
    color: '#2A3F54',
    borderColor: theme.colors.primary,
    borderWidth: 1,
    borderStyle: 'dotted',
    fontWeight: 'bold',
    marginLeft: '-50px',
    '&:hover,&:focus': {
      color: '#2A3F54',
      backgroundColor: '#87dde8',
      borderColor: theme.colors.primary,
      borderWidth: 1,
      borderStyle: 'dotted',
    }
  },
  btnThreedot: {
    margin: '5px 5px 0 0',
    padding: '2px 10px 3px 10px',
    borderRadius: 3,
    borderWidth: 0,
    maxWidth: 32,
    width: 32,
    height: 32,
    backgroundColor: '#7e7e7e00',
    boxShadow: 'none',
    '&:hover,&:focus': {
      backgroundColor: '#7e7e7e73'
    }
  },
  addStepButton: {
    color: `${theme.colors.primary} !important`,
    '&,&:hover': {
      marginTop: '0px !important', 
      marginBottom: '0px !important', 
      padding: '12px 20px', 
      color: theme.colors.common.primary,
      fontWeight: 'bold'
    }
  },  
  testsetsUtterancegridbg: {
    backgroundColor: theme.colors.testsetseditorbg,
  },
  editField: {
    width: '100%',
  },
  testsetsUtteranceedit:{
    '& div': {
       marginTop: 12,
       width: '100%',
       marginRight: 25,
       height: 30,
       borderRadius: 5,
    },
    '& div:hover': {
      backgroundColor: `${theme.colors.tableRowHover}`
    }
  },
  testsetsUtteranceswitch: {
    //paddingTop: 13,
  },
  listitemiconfixwidth: {
    width: 18,
  },
  errorBubble: {
    borderLeft: `5px solid ${theme.colors.common.danger}`,
    paddingTop: 5,
    paddingBottom: 5,
    marginLeft: 10,
    borderBottom: '1px solid #eee',
    '&:focus-visible': {
      border: `2px solid ${theme.colors.visibleborderColor}`
    },
  },
  inputFlexWidth: {
    '& input':{
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }
  },
  senderBotIcon: {
    padding: '8px 15px 0 0',
  },
  senderUserIcon: {
    padding: '8px 0 0 15px',
  },
  possitionChip: {
    marginRight: 5,
    float: 'left',
  },
  addButton: {
    margin: '0px 5px 5px 0px',
    height: 33,
    width: 33,
  },
  errorwarningspace: {
    minHeight: 24,
  },
  '@media (max-width: 1280px)': {
    testsetsUtteranceedit: {
      order: 2,
    },
    switchButtonCol: {
      order: 1,
    },
    testCaseName: {
      order: 2,
    },
    sourceConvoEditor: {
      order: 1,
    },
    utteranceListName: {
      order: 2,
    },
    sourceEditorSwitch: {
      order: 1,
    }
  },
})

export default convoStyle
