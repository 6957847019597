import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import List from '@material-ui/core/List'
import Button from 'components/Button/Button'
import RefreshIcon from '@material-ui/icons/Refresh'
import PerfectScrollbar from 'perfect-scrollbar'
// core components
import ListItem from 'components/List/ListItem/ListItem'
import ListItemIcon from 'components/List/ListItem/ListItemIcon'
import ListItemText from 'components/List/ListItem/ListItemText'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import listStyle from 'assets/jss/material-dashboard-react/components/listStyle'
import { clearListSettings, setListSettings } from 'actions/list'
import {CustomCheckbox, CustomTextField} from 'components/Form/Form'
import DropdownButton from 'components/Button/DropdownButton'
import LoadingIndicator from 'components/Icon/LoadingIndicator'
import Chip from 'components/Chip/Chip'
import ShowIcon from 'components/Icon/ShowIcon'
import Text from 'components/Typography/Text'
import LinkButton from 'components/Button/LinkButton'
import _ from 'lodash'
import Tooltip from 'components/Tooltip/Tooltip'

const defaultListSettings = {
  page: 0
}

class SimpleListToolbar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      filterText: ''
    }
  }
  
  handleFilterChange(filterText) {
    const { onFilterChange } = this.props
    console.log('filterText', filterText)
    if (onFilterChange) {
      onFilterChange(filterText)
    } 
  }

  componentWillUnmount() {
    const { onFilterChange } = this.props
    if (onFilterChange) {
      onFilterChange('')
    }
  }

  render() {
    const { filterText, onRefresh, onFilterChange, classes } = this.props

    return (
      <GridContainer fullWidth  classes={{
        grid: classes.filterBorder
      }}>
        <GridItem xs={10} lg={10} borderBottom>
          {onFilterChange && (
            <CustomTextField
              data-unique="txtSimpleListFilter"
              fullWidth
              inputClasses={{
                formControl: classes.inputFilter
              }}
              input={{
                name: 'txtFilterText',
                placeholder: 'Filter',
                value: filterText || '',
                onChange: ev => {
                  this.handleFilterChange(ev.target.value)
                }
              }}
            />
          )}
        </GridItem>
        <GridItem xs={2} lg={2} borderBottom middle className={classes.refreshbutton}>
          {onRefresh && (
            <Tooltip title="Refresh" placement="bottom">
              <Button data-unique="btnSimpleListRefresh" justIcon aria-label="Refresh" onClick={onRefresh}>
                <RefreshIcon />
              </Button>
            </Tooltip>
          )}
        </GridItem>
      </GridContainer>
    )
  }
}

SimpleListToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  filterText: PropTypes.string,
  onRefresh: PropTypes.func,
  onFilterChange: PropTypes.func,
}

const SimpleListToolbarWrapped = withStyles(listStyle)(
  SimpleListToolbar,
)

class SimpleList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      pageData: [],
      totalCount: 0,
      hasMore: false,
      excludedTypes: [],
      selectedItemIds: [],
      allItemIds: []
    }
    this.simpleListRef = React.createRef()
  }

  componentDidMount() {
    this.getPageData(this.props)
    new PerfectScrollbar(this.simpleListRef.current)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.getPageData(nextProps)
  }

  handleChangePage = (event, page) => {
    const { name, settings, setListSettings } = this.props
    this.setState({ selectedItemIds: [] })
    setListSettings(name || window.location.pathname, {
      ...settings,
      page
    })
    this.getPageData(this.props, page)
  }

  handleFilterChange = filterText => {
    const { name, settings, setListSettings, onSelectionChange } = this.props
    this.setState({ selectedItemIds: [] })
    onSelectionChange && onSelectionChange([])
    setListSettings(name || window.location.pathname, {
      ...settings,
      filterText,
    })
    this.getPageData(this.props)
  }

  handleCardFilterChange = excludedTypes => {
    const { name, settings, setListSettings, onSelectionChange } = this.props
    this.setState({ selectedItemIds: [] })
    onSelectionChange && onSelectionChange([])
    setListSettings(name || window.location.pathname, {
      ...settings,
      excludedTypes,
    })
    this.getPageData(this.props)
  }

  handleSelection = (id) => {
    const { selectedItemIds } = this.state
    const { onSelectionChange, listData, selectIdField } = this.props
    const identifier = selectIdField || 'id'
    const filteredSelectedItemIds = selectedItemIds.filter(s => (listData || []).some(d => d[identifier] === s))

    if (filteredSelectedItemIds.includes(id)) {
      filteredSelectedItemIds.splice(selectedItemIds.indexOf(id), 1)
    } else {
      filteredSelectedItemIds.push(id)
    }
    this.setState({ selectedItemIds: filteredSelectedItemIds })
    onSelectionChange && onSelectionChange(filteredSelectedItemIds)
  }

  getPageData(props, page) {
    const { settings, listData, filterData, mapSelectIds } = props

    let filteredData = listData || []
    if (settings.filterText) {
      const isListItemAccepted = (li) => {
        return (li.name && li.name.toLowerCase().indexOf(settings.filterText.toLowerCase()) >= 0) || (li.secondary && li.secondary.toLowerCase().indexOf(settings.filterText.toLowerCase()) >= 0)
      }
      filteredData = (listData || []).filter(li => isListItemAccepted(li))
    }
    if (filterData && filterData.length > 0) {
      filteredData = filteredData.filter(item => !this.state.excludedTypes.includes(item.type))
    }

    if (mapSelectIds) {
      this.setState({
        allItemIds: mapSelectIds(filteredData)
      })
    }
    this.setState({
      pageData: filteredData,
      hasMore: false,
      totalCount: filteredData.length,
    })
  }

  renderCardFilter() {

    const toggleExcludedTypes = type => {
      let excludedTypes = this.state.excludedTypes
      if (excludedTypes.includes(type)) {
        _.remove(excludedTypes, t => t === type)
      } else {
        excludedTypes.push(type)
      }
      return excludedTypes
    }

    return <React.Fragment>{this.props.filterData && this.props.filterData.length > 0 &&
      <GridItem xs={12} largePadding borderBottom>
        {this.props.filterData.map(f =>
          <LinkButton key={`simpleListFilter_${f.type}`}
            onClick={() => {
              this.setState({ excludedTypes: toggleExcludedTypes(f.type) })
              this.handleCardFilterChange(this.state.excludedTypes)
            }}>
            <Chip
              tooltip={`${this.state.excludedTypes.includes(f.type) ? 'Show ' : 'Hide '} ${f.tooltip}`}
              style={{ cursor: 'pointer' }}
              variant="info"
              avatarImage={f.icon}
              label={this.state.excludedTypes.includes(f.type) ? <ShowIcon icon="eye" style={{ opacity: 0 }} /> : <ShowIcon icon="eye" />} />
          </LinkButton>)}
      </GridItem>
    }</React.Fragment>
  }

  render() {
    const { pageData } = this.state
    const {
      settings,
      onRefresh,
      pageLoading,
      disableHeader,
      classes,
      onSelectionChange,
      mapSelectIds,
      dropDownFilterComponent
    } = this.props

    const contentContainer = document.querySelector('[data-simplelist-container]')

    let height = window.screen.height - window.screen.height * 0.3

    if(contentContainer) {
      height = Math.max(height, contentContainer.offsetHeight)
    }

    const dataUnique = this.props.dataUnique || this.props.name
    return (
      <GridContainer classes={{
        grid: classes.listBorder
      }}>

        {!disableHeader && (
          <GridItem md={12} lg={12} noPadding className={classes.gridpadding}>
            <SimpleListToolbarWrapped
              onRefresh={onRefresh || undefined}
              filterText={settings.filterText}
              onFilterChange={filterText => this.handleFilterChange(filterText)}
            />
          </GridItem>
        )}
       {!disableHeader && mapSelectIds && (<GridItem xs={6} lg={dropDownFilterComponent ? 6 : 12} borderRight borderBottom middle largePadding>
          <CustomCheckbox
            className={classes.selectAllCheckboxPosition}
            useCheckbox
            dense
            label="Select All"
            data-unique={`chk${dataUnique}_SelectAll`}
            input={{
              onChange: () => {
                if (this.state.selectedItemIds.length === this.state.allItemIds.length) {
                  this.setState({
                    selectedItemIds: []
                  })
                } else {
                  this.setState({
                    selectedItemIds: this.state.allItemIds.map(a => a)
                  })
                }
                onSelectionChange && setTimeout(() => onSelectionChange(this.state.selectedItemIds), 0)
              },
              checked: this.state.selectedItemIds.length === this.state.allItemIds.length
            }}
          />
        </GridItem>)}
        {!disableHeader && dropDownFilterComponent && (<GridItem xs={6} lg={mapSelectIds ? 6 : 12} right borderBottom noPadding>
            {dropDownFilterComponent}
        </GridItem>)}
        {this.renderCardFilter()}
        <GridItem lg={12} className={classes.gridpadding}>
          <div className={classes.listWrapper} ref={this.simpleListRef} style={{ height: height + 'px' }} data-unique={dataUnique} data-length={(!pageLoading && pageData.length) || 0} data-loading={pageLoading ? 'loading' : ''}>
            {!pageLoading && (
              <List dense className={classes.listpadding}>
                {pageData.map((t, index) => {
                  if (t.onClick) {
                    return (<ListItem key={t.id}
                      disableRipple
                      button
                      selected={t.selected}
                      onClick={event => {
                        if(t.onClick && !t.dotsClicked) {
                          t.onClick(event)
                        }
                      }
                      }
                      data-unique={t.dataUnique || `li_${index}`}
                    >
                      {mapSelectIds && <CustomCheckbox
                        className={classes.selectCheckboxPosition}
                        useCheckbox
                        dense
                        data-unique={`chk${dataUnique}_Select_${index}`}
                        input={{
                          onChange: () => this.handleSelection(this.state.allItemIds[index]),
                          checked: this.state.selectedItemIds.filter(id => this.state.allItemIds[index] === id).length > 0
                        }}
                      />}
                      {t.icon && <ListItemIcon className={classes.SimpleListIcon}><Text>{t.icon}</Text></ListItemIcon>}
                      <Tooltip title={t.name}><ListItemText 
                        primary={<Text bold className={classes.SimpleListPrimaryText}>{t.name}</Text>} secondary={t.secondary || null}/>
                      </Tooltip>
                      {t.actions && <DropdownButton
                          aria-label="Actions"
                          tooltipTitle="Actions"
                          data-unique={`${t.dataUnique || `li_${index}`}_btnActions`} dots items={t.actions} onClick={() => {
                          t.dotsClicked = true
                          }} onClose={() => {
                        setTimeout(() => t.dotsClicked = false, 100)
                      }}/>}
                    </ListItem>)
                  } else {
                    return (<ListItem key={t.id} data-unique={t.dataUnique}>
                      {t.icon && <ListItemIcon className={classes.SimpleListIcon}><Text>{t.icon}</Text></ListItemIcon>}
                      <Tooltip title={t.name}><ListItemText 
                        primary={<Text bold className={classes.SimpleListPrimaryText}>{t.name}</Text>} secondary={t.secondary || null}/></Tooltip>
                      {t.actions && <Tooltip title="Action" placement="bottom"><DropdownButton dots items={t.actions}/></Tooltip>}
                    </ListItem>)
                  }
                })}
              </List>
            )}
            {pageLoading &&
              <List dense className={classes.listpadding}>
                <ListItem>
                  <ListItemIcon><LoadingIndicator/></ListItemIcon>
                </ListItem>
              </List>
            }
          </div>
        </GridItem>
      </GridContainer>
    )
  }
}

SimpleList.defaultProps = {
  tableHeaderColor: 'gray',
  rowsPerPage: 15
}

SimpleList.propTypes = {
  classes: PropTypes.object.isRequired,
  name: PropTypes.string,
  listData: PropTypes.arrayOf(PropTypes.any),
  onRefresh: PropTypes.func,
  pageLoading: PropTypes.bool,
  disableHeader: PropTypes.bool,
  rowsPerPage: PropTypes.number,
  mapSelectIds: PropTypes.func,
  onSelectionChange: PropTypes.func,
  selectIdField: PropTypes.string
}

export default connect(
  (state, ownProps) => ({
    settings: Object.assign(
      {},
      defaultListSettings,
      state.list[ownProps.name || window.location.pathname],
    ),
  }),
  { clearListSettings, setListSettings },
)(withStyles(listStyle)(SimpleList))
