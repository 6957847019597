import React, { useEffect, useRef } from 'react'
import _ from 'lodash'
import * as moment from 'moment'
import * as esprima from 'esprima'
import * as jp from 'jsonpath'
import prettyBytes from 'pretty-bytes'
import { isValidCron } from 'cron-validator'
import Field from 'components/Form/OptionalField'
import createDecorator from 'final-form-calculate'
import Dropzone from 'react-dropzone'
import { Query } from 'react-apollo'
import { AutoComplete } from 'rsuite'
import { withStyles } from '@material-ui/core/styles'
import FormControlLabelCore from '@material-ui/core/FormControlLabel'
import Toolbar from '@material-ui/core/Toolbar'
import TextFieldCore from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import Switch from '@material-ui/core/Switch'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Selector from 'components/Form/Selector'
import FormHelperText from '@material-ui/core/FormHelperText'
import Tooltip from 'components/Tooltip/Tooltip'
import Chip from 'components/Chip/Chip'
import DownshiftMultiple from 'components/Form/DownshiftMultiple'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import customInputStyle from 'assets/jss/material-dashboard-react/components/customInputStyle'
import tableStyle from 'assets/jss/material-dashboard-react/components/tableStyle'
import dropzoneStyle from 'assets/jss/material-dashboard-react/components/dropzoneStyle'
import selectNamespaceStyle from 'assets/jss/material-dashboard-react/components/selectNamespaceStyle'
import freeEmailDomains from 'free-email-domains'
import {Controlled as CodeMirror} from 'react-codemirror2'
import NodeRSA from 'node-rsa'
import { TAGS_QUERY, NAMESPACES_QUERY } from 'views/Settings/gql'
import ShowIcon from 'components/Icon/ShowIcon'
import classNames from 'classnames'
import Text from 'components/Typography/Text'
import 'assets/css/codemirror-darkmode.css'
import { isDarkmode } from 'components/Darkmode/helper'
import LoadingIndicator from 'components/Icon/LoadingIndicator'

const inputStyle = customInputStyle.inputRoot

export const DangerSpan = withStyles(customInputStyle)(({ ...props }) => {
  const { classes, children } = props
  return (
    <span className={classes.labelRootError}>
      {children}
    </span>
  )
})

export const FormControlLabel = withStyles(customInputStyle)(({ ...props }) => {
  const { classes, children, ...rest } = props
  return (
    <FormControlLabelCore classes={{
      label: classes.formControlLabelLabelSmall + ' ' + classes.labelShrinkWidth
    }} {...rest}>
      {children}
    </FormControlLabelCore>
  )
})

export const TextField = withStyles(customInputStyle)(({ classes, InputLabelProps: { ...inputLabelProps }, ...props }) => {
  return <TextFieldCore InputLabelProps={{
    classes: {
      shrink: classes.labelShrinkWidth
    },
    ...inputLabelProps
  }} {...props} />
})

export const CustomTagsField = withStyles(customInputStyle)(class TextFieldComponent extends React.Component {
  render() {
    return renderTagField(this.props)
  }
})

export const renderTagField = ({
  readonly,
  ...rest
}) => {
  return (
    <Query query={TAGS_QUERY} >
      {({ data }) => <CustomAutoSuggest
        {...rest}
        suggestions={(data && data.tags && data.tags.map(t => t.name)) || []}
        addFunc={readonly ? null : (d => d)}
      />}
    </Query>
  )
}

// not so good, we can have problem with it if the user uses the same name
export const ALL_NAMESPACES_KEY = '+!!!-<all>-!!!+'

export const SelectNamespace = withStyles(selectNamespaceStyle)(class SelectComponent extends React.Component {
  render() {
    return <Query query={NAMESPACES_QUERY}>
      {({ data }) => {
        data = (data && data.namespaces && data.namespaces.filter(n => n.canRead || n.canWrite)) || []
        return renderSelect({
          ...this.props,
          disabled: !data || !data.length || (this.props && this.props.disabled),
          form: false,
          filterable: false,
          multiple: false,
          items: [
            {
              key: ALL_NAMESPACES_KEY,
              label: (data && data.length) ? 'All namespaces' : 'No namespaces'
            },
            ...data.map(t => ({key: t.namespace , label: t.namespace}))
          ]
        })
      }}
    </Query>
  }
})

export const CustomNamespaceField = withStyles(customInputStyle)(class TextFieldComponent extends React.Component {
  render() {
    return renderTagField(this.props)
  }
})

export const renderNamespaceField = ({
  readonly,
  forRead,
  forWrite,
  ...rest
}) => {
  return (
    <Query query={NAMESPACES_QUERY}>
      {({ data }) => {
        return renderAutoSuggestSingle({
          ...rest,
          suggestions: (data && data.namespaces && data.namespaces.filter(n => (!forRead || n.canRead) && (!forWrite || n.canWrite)).map(t => t.namespace)) || []
        })
      }}
    </Query>
  )
}



export const formatDate = value => {
  if (value) {
    const parsed = moment(value)
    if (parsed.isValid()) return parsed.format('YYYY-MM-DD')
  }
  return ''
}
export const parseDate = value => {
  return value
}

export const parseInteger = value => {
  if (value) {
    const result = parseInt(value, 10)
    if (isNaN(result)) return null
    else return result
  }
  return null
}

export const parseDecimal = value => {
  if (value) {
    const result = parseFloat(value)
    if (isNaN(result)) return null
    else return result
  }
  return null
}

export const FormActionsToolbar = withStyles({

})(class FormActionsToolbar extends React.Component {
  render() {

    const { leftButtons, leftButtonsXs = 6, rightButtonsXs = 6, rightButtons, classes } = this.props

    return <GridContainer classes={{
      grid: classes.root
    }}>
      <GridItem md={12} lg={leftButtonsXs} noPadding>
        {leftButtons}
      </GridItem>
      <GridItem md={12} lg={rightButtonsXs} right noPadding>
        {rightButtons}
      </GridItem>
    </GridContainer>
  }
})

export const TableActionsToolbar = withStyles(tableStyle)(class TableActionsToolbar extends React.Component {
  render() {
    const { customActions, customActionsRight, children, classes, noPaddingLeft, addNoPaddingTableActionsToolbar} = this.props
    return <Toolbar className={classes.tableToolbar} classes={{ gutters: classNames({
      [classes.guttersNoPadding]: noPaddingLeft
      }) }}>
      <GridContainer fullWidth flexWrapinherit noPadding noMargin>
        {customActions  &&
        <GridItem md={12} lg={6}  className={classes.tableToolbarCustomActions} grid>{customActions}</GridItem>}
        {addNoPaddingTableActionsToolbar &&
        <GridItem md={12} lg={customActions ? 6 : 12} noPadding right className={classes.tableToolbarActions}>
          {customActionsRight}
          {(_.isArray(children) ? children : [children]).map((child, index) => <div key={index} className={classes.tableToolbarActionsItem}>{child}</div>)}
        </GridItem>}
        {(addNoPaddingTableActionsToolbar === undefined || addNoPaddingTableActionsToolbar === false) &&
        <GridItem md={12} lg={customActions ? 6 : 12}  right className={classes.tableToolbarActions}>
          {customActionsRight}
          {(_.isArray(children) ? children : [children]).map((child, index) => <div key={index} className={classes.tableToolbarActionsItem}>{child}</div>)}
        </GridItem>}
      </GridContainer>
    </Toolbar>
  }
})

export const renderPasswordField = ({
  input: { name, onChange, value, ...restInput },
  classes,
  label,
  meta,
  helperText,
  autoComplete,
  hasRequired,
  ...rest
}) => {

  return (
    <TextField
      {...rest}
      name={name}
      helperText={showErrorOrRequiredHint(meta) ? <DangerSpan>{meta.error || meta.submitError}</DangerSpan> : helperText}
      error={!!showError(meta)}

      inputProps={{
        'data-unique': `${rest['data-unique']}Input`,
        style: {
          height: 'auto',
        },
        classes: {
          root: classes.inputRoot
        },
        autoComplete: autoComplete ? autoComplete : 'new-password',
        ...restInput,
      }}
      InputProps={{
        classes: {
          input: classes.input+ ' ' + classes.inputPassword,
          formControl: classes.formControl,
          disabled: classes.disabled
        },
      }}
      onChange={onChange}
      value={value}
      label={label}
      type="password"
      margin="normal"
      InputLabelProps={{
        shrink: true,
        FormLabelClasses: {
          disabled: classes.inputLabeldisabled
        },
        classes: {
          root: classes.inputLabelRoot,
          shrink: classes.inputLabelShrink,
          formControl: classes.inputLabelformControl
        }
      }}
      FormHelperTextProps={{
        classes: {
          root: classes.inputHelperTextRoot
        }
      }}
      fullWidth
    />
  )
}
export const CustomPasswordField = withStyles(customInputStyle)(class PasswordFieldComponent extends React.Component {
  render() {
    return renderPasswordField(this.props)
  }
})

export const renderSlider = ({
  input: { name , value, ...restInput },
  classes,
  label,
  form,
  meta,
  min,
  max,
  // optional
  minSlider,
  // optional
  maxSlider,
  disabled,
  step,
  helperText,
  autoComplete,
  formControlProps,
  showInputField,
  validate,
  onChange,
  inputWidth,
  hasRequired,
  ...rest
}) => {


  const backgroundStyle =
    isDarkmode() ?
    'linear-gradient(to right, #FF9800 0%, #FF9800 ' + ((value-(minSlider || min))/((maxSlider || max)-(minSlider || min))*100) + '%, #D5D9DD ' + ((value-(minSlider || min))/((maxSlider || max)-(minSlider || min))*100) + '%, #D5D9DD 100%)' :
    'linear-gradient(to right, #FF9800 0%, #FF9800 ' + ((value-(minSlider || min))/((maxSlider || max)-(minSlider || min))*100) + '%, #2B3E531A ' + ((value-(minSlider || min))/((maxSlider || max)-(minSlider || min))*100) + '%, #2B3E531A 100%)'

  return (
    <FormControl
      {...formControlProps}
      error={!!showError(meta)}
      classes={{
        root: classes.sliderFormControl
      }}
      fullWidth
      margin="normal"
      data-unique={`${rest['data-unique']}`}
    >
      {label && (
        <InputLabel htmlFor={name} shrink={true} className={classes.sliderLabel}>
          {label}
        </InputLabel>
      )}
      <GridContainer>
        <GridItem xs={12}>
          <div style={{ width: 65, position: 'absolute', right: 0, top: '-25px' }} >
            {showInputField && <CustomIntField
              input={{min: min,
                max: max,
                name: name,
                value: value,
                onBlur: () => {
                  if (value < min) {
                    if (form) form.change(name, min)
                    if (onChange) onChange(min)
                  } else if (value > max) {
                    if (form) form.change(name, max)
                    if (onChange) onChange(max)
                  }
                },
                onChange: (e) => {
                  let value = parseInteger(e.target.value)
                  if (_.isNil(value) || value < min) {
                    value = min
                  } else if (value > max) {
                    value = max
                  }
                  if (value >= min && value <= max) {
                    if (form) form.change(name, value)
                    if (onChange) onChange(value)
                  }
                }
              }}
              data-unique={`${rest['data-unique']}Input`}
              {...restInput} />}
            <div style={{marginTop: 20, textAlign: 'right' }}>{!showInputField && value}</div>
          </div>

          <input
            onChange={(e) => {
              if (form) form.change(name, parseInteger(e.target.value))
              if (onChange) onChange(parseInteger(e.target.value))
            }}
            value={value}
            type="range"
            min={minSlider || min}
            className={classes.sliderInput}
            max={maxSlider || max}
            step={step}
            style={{
              width: `calc(100% - ${inputWidth + 5}px)`,
              background: backgroundStyle
            }}
            disabled={disabled}
          />
          {showError(meta) && <FormHelperText classes={{ root: classes.inputHelperTextRoot }}><DangerSpan>{meta.error || meta.submitError}</DangerSpan></FormHelperText>}
          {helperText && <FormHelperText classes={{ root: classes.inputHelperTextRoot }}>{helperText}</FormHelperText>}
        </GridItem>
      </GridContainer>
    </FormControl>
  )
}
export const CustomSlider = withStyles(customInputStyle)(class SliderComponent extends React.Component {
  render() {
    return renderSlider(this.props)
  }
})

export const renderTextField = ({
  input: { name, onChange, value, ...restInput },
  classes,
  label,
  meta,
  helperText,
  startAdornment,
  endAdornment,
  maxLength,
  readOnly,
  hasRequired,
  disableBorder,
  slimError,
  inputClasses,
  flexibleWidth,
  inlineEdit,
  ...rest
}) => {
  const inputSize = flexibleWidth && value ? Math.min(value.length, 100) : ''

  return (
    <TextField
      {...rest}
      name={name}
      id={name}
      helperText={showErrorOrRequiredHint(meta) ? <DangerSpan>{meta.error || meta.submitError}</DangerSpan> : helperText}
      error={!!showError(meta)}
      onMouseOver={() => {

      }}
      inputProps={{
        'data-unique': `${rest['data-unique']}Input`,
        size: inputSize,
        ...(maxLength ? { maxLength } : {}),
        ...restInput
      }}

      InputProps={{
        ...(startAdornment ? { startAdornment: <InputAdornment position="start">{startAdornment}</InputAdornment> } : {}),
        ...(endAdornment ? { endAdornment: <InputAdornment position="end">{endAdornment}</InputAdornment> } :
          inlineEdit ? (slimError && meta.error) ? { startAdornment: <Tooltip title={meta.error}><Text danger><ShowIcon icon="pen" /></Text></Tooltip> } : { startAdornment: <ShowIcon icon="pen" /> } : {}
        ),
        readOnly: readOnly || false,
        disableUnderline: true,
        classes: {
          disabled: disableBorder ? '' : classes.disabled,
          input: classNames({
            [classes.input]: true,
            [classes.inputNoBorder]: disableBorder,
            [classes.inputError]: !!showError(meta) && slimError,
            [classes.flexibleWidth]: flexibleWidth,
            
          }),
          formControl: classNames({
            [classes.formControl]: true,
            [classes.formControlNoBorder]: disableBorder,
            [classes.inputAdornedEnd]: inlineEdit,
            [classes.inputAdornedEndDanger]: inlineEdit && slimError && meta.error,
          }),
          ...inputClasses
        },
      }}

      onChange={onChange}
      value={value}
      label={label}
      margin="normal"
      InputLabelProps={{
        shrink: true,
        FormLabelClasses: {
          disabled: classes.inputLabeldisabled
        },
        classes: {
          root: disableBorder ? classes.inputLabelRootNoBorder : classes.inputLabelRoot,
          shrink: classes.inputLabelShrink,
          formControl: classes.inputLabelformControl,
        }
      }}
      FormHelperTextProps={{
        classes: {
          root: classNames({
            [classes.inputHelperTextRoot]: true,
          })
        }
      }}
      fullWidth={!flexibleWidth}
    />
  )
}
export const CustomTextField = withStyles(customInputStyle)(class TextFieldComponent extends React.Component {
  render() {
    return renderTextField(this.props)
  }
})

export const renderIntField = ({
  input: { name, onChange, value, ...restInput },
  classes,
  label,
  meta,
  helperText,
  hasRequired,
  ...rest
}) => {

  return (
    <TextField
      {...rest}
      name={name}
      id={name}
      helperText={showErrorOrRequiredHint(meta) ? <DangerSpan>{meta.error || meta.submitError}</DangerSpan> : helperText}
      error={!!showError(meta)}
      inputProps={{
        'data-unique': `${rest['data-unique']}Input`,
        style: {
          height: 'auto',
          position: 'relative',
          left: 3
        },
        ...restInput,
      }}
      onChange={onChange}
      value={value}
      label={label}
      InputProps={{
        classes: {
          input: classes.input,
          formControl: classes.formControl,
          disabled: classes.disabled,
        },
      }}
      InputLabelProps={{
        shrink: true,
        FormLabelClasses: {
          disabled: classes.inputLabeldisabled
        },
        classes: {
          root: classes.inputLabelRoot,
          shrink: classes.inputLabelShrink,
          formControl: classes.inputLabelformControl,
        }
      }}
      FormHelperTextProps={{
        classes: {
          root: classes.inputHelperTextRoot,
          disabled: classes.inputHelperDisabled
        }
      }}
      type="number"
      margin="normal"
      fullWidth
    />
  )
}
export const CustomIntField = withStyles(customInputStyle)(class IntFieldComponent extends React.Component {
  render() {
    return renderIntField(this.props)
  }
})

export const renderTextArea = ({
  input: { name, onChange, value, ...restInput },
  input,
  classes,
  label,
  meta,
  helperText,
  hasRequired,
  slimError,
  disableBorder,
  flexibleWidth,
  inlineEdit,
  startAdornment,
  endAdornment,
  rows,
  ...rest
}) => {
  const inputSize = flexibleWidth && value ? Math.min(value.length, 110) : ''

  let el = document.querySelector(`[data-unique="${rest['data-unique']}Input"]`)

  return (
    <TextField
      {...rest}
      rows={rows || 5}
      name={name}
      id={name}
      helperText={showErrorOrRequiredHint(meta) ? <DangerSpan>{meta.error || meta.submitError}</DangerSpan> : helperText}
      error={!!showError(meta)}
      inputProps={{cols: (flexibleWidth ? inputSize : null) ,'data-unique': `${rest['data-unique']}Input`, ...restInput, style: { ...inputStyle, resize: flexibleWidth ? 'none' : 'vertical', height: (flexibleWidth ? (el ? `${el.scrollHeight}px` : 10) : 'auto') }}}
      onChange={e => {
        el = document.querySelector(`[data-unique="${rest['data-unique']}Input"]`)
        onChange(e)
      }}
      value={value}
      label={label}
      margin="normal"
      InputLabelProps={{
        shrink: true,
        classes: {
          root: disableBorder ? classes.inputLabelRootNoBorder : classes.inputLabelRoot,
          shrink: classes.inputLabelShrink,
          formControl: classes.inputLabelformControl,
        }
      }}
      InputProps={{
        ...(startAdornment ? { startAdornment: <InputAdornment position="start">{startAdornment}</InputAdornment> } : {}),
        ...(endAdornment ? { endAdornment: <InputAdornment position="end">{endAdornment}</InputAdornment> } :
          inlineEdit ? (slimError && meta.error) ? { startAdornment: <Tooltip title={meta.error}><Text danger><ShowIcon icon="pen" /></Text></Tooltip> } : { startAdornment: <ShowIcon icon="pen" /> } : {}
        ),
        classes: {
          input: classNames({
            [classes.inputTextArea]: true,
            [classes.inputTextAreaNoBorder]: disableBorder,
            [classes.inputError]: !!showError(meta) && slimError,
            [classes.flexibleWidth]: flexibleWidth,
          }),
          formControl: classNames({
            [classes.formControl]: true,
            [classes.formControlTextAreaNoBorder]: disableBorder,
            [classes.formControlNoError]: slimError,
            [classes.inputAdornedEnd]: inlineEdit,
            [classes.inputAdornedEndDanger]: inlineEdit && slimError && meta.error
          }),
          underline: classes.underline

        },
      }}
      FormHelperTextProps={{
        classes: {
          root: classes.inputHelperTextRoot
        },
        root: classNames({
          [classes.inputHelperTextRoot]: true,
          [classes.inputHelperTextRootHide]: slimError
        })
      }}
      multiline
      fullWidth={!flexibleWidth}
    />
  )
}
export const CustomTextArea = withStyles(customInputStyle)(class TextAreaComponent extends React.Component {
  render() {
    return renderTextArea(this.props)
  }
})

export const renderCodeArea = ({
  input: { name, onChange, value, ...restInput },
  classes,
  options,
  label,
  codeFormat,
  meta,
  helperText,
  formControlProps,
  disabled,
  hasRequired,
  ...rest
}) => {

  return (
    <FormControl
      {...formControlProps}
      error={!!showError(meta)}
      fullWidth
      classes={{
        root: disabled ? classes.disabledCodeMirror : '',
      }}
      margin="normal"
      data-unique={`${rest['data-unique']}`}
    >
      {label && (
        <InputLabel htmlFor={name} shrink={true} classes={{ root: classes.inputLabelRoot, shrink: classes.inputLabelShrink, formControl: classes.inputLabelformControl }}>
          {label}
        </InputLabel>
      )}
      {!codeFormat &&
      (<div {...(label ? { style: customInputStyle.marginTop } : {})}>
        <CodeMirror
          {...rest}
          name={name}
          onBeforeChange={(editor, data, value) => {
            onChange(value)
          }}
          value={value}
          options={Object.assign({
            lineNumbers: true,
            cursorHeight: 0.8,
            readOnly: disabled ? 'nocursor' : false,
            theme: isDarkmode() ? 'botium-darkmode' : 'default'
          }, options)}
        />
      </div>)}
      {codeFormat &&
      (<div {...(label ? { style: customInputStyle.marginTop } : {})}>
        <CodeMirror
          {...rest}
          name={name}
          onBeforeChange={(editor, data, value) => {
            onChange(value)
          }}
          onBlur={(editor, data) => {
            onChange(codeFormat(editor.doc.getValue()))
          }}
          value={value}
          options={Object.assign({
            lineNumbers: true,
            cursorHeight: 0.8,
            readOnly: disabled ? 'nocursor' : false,
            theme: isDarkmode() ? 'botium-darkmode' : 'default'
          }, options)}
        />
      </div>)}
      {showError(meta) && <FormHelperText classes={{ root: classes.inputHelperTextRoot }}><DangerSpan>{meta.error || meta.submitError}</DangerSpan></FormHelperText>}
      {helperText && <FormHelperText classes={{ root: classes.inputHelperTextRoot }}>{helperText}</FormHelperText>}
    </FormControl>
  )
}

export const CustomCodeArea = withStyles(customInputStyle)(class CodeAreaComponent extends React.Component {
  render() {
    return renderCodeArea(this.props)
  }
})

export const renderCheckbox = ({
  input: { checked, name, onChange, ...restInput },
  classes,
  label,
  meta,
  helperText,
  formControlProps,
  hasRequired,
  useCheckbox,
  switchClasses,
  dense,
  ...rest
}) => {

  const _rc = () => useCheckbox ?
    <Checkbox
      {...rest}
      name={name}
      inputProps={{'data-unique': `${rest['data-unique']}Input`, ...restInput}}
      onChange={onChange}
      checked={!!checked}
      classes={{
        root: dense ? classes.checkboxBasedense : classes.checkboxBase,
        checked: classes.checkboxBasecolorchecked,
        ...(_.omit(switchClasses, 'label'))
      }}
    />
    :
    <Switch
      {...rest}
      name={name}
      inputProps={{'data-unique': `${rest['data-unique']}Input`, ...restInput}}
      onChange={onChange}
      checked={!!checked}
      classes={{
        bar: checked ? classes.switchBasecolorbar : classes.switchBasecolorunchecked,
        checked: classes.switchBasecolorchecked,
        ...(_.omit(switchClasses, 'label'))
      }}
    />

  return (
    <FormControl
      error={!!showError(meta)}
      margin={dense ? 'none' : 'normal'}
      {...formControlProps}
    >
      {label && <FormControlLabel className={(switchClasses && switchClasses.label) || (!dense ? classes.switchBasecolorlabel : classes.switchBasecolornolabel)}
        control={_rc()}
        label={label}
        margin="normal"
      />}
      {!label && _rc()}
      {showError(meta) && <FormHelperText classes={{ root: classes.inputHelperTextRoot }}><DangerSpan>{meta.error || meta.submitError}</DangerSpan></FormHelperText>}
      {helperText && <FormHelperText classes={{ root: classes.inputHelperTextRoot }}>{helperText}</FormHelperText>}
    </FormControl>
  )
}
renderCheckbox.displayName = 'renderCheckbox'

export const CustomCheckbox = withStyles(customInputStyle)(class CheckboxComponent extends React.Component {
  render() {
    return renderCheckbox(this.props)
  }
})

export const prettyPrintJson = uglyJson => {
  if (!uglyJson) {
    return ''
  }
  try {
    const obj = JSON.parse(uglyJson)
    return JSON.stringify(obj, undefined, 2)
  } catch (err) {
    return uglyJson
  }
}

export const oneLinePrintJson = uglyJson => {
  if (!uglyJson) {
    return ''
  }
  try {
    const obj = JSON.parse(uglyJson)
    return JSON.stringify(obj)
  } catch (err) {
    return uglyJson
  }
}

export const renderSelect = ({hasRequired, ...props}) => {
  return <Selector form={true} {...props} />
}
renderSelect.displayName = 'renderSelect'

export const CustomSelect = withStyles(customInputStyle)(class SelectComponent extends React.Component {
  render() {
    return renderSelect({...this.props, form: false})
  }
})

export const renderAutoSuggest = ({
  input: { name, value, onChange },
  meta,
  helperText,
  label,
  suggestions,
  keyFunc,
  labelFunc,
  addFunc,
  formControlProps,
  disabled,
  unique,
  hasRequired,
  marginTop,
  classes,
  ...rest
}) => {

  if (_.isUndefined(unique)) {
    unique = true
  }

  if (disabled) {
    return <CustomRenderField
      input={{ name }}
      label={label}
      formControlProps={formControlProps}
      meta={meta}
      helperText={helperText}
    >
      {value && value.map((v, index) => (
        <Chip key={index} label={labelFunc ? labelFunc(v) : v} />
      ))}
      {value && value.length === 0 && <Text padding>None set (disabled)</Text>}
    </CustomRenderField>
  }

  return (
      <DownshiftMultiple
        {...rest}
        hasRequired={hasRequired}
        meta={meta}
        helperText={helperText}
        error={!!showError(meta)}
        name={name}
        label={label}
        onChange={onChange}
        value={value && value.length > 0 ? [...(unique ? new Set(value) : value)] : []}
        suggestions={suggestions}
        keyFunc={keyFunc}
        labelFunc={labelFunc}
        addFunc={addFunc}
      />

  )
}

export const CustomAutoSuggest = withStyles(customInputStyle)(class AutoSuggestComponent extends React.Component {
  render() {
    return renderAutoSuggest(this.props)
  }
})

/**
 * it is possible to choose just one entry, not as in renderAutoSuggest
 * @param name
 * @param value
 * @param onChange
 * @param meta
 * @param helperText
 * @param label
 * @param suggestions
 * @param formControlProps
 * @param rest
 * @returns {JSX.Element|XML}
 */
export const renderAutoSuggestSingle = ({
  input: { name, value, onChange },
  meta,
  helperText,
  label,
  suggestions,
  formControlProps,
  classes,
  hasRequired,
  ...rest
}) => {
  return (
    <FormControl
      {...formControlProps}
      error={!!showError(meta)}
      fullWidth
      margin="normal"
    >
      {label && (
        <InputLabel htmlFor={name} shrink={true} classes={{ root: classes.inputLabelRoot, shrink: classes.inputLabelShrink, formControl: classes.inputLabelformControl }}>
          {label}
        </InputLabel>
      )}
      <AutoComplete
        className={classes.autocompleteInput}
        {...rest}
        id={name}
        onChange={onChange}
        placeholder=""
        value={value}
        data={suggestions}
        autoComplete="off"
      />
      {showError(meta) && <FormHelperText classes={{ root: classes.inputHelperTextRoot }}><DangerSpan>{meta.error || meta.submitError}</DangerSpan></FormHelperText>}
      {helperText && <FormHelperText classes={{ root: classes.inputHelperTextRoot }}>{helperText}</FormHelperText>}
    </FormControl>
  )
}

export const CustomRenderField = withStyles(customInputStyle)(class CustomComponent extends React.Component {
  render() {
    return renderCustom(this.props)
  }
})

export const renderCustom = ({
  input,
  children,
  classes,
  meta,
  helperText,
  label,
  formControlProps,
}) => {

  return (
    <FormControl
      {...formControlProps}
      error={!!showError(meta)}
      fullWidth
      margin="normal"
    >
      {label && (
        <InputLabel htmlFor={input && input.name} shrink={true} classes={{ root: classes.inputLabelRoot, shrink: classes.inputLabelShrink, formControl: classes.inputLabelformControl }}>
          {label}
        </InputLabel>
      )}
      <div style={{marginTop: 5}}>
        {children}
      </div>
      {showError(meta) && <FormHelperText classes={{ root: classes.inputHelperTextRoot }}><DangerSpan>{meta.error || meta.submitError}</DangerSpan></FormHelperText>}
      {helperText && <FormHelperText classes={{ root: classes.inputHelperTextRoot }}>{helperText}</FormHelperText>}
    </FormControl>
  )
}

export const renderFileUpload = withStyles(dropzoneStyle)(({
  change,
  classes,
  onFileLoaded,
  multiple,
  values,
  input,
  meta,
  helperText,
  label,
  formControlProps,
  accept,
  fullWidth,
  disabled,
  onDrop,
  processingFiles,
  ...rest
}) => {

  let dropzoneRef = useRef()

  useEffect(() => {
    const r = dropzoneRef
    document.addEventListener('keydown', (e) => {
      if (e.key === 'Enter' && document.activeElement.contains(r.fileInputEl)) {
        r.fileInputEl.click()
      }
    })
  }, [])

  return renderCustom({
    input,
    meta,
    formControlProps,
    classes,
    label,
    helperText,
    children: (
      <Dropzone
        data-unique={`${rest['data-unique']}`}
        name={input.name}
        title={label}
        multiple={multiple}
        accept={accept}
        disabled={disabled}
        tabIndex={0}
        ref={node => { dropzoneRef = node }}
        onDrop={(files, e) => {
          if (onDrop) onDrop(files, e)
          files.forEach((file) => {
            const reader = new FileReader()
            reader.onload = () => {
              const base64String = reader.result.split(',').pop()
              onFileLoaded(file.name, base64String)
            }
            reader.readAsDataURL(file)
            change(input.name, file)
          })
        }}
        className={classNames({
        [classes.root]: true,
        [classes.disabled]: disabled,
        [classes.fullWidth]: fullWidth,
        })}
      >
        {(processingFiles) && <>
          <LoadingIndicator />
          {values[input.name] && (
            <Text muted>
              {values[input.name].name} - {prettyBytes(values[input.name].size)}
            </Text>
          )}
        </>}
        {(!processingFiles) && <>
          <ShowIcon size="lg" icon="cloud-upload-alt" className={classes.cloudicon} />
          {values[input.name] && (
            <Text muted>
              {values[input.name].name} - {prettyBytes(values[input.name].size)}
            </Text>
          )}
          {!disabled && !values[input.name] && !multiple &&
            <Text primary bold>Drop file here or browse</Text>}
          {!disabled && !values[input.name] && multiple &&
            <Text primary bold>Drop file(s) here or browse</Text>}
          {disabled && !values[input.name] && !multiple &&
            <Text muted>Drop file here or browse</Text>}
          {disabled && !values[input.name] && multiple &&
            <Text muted>Drop file(s) here or browse</Text>}
          </>}
      </Dropzone>
    ),
  })
})
renderFileUpload.displayName = 'renderFileUpload'

export const showError = meta => meta && ((meta.error && meta.submitFailed) || (meta.error && meta.error !== 'Required'))
export const showErrorOrRequiredHint = meta => meta && (showError(meta) || (meta.error === 'Required' && meta.visited && !meta.active))
export const Conditional = class  {
  constructor(fieldName) {
    this.fieldName = fieldName
  }

  decorateValidator(decoratedValidator, expectedValue) {
    return (value, allValues) => {
      if (allValues[this.fieldName] === expectedValue) {
        const result = decoratedValidator(value)
        return result
      }
    }
  }
}

export const required = value => {
  if (_.isArray(value)) {
    return value.length > 0 ? undefined : 'Required'
  }
  if (_.isNumber(value)) {
    return `${value}`.length > 0 ? undefined : 'Required'
  }
  if (_.isString(value)) {
    return value.length > 0 ? undefined : 'Required'
  }
  return value ? undefined : 'Required'
}
required.displayName = 'required'

export const conditional = value => {
  return undefined
}
conditional.displayName = 'conditional'

/**
 * Can be used to create conditional validator: Depending on a field an other field must be empty
 * validate={values.retryUserSaysNumRetries ? (() => {}) : empty}
 * @param value
 * @return {*}
 */
export const empty = (value) => {
  if (_.isArray(value)) {
    return value.length > 0 ? 'Must be empty' : undefined
  }
  return value ? 'Must be empty' : undefined
}
export const regex = (value) => {
  if (!value) {
    return
  }

  if (_.isArray(value)) {
    for (const entry of value) {
      try {
        new RegExp(entry)
      } catch(e) {
        return `Not valid regex: ${entry}`
      }
    }
  } else {
    try {
      new RegExp(value)
    } catch(e) {
      return `Not valid regex`
    }
  }
}
export const mustBeNumber = value =>
  !_.isNil(value) && isNaN(value) ? 'Must be a number' : undefined
/**
 * @param min
 * @return {function(*=): *}
 */
// values can be null becuse OptionalField
export const minValue = minRaw => (value, values = {}) => {
  const min = _.isFunction(minRaw) ? minRaw({value, values}) : minRaw

  return _.isNil(value) || isNaN(value) || value >= min
    ? undefined
    : `Should be equal or larger than ${min}`
}
/**
 * @param max
 * @return {function(*=): *}
 */
// values can be null becuse OptionalField
export const maxValue = maxRaw => (value, values = {}) => {
  const max = _.isFunction(maxRaw) ? maxRaw({value, values}) : maxRaw
  return _.isNil(max) || _.isNil(value) || isNaN(value) || value <= max
    ? undefined
    : `Should be equal or smaller than ${max}`
}
export const maxLength = max => value => {
  if (_.isArray(value) || _.isString(value)) {
    return value.length > max ? `Maximum length of ${max} exceeded` : undefined
  }
}
export const minLength = min => value => {
  if (_.isArray(value) || _.isString(value)) {
    return value.length < min ? `Minimum length ${min}` : undefined
  }
}

export const json = value => {
  if (!value) {
    return
  }
  try {
    JSON.parse(value)
  } catch (err) {
    return `No valid JSON: ${err.message}`
  }
}
export const javascript = value => {
  if (!value) {
    return
  }
  try {
    esprima.parse(`(async function() {${value}})()`)
  } catch (err) {
    return `No valid Javascript expression: ${err.message}`
  }
}
export const jsonpath = value => {
  if (!value) {
    return
  }
  try {
    jp.parse(value)
  } catch (err) {
    return `No valid JSON-Path expression: ${err.message}`
  }
}
export const isRsaKey = value => {
  if (!value) {
    return
  }
  try {
    const key = new NodeRSA(value.replace(/\\n/g, '\n'))
    if (key.isPrivate()) return
  } catch (err) {
    return 'Not valid private key'
  }
  return 'Not valid private key'
}
export const url = value => {
  if (!value) {
    return
  }
  if (_.isString(value) && (value.startsWith('http://') || value.startsWith('https://'))) return
  return 'Not a valid URL'
}

export const isGitUrl = value => !gitUrl(value)

export const gitUrl = value => {
  if (!value) {
    return
  }
  if (_.isString(value) && (value.startsWith('git:') || value.startsWith('git@') || value.startsWith('ssh:') || value.startsWith('http://') || value.startsWith('https://'))) return
  return 'Not a valid git URL'
}
export const cron = value => {
  if (!value) {
    return
  }
  if (isValidCron(value)) return
  return 'Not a valid cron expression'
}
const emailRegexp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/ // eslint-disable-line no-useless-escape
export const email = value => {
  if (!value) {
    return
  }

  if (_.isArray(value)) {
    for (const entry of value) {
      if (!emailRegexp.test(entry)) {
        return `Not a valid email address: ${entry}`
      }
    }
  } else {
    if (!emailRegexp.test(value)) {
      return `Not a valid email address: ${value}`
    }
  }
}
export const companyemail = value => {
  if (!value) {
    return
  }
  const parts = value.split('@')
  if (parts.length > 1) {
    const domain = parts[1]
    if (freeEmailDomains.includes(domain)) {
      return 'You are currently using an email from a free email provider. Please give us a company email address.'
    }
  }
}

// because OptionalField the original validator parameters "(value: ?any, allValues: Object, meta: ?FieldState) => ?any" are changed a bit
//
export const composeValidators = (...validators) => (value, callbackOrValues, metaOrNothing) => {
  let values = null
  if(_.isFunction(callbackOrValues)) {
    callbackOrValues(validators)
  } else {
    values = callbackOrValues
  }
  return validators.filter(v => v).reduce((error, validator) => error || validator(value, values, metaOrNothing), undefined)
}

export const Condition = ({ when, is, isnot, isin, isnotin, match, startswith, fn, children }) => (
  <Field name={when} subscription={{ value: true }}>
    {({ input: { value } }) => {
      if (is) return value === is ? children : null
      else if (isnot) return value !== isnot ? children : null
      else if (isin) return value && isin.findIndex(i => value === i) >= 0 ? children : null
      else if (isnotin) return !value || isnotin.findIndex(i => value === i) < 0 ? children : null
      else if (match) return value && `${value}`.indexOf(match) >= 0 ? children : null
      else if (startswith) return value && `${value}`.startswith(match) ? children : null
      else if (fn) return fn(value) ? children : null
      else if (value) return children
      else return null
    }}
  </Field>
)

export const ConditionalTooltip = ({ title, children }) => (<React.Fragment>
  {title}
  {title && <Tooltip title={title}>{children}</Tooltip>}
  {!title && children}
</React.Fragment>)

export const calculatorSetFromDropdown = (
  changedField,
  setField,
  lookupFunction,
  lookupValue,
) => {
  return createDecorator({
    field: changedField,
    updates: {
      [setField]: (changedFieldValue, allValues) => {
        const lookup = lookupFunction(changedFieldValue, allValues)
        if (lookup) {
          return lookup[lookupValue]
        }
      },
    },
  })
}
