import React from 'react'
import _ from 'lodash'
//import { lookup as mime } from 'mime-types'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import Avatar from '@material-ui/core/Avatar'
// core components
import Tooltip from 'components/Tooltip/Tooltip'
import CodeIcon from '@material-ui/icons/Code'
import SubtitlesIcon from '@material-ui/icons/Subtitles'
import TrendingUpIcon from '@material-ui/icons/TrendingUp'
import PictureInPictureIcon from '@material-ui/icons/PictureInPicture'
import ShortTextIcon from '@material-ui/icons/ShortText'
import TextFormatIcon from '@material-ui/icons/TextFormat'
import StorageIcon from '@material-ui/icons/Storage'
import HttpIcon from '@material-ui/icons/Http'
import LinkIcon from '@material-ui/icons/Link'
import ShowIcon from 'components/Icon/ShowIcon'
import Chip from 'components/Chip/Chip'
import convoStyle from 'assets/jss/material-dashboard-react/components/convoStyle'

const avatarContent = {
  MEDIA: <ShowIcon custom icon="inputMedia" />,
  MEDIA_COUNT: <ShowIcon custom icon="inputMedia" />,
  MEDIA_COUNT_REC: <ShowIcon custom icon="inputMedia" />,
  INTENT: <SubtitlesIcon />,
  INTENT_CONFIDENCE: <TrendingUpIcon />,
  ENTITIES: <SubtitlesIcon />,
  ENTITY_VALUES: <SubtitlesIcon />,
  ENTITY_CONTENT: <SubtitlesIcon />,
  BUTTONS: <ShowIcon custom icon="inputButton" />,
  BUTTONS_COUNT: <ShowIcon custom icon="inputButton" />,
  BUTTONS_COUNT_REC: <ShowIcon custom icon="inputButton" />,
  BUTTON: <ShowIcon custom icon="inputButton" />,
  CARDS: <PictureInPictureIcon />,
  CARDS_COUNT: <PictureInPictureIcon />,
  CARDS_COUNT_REC: <PictureInPictureIcon />,
  JSON_PATH: <CodeIcon />,
  JSON_PATH_COUNT: <CodeIcon />,
  RESPONSE_LENGTH: <TextFormatIcon />,
  FORMS: <ShowIcon custom icon="inputForm" />,
  FORM: <ShowIcon custom icon="inputForm" />,
  TEXT: <ShortTextIcon />,
  PAUSE: <ShowIcon custom icon="pause" />,
  WAITFORBOT: <ShowIcon custom icon="waitForBot" />,
  INCLUDE: <ShowIcon custom icon="partialConvo" />,
  BOT_CONSUMED: <ShowIcon custom icon="skipBotUnconsumed" />,
  BOT_UNCONSUMED_COUNT: <ShowIcon custom icon="skipBotUnconsumed" />,
  SKIP_BOT_UNCONSUMED: <ShowIcon custom icon="skipBotUnconsumed" />,
  ORDERED_LIST_TO_BUTTON: <ShowIcon icon="list-ol" style={{ width: 24 }} />,
  MSSQL: <StorageIcon />,
  MYSQL: <StorageIcon />,
  ORACLEDB: <StorageIcon />,
  POSTGRES: <StorageIcon />,
  HTTP: <HttpIcon />,
  HASLINK: <LinkIcon />,
  WATSONV1_HAS_CONTEXT: <ShowIcon icon="user-cog" style={{ width: 24 }} />,
  WATSONV1_CONTEXT: <ShowIcon icon="user-cog" style={{ width: 24 }} />,
  WATSONV1_ACTION: <ShowIcon icon="user-check" style={{ width: 24 }} />,
  WATSONV2_HAS_CONTEXT: <ShowIcon icon="user-cog" style={{ width: 24 }} />,
  WATSONV2_CONTEXT: <ShowIcon icon="user-cog" style={{ width: 24 }} />,
  WATSONV2_ACTION: <ShowIcon icon="user-check" style={{ width: 24 }} />,
  WATSONV2_ACTION_HAS_PARAMETER: <ShowIcon icon="user-check" style={{ width: 24 }} />,
  WATSONV2_ACTION_PARAMETER: <ShowIcon icon="user-check" style={{ width: 24 }} />,
  DIALOGFLOW_HAS_CONTEXT: <ShowIcon icon="user-cog" style={{ width: 24 }} />,
  DIALOGFLOW_CONTEXT_PARAMETER: <ShowIcon icon="user-cog" style={{ width: 24 }} />,
  DIALOGFLOW_HAS_CONTEXT_PARAMETER: <ShowIcon icon="user-cog" style={{ width: 24 }} />,
  DIALOGFLOW_CONTEXT_LIFESPAN: <ShowIcon icon="user-cog" style={{ width: 24 }} />,
  DIALOGFLOWCX_HAS_PARAMETER: <ShowIcon icon="user-cog" style={{ width: 24 }} />,
  DIALOGFLOWCX_PARAMETER: <ShowIcon icon="user-cog" style={{ width: 24 }} />,
  CHECKTWILIOSMS: <ShowIcon icon="sms" style={{ width: 24 }} />,
  CHECKINBOX: <ShowIcon icon="envelope" className={convoStyle.iconFas} style={{ width: 24 }} />,
  CHECKLIVEPERSONSKILL: <ShowIcon icon="user-cog" style={{ width: 24 }} />,
  SETFROMHTTPGET: <HttpIcon />,
  SETFROMHTTPPOST: <HttpIcon />,
  SETPARAMETERSTOREFROMHTTPGET: <HttpIcon />,
  SETPARAMETERSTOREFROMHTTPPOS: <HttpIcon />,
  HTTPGET: <HttpIcon />,
  HTTPPOST: <HttpIcon />,
  SET_SCRIPTING_MEMORY: <ShowIcon custom icon="setScripting" />,
  ASSIGN_SCRIPTING_MEMORY: <ShowIcon custom icon="assignScripting" />,
  CLEAR_SCRIPTING_MEMORY: <ShowIcon custom icon="clearScripting" />,
  EDIT: <ShowIcon icon="pen" />,
}

const avatarContentAsserters = {
  CUSTOM: <ShowIcon custom icon="asserterCustom" />,
  MEDIA: <ShowIcon custom icon="asserterMedia" />,
  MEDIA_COUNT: <ShowIcon custom icon="asserterMedia" />,
  MEDIA_COUNT_REC: <ShowIcon custom icon="asserterMedia" />,
  INTENT: <ShowIcon custom icon="asserterIntent" />,
  INTENT_CONFIDENCE: <ShowIcon custom icon="asserterIntent" />,
  ENTITIES: <ShowIcon custom icon="asserterEntities" />,
  ENTITY_VALUES: <ShowIcon custom icon="asserterEntities" />,
  ENTITY_CONTENT: <ShowIcon custom icon="asserterEntities" />,
  BUTTONS: <ShowIcon custom icon="asserterButton" />,
  BUTTONS_COUNT: <ShowIcon custom icon="asserterButton" />,
  BUTTONS_COUNT_REC: <ShowIcon custom icon="asserterButton" />,
  BUTTON: <ShowIcon custom icon="asserterButton" />,
  CARDS: <ShowIcon custom icon="asserterCards" />,
  CARDS_COUNT: <ShowIcon custom icon="asserterCards" />,
  CARDS_COUNT_REC: <ShowIcon custom icon="asserterCards" />,
  JSON_PATH: <ShowIcon custom icon="asserterJson" />,
  JSON_PATH_COUNT: <ShowIcon custom icon="asserterJson" />,
  RESPONSE_LENGTH: <ShowIcon custom icon="asserterResponseLength" />,
  FORMS: <ShowIcon custom icon="asserterForm" />,
  FORM: <ShowIcon custom icon="asserterForm" />,
  TEXT: <ShowIcon custom icon="asserterText" />,
  PAUSE: <ShowIcon custom icon="asserterAsserter" />,
  BOT_CONSUMED: <ShowIcon custom icon="asserterBotConsumed" />,
  BOT_UNCONSUMED_COUNT: <ShowIcon custom icon="asserterBotUnconsumed" />,
  MSSQL: <ShowIcon custom icon="asserterDatabase" />,
  MYSQL: <ShowIcon custom icon="asserterDatabase" />,
  ORACLEDB: <ShowIcon custom icon="asserterDatabase" />,
  POSTGRES: <ShowIcon custom icon="asserterDatabase" />,
  HTTP: <ShowIcon custom icon="asserterHttp" />,
  HASLINK: <ShowIcon custom icon="asserterLink" />,
  CHECKLINK: <ShowIcon custom icon="asserterLink" />,
  WATSONV1_HAS_CONTEXT: <ShowIcon custom icon="asserterContext" />,
  WATSONV1_CONTEXT: <ShowIcon custom icon="asserterContext" />,
  WATSONV1_ACTION: <ShowIcon custom icon="asserterAction" />,
  WATSONV2_HAS_CONTEXT: <ShowIcon custom icon="asserterContext" />,
  WATSONV2_CONTEXT: <ShowIcon custom icon="asserterContext" />,
  WATSONV2_ACTION: <ShowIcon custom icon="asserterAction" />,
  WATSONV2_ACTION_HAS_PARAMETER: <ShowIcon custom icon="asserterAction" />,
  WATSONV2_ACTION_PARAMETER: <ShowIcon custom icon="asserterAction" />,
  DIALOGFLOW_HAS_CONTEXT: <ShowIcon custom icon="asserterContext" />,
  DIALOGFLOW_CONTEXT_PARAMETER: <ShowIcon custom icon="asserterContext" />,
  DIALOGFLOW_HAS_CONTEXT_PARAMETER: <ShowIcon custom icon="asserterContext" />,
  DIALOGFLOW_CONTEXT_LIFESPAN: <ShowIcon custom icon="asserterContext" />,
  DIALOGFLOWCX_HAS_PARAMETER: <ShowIcon custom icon="asserterContext" />,
  DIALOGFLOWCX_PARAMETER: <ShowIcon custom icon="asserterContext" />,
  CHECKSMS: <ShowIcon custom icon="asserterSms" />,
  CHECKTWILIOSMS: <ShowIcon custom icon="asserterSms" />,
  CHECKINBOX: <ShowIcon custom icon="asserterMail" />,
  CHECKLIVEPERSONSKILL: <ShowIcon custom icon="asserterContext" />,
  FACTCHECK: <ShowIcon custom icon="asserterFactCheck" />,
}

const avatarContentLogicHooks = {
  CUSTOM: <ShowIcon custom icon="logichookCustom" />,
  WAITFORBOT: <ShowIcon custom icon="logichookWaitForBot" />,
  INCLUDE: <ShowIcon custom icon="logichookPartialConvo" />,
  PAUSE: <ShowIcon custom icon="logichookPause" />,
  SKIP_BOT_UNCONSUMED: <ShowIcon custom icon="logichookSkipBotUnconsumed" />,
  SET_SCRIPTING_MEMORY: <ShowIcon custom icon="logichookSetScripting" />,
  ASSIGN_SCRIPTING_MEMORY: <ShowIcon custom icon="logichookAssignScripting" />,
  CLEAR_SCRIPTING_MEMORY: <ShowIcon custom icon="logichookClearScripting" />,
  SETFROMHTTPGET: <ShowIcon custom icon="logichookHttp" />,
  SETFROMHTTPPOST: <ShowIcon custom icon="logichookHttp" />,
  SETPARAMETERSTOREFROMHTTPGET: <ShowIcon custom icon="logichookHttp" />,
  SETPARAMETERSTOREFROMHTTPPOST: <ShowIcon custom icon="logichookHttp" />,
  HTTPGET: <ShowIcon custom icon="logichookHttp" />,
  HTTPPOST: <ShowIcon custom icon="logichookHttp" />,
  VOIP_IGNORE_SILENCE_DURATION: <ShowIcon custom icon="logichookVoipIgnoreSilenceDuration" />,
  VOIP_JOIN_SILENCE_DURATION: <ShowIcon custom icon="logichookVoipJoinBySilenceDuration" />,
  'PERFECTO-REPORTING': <ShowIcon custom icon="logichookPerfectoReporting" />,
  CONDITIONAL_STEP: <ShowIcon custom icon="conditionalLogichook" />
}

export function getComponentIcon(name, type) {
  if (type && type === 'asserter') {
    if (avatarContentAsserters[name]) return avatarContentAsserters[name]
    return avatarContentAsserters['CUSTOM']
  }
  if (type && type === 'logichook') {
    if (avatarContentLogicHooks[name]) return avatarContentLogicHooks[name]
    if (name.startsWith('CONDITIONAL_STEP')) return avatarContentLogicHooks['CONDITIONAL_STEP']
    return avatarContentLogicHooks['CUSTOM']
  }
  if (avatarContent[name]) return avatarContent[name]
  return <ShowIcon icon="puzzle-piece" />
}

export function renderComponentAvatar(name, args, type) {
  if (name && name.startsWith('TEXT')) {
    return getComponentIcon('TEXT', type)
  }
  if (name && name.startsWith('MEDIA') && args && args.length > 0) {
    /*const mimeType = mime(args[0])
    if (mimeType) {
      if (mimeType.startsWith('audio')) {
        return getComponentIcon('AUDIO')
      }
      if (mimeType.startsWith('video')) {
        return getComponentIcon('VIDEO')
      }
    }*/
  }
  if (name) {
    return getComponentIcon(name, type)
  }
  return null
}

class ComponentChip extends React.Component {

  flattenObj(obj) {
    let result = {}
    for (const key in obj) {
      if (_.isObject(obj[key]) && !_.isArray(obj[key])) {
        const temp = this.flattenObj(obj[key])
        for (const j in temp) {
          result[key + '.' + j] = temp[j]
        }
      } else {
        if (!_.isArray(obj[key])) {
          result[key] = obj[key]
        } else {
          const flattenList = obj[key].map(a => {
            if (_.isObject(a) && !_.isArray(a)) {
              const flattenElement = this.flattenObj(a)
              return Object.entries(flattenElement).map(([key, value]) => `${key}="${value}"`).join(', ')
            } else {
              return a
            }
          })
          console.log(flattenList)
          result[key] = `[${flattenList.join(', ')}]`
        }
      }
    }
    console.log()
    return result
  }

  isJsonObjectString(str) {
    try {
      const obj = JSON.parse(str)
      return _.isObject(obj) && !_.isArray(obj)
    } catch (e) {
      return false
    }
  }

  getArgsLabel(args, beautifyJsonArgs, component) {
    if (!args) return

    if (component?.name === 'CONNECTORPROPS' && args && args.length > 0 && args[0].length) {
      return args[0].map(arg => {
        let normalizedArg = (arg.stringValue || arg.intValue || arg.booleanValue || arg.jsonValue || '')
          .replace(/(\r\n|\n|\r)/g, '')
        if (normalizedArg.length > 23) {
          normalizedArg = normalizedArg.substring(0, 20) + '...'
        }
        return `${arg.name || '<Empty>'}: ${normalizedArg}`
      }).join(' | ')
    }
    if (!beautifyJsonArgs) return args.filter(a => !_.isNil(a)).join(' | ')

    return args.filter(a => !_.isNil(a)).map(arg => {
      // For http logichooks the headers argument is an array, not a string
      if (!_.isString(arg)) {
        const flattenArg = this.flattenObj(arg)
        return Object.entries(flattenArg).map(([key, value]) => `${key}="${value}"`).join(', ')
      }

      if (this.isJsonObjectString(arg)) {
        const flattenArg = this.flattenObj(JSON.parse(arg))
        return Object.entries(flattenArg).map(([key, value]) => `${key}="${value}"`).join(', ')
      } else if (arg.startsWith('data:')) {
        let parts = arg.split(';', 2)
        if (parts[1] && parts[1].startsWith('filename=')) {
          parts = arg.split(';', 3)
          return decodeURIComponent(parts[1].split('=')[1])
        }
        return '(Encoded Binary Data)'
      } else {
        return arg
      }
    }).join(' | ')
  }

  getTooltip(component, componentLabel, argsLabel) {
    return (component.not ? '! ' : '') + (componentLabel || component.name) + (argsLabel ? ` ${argsLabel}` : '')
  }

  render() {
    const { component, componentType, truncate, sliceLabel = 255, onDelete, onClick, argsToShow, beautifyJsonArgs = true, componentLabel } = this.props
    const ac = renderComponentAvatar(component.name, component.args, componentType)
    const argsLabel = this.getArgsLabel(argsToShow || component.args, beautifyJsonArgs, component)
    const tooltip = this.getTooltip(component, componentLabel, argsLabel)
    let label = argsLabel || componentLabel || component.name
    if (sliceLabel && sliceLabel > 0 && label.length > sliceLabel) {
      label = label.slice(0, sliceLabel) + '...'
    }

    return (
      <Tooltip title={tooltip}>
        <Chip variant="component"
          avatar={<Avatar>{ac}</Avatar>}
          label={label}
          truncate={truncate ? truncate : '100%'}
          onDelete={onDelete}
          onClick={onClick}
          badge={component.not ? 'NOT' : null}
          clickable
        />
      </Tooltip>
    )
  }
}

export default withStyles(convoStyle)(ComponentChip)
