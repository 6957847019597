import React from 'react'
import _ from 'lodash'
import moment from 'moment'

import TrendingUpIcon from '@material-ui/icons/TrendingUp'
import TrendingDownIcon from '@material-ui/icons/TrendingDown'
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat'

import { toConfidence } from 'helper/numberHelper'
import Text from 'components/Typography/Text'

export const EMPTY_UTTERANCE = '<empty>'
export const renderTrending = (value, upIsBad = false, valueToCompare = 0) => {
  if (!_.isNumber(value) || !_.isNumber(valueToCompare)) {
    return null
  }
  const increasing = value - valueToCompare

  const icon = increasing > 0 ? <TrendingUpIcon/> : (increasing < 0 ? <TrendingDownIcon/> : <TrendingFlatIcon/>)
  const itsCool = upIsBad ? -increasing : increasing
  return renderTrendingColor(icon, itsCool)
}

export const renderTrendingIcon = (trend, cool) => {
  const icon = trend > 0 ? <TrendingUpIcon/> : (trend < 0 ? <TrendingDownIcon/> : <TrendingFlatIcon/>)
  return renderTrendingColor(icon, cool)
}

export const renderTrendingColor = (component, cool) => {
  if (cool > 0) return <Text success inline>{component}</Text>
  if (cool < 0) return <Text danger inline>{component}</Text>
  return <Text info inline>{component}</Text>
}

/**
 *
 * @param text
 * @param trend
 * @param inverse
 * @param fixed
 * @param forceTrendLabel if both primary and secondary values exist, then the trend must be displayed even it is 0
 * @returns {*}
 */
export const renderTrendingText = (text, trend, inverse, fixed = 0, forceTrendLabel = false) => {
  if (!_.isNumber(trend)) {
    return <Text info>{text} ({trend})</Text>
  }
  const trendLabel = renderTrendingLabel(trend, inverse, fixed)

  if (trend > 0) return <Text success>{text} ({trendLabel})</Text>
  else if (trend < 0) return <Text danger>{text} ({trendLabel})</Text>
  else return forceTrendLabel ? <Text info>{text} ({trendLabel})</Text> : <Text info>{text}</Text>
}

export const renderTrendingLabel = (trend, inverse = false, fixed = 0) => {
  if (_.isNil(trend)) {
    return null
  }
  const trendCount = inverse ? -trend : trend
  return trendCount < 0 ? `${trendCount.toFixed(fixed)}` : `+${trendCount.toFixed(fixed)}`
}

// current behaviour: incomprehension is ovelall bad, even if we expect it
// if we want to refinde it, we have to alter everything marked with this
export const INCOMPREHENSION_IS_BAD = true

export const toFixedSafe = (maybeNumber) => toConfidence(maybeNumber)

export const testsessionLabel = (testsession) => {
  if (testsession) {
    return <React.Fragment>{testsession.testProject.name + ' / ' + moment(testsession.updatedAt).format('lll')}</React.Fragment>
  }
  return null
}
