import {gql} from 'apollo-boost'

export const FULL_TRAINER_ENTITY_FRAGMENT = gql`
  fragment FullTrainerEntity on TrainerUtteranceEntity {
    id
    expected {
      id
      entityKey
      name
      confidenceReal
      confidenceCorrected
      matches
    }
    expectedNames
    #  field is not used yet. Expected, and expected values are independent, they may come from different asserters.
        expectedValues
    actual {
      id
      entityKey
      name
      value
      confidence
      justActualAndChecked
      justActualAndNotChecked
      matches
    }
  }
`

export const FULL_TRAINER_PER_CORRECTED_INTENT_FRAGMENT = gql`
  fragment FullTrainerPerCorrectedIntent on TrainerPerCorrectedIntent {
    id
    name
    incomprehension
    count
    sum
    confidenceDifferenceSquaredSum
    avg
    deviation
    accuracy
    precision
    recall
    F1
  }
`

export const FULL_TRAINER_PER_ACTUAL_ENTITY_FRAGMENT = gql`
  fragment FullTrainerPerActualEntity on TrainerPerActualEntity {
    id
    name
    count
    sum
    confidenceDifferenceSquaredSum
    avg
    deviation
  }
`

export const FULL_TRAINER_UTTERANCE_FRAGMENT = gql`
  fragment FullTrainerUtterance on TrainerUtterance {
    id
    utterance
    utteranceKey
    step
    testCaseName
    intent {
      id
      expected
      actual
      incomprehension
      incomprehensionIncompUtterance
      actuals {
        id
        name
        confidence
        incomprehension
      }
      confidenceCorrected
      confidenceReal
      confidenceDifferenceExpected
      confidenceDifferenceActual
      confidenceDifferenceCorrected
      matches
      confusionProbability
    }
    entity {
      ...FullTrainerEntity
    }
    # maybe this should be moved to a different query. Used just in Utterance.jsx
    # and it makes all other requests slower
    testCaseResult {
      id
      testcaseName
      testSetScript {
        id
        scriptType
      }
      testSet {
        id
        name
      }
    }
  }
  ${FULL_TRAINER_ENTITY_FRAGMENT}
`

export const FULL_TRAINER_OVERALL_STAT_FRAGMENT = gql`
  fragment FullTrainerOverallStat on TrainerOverallStat {
    id

    # test scripts
    testScriptsProcessed
    testScriptsIgnored

    # steps
    steps
    stepsIgnored
    stepsWithActualIntent
    stepsWithIntent
    stepsWithEntity
    stepsWithEntityValue

    # utterances(responses)
    count

    # intents
    sum
    avg
    incomprehensionIntents
    matchedIntents
    missedIntents
    confidenceDifferenceSquaredSum
    confidenceDifferenceSquaredSumExpected
    confidenceDifferenceSquaredSumActual
    accuracy
    precision
    recall
    F1
    actualIntentCount
    actualIntentCountNoIncomprehension
    confusionMatrixStrength

    # entities
    justExpectedEntities
    justActualEntitiesChecked
    justActualEntitiesNotChecked
    expectedAndActualEntities
    actualEntityCount

    # support flags
    intentResolutionSupported
    intentConfidenceSupported
    intentListSupported

    entityResolutionSupported
    entityConfidenceSupported
    expectedIntentsSupported
    expectedEntitiesSupported
  }
`

export const LIST_TRAINER_OVERALL_STAT_FRAGMENT = gql`
  fragment ListTrainerOverallStat on TrainerOverallStat {
    id
    count
    incomprehensionIntents
    F1
    confusionMatrixStrength
  }
`

export const TRAINER_SESSION_ROOT_QUERY = gql`
  query TrainerSessionRootQuery($id: ID!) {
    testsession(id: $id) {
      id
      name
      createdAt
      updatedAt
      status
      chatbot {
        id
        name
        avatar
        containermode
      }
      testProject {
        id
        name
        minimumAverageConfidence
        maximumConfidenceDeviation
        minimumConfidenceDiff
        minimumUtterancesPerIntent
        minimumUtterancesPerEntity
      }
      testSets {
        id
        name
      }
      trainerSession {
        id
        status
        err
        cleanedUp
        overallStat {
          ...FullTrainerOverallStat
        }
      }
    }
  }
  ${FULL_TRAINER_OVERALL_STAT_FRAGMENT}
`

export const TRAINER_SESSION_ROOT_SUBSCRIPTION = gql`
  subscription TrainerSessionRootSubscription($id: ID!) {
    testSessionProgress(testSessionId: $id) {
      id
      name
      createdAt
      updatedAt
      status
      chatbot {
        id
        name
        avatar
        containermode
      }
      testProject {
        id
        name
        minimumAverageConfidence
        maximumConfidenceDeviation
        minimumConfidenceDiff
        minimumUtterancesPerIntent
        minimumUtterancesPerEntity
      }
      testSets {
        id
        name
      }
      trainerSession {
        id
        status
        err
        cleanedUp
        overallStat {
          ...FullTrainerOverallStat
        }
      }
    }
  }
  ${FULL_TRAINER_OVERALL_STAT_FRAGMENT}
`

export const CHART_INTENT_CONFIDENCE_DISTRIBUTION_QUERY = gql`
  query TrainerChartIntentConfidenceDistributionQuery($testSessionId: ID!, $intentFilter: String!, $showDataByExpected: Boolean!) {
    trainerChartIntentConfidenceDistribution(testSessionId: $testSessionId, intentFilter: $intentFilter, showDataByExpected: $showDataByExpected) {
      stepIndex
      count
    }
  }
`
export const CHART_INTENT_MISMATCH_PROBABILITY_PER_UTTERANCE_QUERY = gql`
  query TrainerChartIntentMismatchProbabilityPerUtteranceQuery($testSessionId: ID!, $intentFilter: String!, $utterance: String) {
    trainerChartIntentMismatchProbabilityPerUtterance(testSessionId: $testSessionId, intentFilter: $intentFilter, utterance: $utterance) {
      id
      confusionProbability
      trainerUtterance {
        utteranceKey
        utterance
      }
      actuals {
        name
        confidence
      }
    }
  }
`
export const CHART_INTENT_MISMATCH_PROBABILITY_PER_UTTERANCE_SECONDARY_QUERY = gql`
  query TrainerChartIntentMismatchProbabilityPerUtteranceSecondaryQuery($testSessionId: ID!, $utteranceKeys: [String!]!) {
    trainerChartIntentMismatchProbabilityPerUtteranceSecondary(testSessionId: $testSessionId, utteranceKeys: $utteranceKeys) {
      id
      confusionProbability
      trainerUtterance {
        utteranceKey
        utterance
      }
      actuals {
        name
        confidence
      }
    }
  }

`
export const CHART_INTENT_QUERY = gql`
  query TrainerChartIntentQuery($testSessionId: ID!, $intentFilter: String, $showDataByExpected: Boolean!, $orderBy: String!, $incomprehensionIsBad: Boolean) {
    trainerChartIntent(testSessionId: $testSessionId, intentFilter: $intentFilter, showDataByExpected: $showDataByExpected, orderBy: $orderBy, incomprehensionIsBad: $incomprehensionIsBad) {
      id
      name
      count
      sum
      confidenceDifferenceSquaredSum
      avg
      deviation
      accuracy
      precision
      recall
      F1
    }
  }
`
export const CHART_INTENT_SECONDARY_QUERY = gql`
  query TrainerChartIntentSecondaryQuery($testSessionId: ID!, $showDataByExpected: Boolean!, $intentNames: [String!]!) {
    trainerChartIntentSecondary(testSessionId: $testSessionId, showDataByExpected: $showDataByExpected, intentNames: $intentNames) {
      id
      name
      count
      sum
      confidenceDifferenceSquaredSum
      avg
      deviation
      accuracy
      precision
      recall
      F1
    }
  }
`

export const CHART_ENTITY_QUERY = gql`
  query TrainerChartEntityQuery($testSessionId: ID!, $entityFilter: String, $orderBy: String!) {
    trainerChartEntity(testSessionId: $testSessionId, entityFilter: $entityFilter, orderBy: $orderBy) {
      id
      name
      count
      sum
      confidenceDifferenceSquaredSum
      avg
      deviation
    }
  }
`
export const CHART_ENTITY_SECONDARY_QUERY = gql`
  query TrainerChartEntitySecondaryQuery($testSessionId: ID!, $entityNames: [String!]!) {
    trainerChartEntitySecondary(testSessionId: $testSessionId, entityNames: $entityNames) {
      id
      name
      count
      sum
      confidenceDifferenceSquaredSum
      avg
      deviation
    }
  }
`
export const LIST_UNEXPECTED_INTENT_QUERY = gql`
  query TrainerListUnexpectedIntentQuery($testSessionId: ID!, $intentFilter: String!) {
    trainerListUnexpectedIntent(testSessionId: $testSessionId, intentFilter: $intentFilter) {
      id
      intent1
      intent2
      intent1And2
      count
    }
  }
`
export const LIST_UNEXPECTED_INTENT_SECONDARY_QUERY = gql`
  query TrainerListUnexpectedIntentSecondaryQuery($testSessionId: ID!, $intent1And2s: [String!]!) {
    trainerListUnexpectedIntentSecondary(testSessionId: $testSessionId, intent1And2s: $intent1And2s) {
      id
      intent1
      intent2
      intent1And2
      count
    }
  }
`
export const LIST_UNEXPECTED_INTENT_BY_ALTERNATIVE_LIST_QUERY = gql`
  query TrainerListUnexpectedIntentByAlternativeListQuery($testSessionId: ID!, $intentFilter: String!) {
    trainerListUnexpectedIntentByAlternativeList(testSessionId: $testSessionId, intentFilter: $intentFilter) {
      id
      intent1
      intent2
      intent1And2
      count
      avg
      worstConfidence
      worstUtterance
    }
  }
`
export const LIST_UNEXPECTED_INTENT_BY_ALTERNATIVE_LIST_SECONDARY_QUERY = gql`
  query TrainerListUnexpectedIntentByAlternativeListSecondaryQuery($testSessionId: ID!, $intent1And2s: [String!]!) {
    trainerListUnexpectedIntentByAlternativeListSecondary(testSessionId: $testSessionId, intent1And2s: $intent1And2s) {
      id
      intent1
      intent2
      intent1And2
      count
      avg
      worstConfidence
      worstUtterance
    }
  }
`
export const OVERVIEW_INTENT_COMPARE_QUERY = gql`
  query TrainerOverviewIntentCompareQuery($testSessionId: ID!, $secondaryTestSessionId: ID!) {
    trainerOverviewIntentCompare(testSessionId: $testSessionId, secondaryTestSessionId: $secondaryTestSessionId) {
      id
      justPrimaryCount
      justSecondaryCount
      wrongToCorrectCount
      correctToWrongCount
      wrongToWrongWithOtherIntentCount
      wrongToWrongWithSameIntentCount
      sameIntentCount
      otherIntentCount
    }
  }
`
export const OVERVIEW_ENTITY_COMPARE_QUERY = gql`
  query TrainerOverviewEntityCompareQuery($testSessionId: ID!, $secondaryTestSessionId: ID!) {
    trainerOverviewEntityCompare(testSessionId: $testSessionId, secondaryTestSessionId: $secondaryTestSessionId) {
      id
      correctToWrongCount
      wrongOrNothingToWrongCount
    }
  }
`
export const LIST_INTENT_COMPARE_QUERY = gql`
  query TrainerListIntentCompareQuery($testSessionId: ID!, $secondaryTestSessionId: ID!, $fieldName: String!) {
    trainerListIntentCompare(testSessionId: $testSessionId, secondaryTestSessionId: $secondaryTestSessionId, fieldName: $fieldName) {
      primary {
        id
        utterance
        utteranceKey
        step
        testCaseName
        intent {
          id
          expected
          actual
          confidenceCorrected
          confidenceReal
          confidenceDifferenceExpected
          confidenceDifferenceActual
          confidenceDifferenceCorrected
          matches
          confusionProbability
        }
      }
      secondary {
        id
        utterance
        utteranceKey
        step
        testCaseName
        intent {
          id
          expected
          actual
          confidenceCorrected
          confidenceReal
          confidenceDifferenceExpected
          confidenceDifferenceActual
          confidenceDifferenceCorrected
          matches
          confusionProbability
        }
      }
    }
  }
`
export const LIST_UTTERANCES_FOR_ACTUAL_ENTITY_QUERY = gql`
  query TrainerListUtterancesForActualEntityQuery($actualEntityId: ID!, $orderBy: String!) {
    trainerListUtterancesForActualEntity(actualEntityId: $actualEntityId, orderBy: $orderBy) {
      id
      utterance
      utteranceKey
      step
      testCaseName
      entity {
        id
        expectedNames
        actual {
          id
          entityKey
          name
          value
          confidence
          matches
        }
      }
    }
  }
`
export const IGNORED_TEST_SCRIPTS_QUERY = gql`
  query TrainerIgnoredTestScriptsQuery($testSessionId: ID!) {
    trainerIgnoredTestScripts(testSessionId: $testSessionId) {
      id
      testcaseName
      err
    }
  }
`
export const IGNORED_TEST_SCRIPT_STEPS_QUERY = gql`
  query TrainerIgnoredTestScriptStepsQuery($testSessionId: ID!) {
    trainerIgnoredTestScriptSteps(testSessionId: $testSessionId) {
      id
      testCaseResultStep {
        id
      }
      stepIndex
      err
    }
  }
`
export const UTTERANCES_QUERY = gql`
  query TrainerUtterances(
    $testSessionId: ID,
    $orderBy: String,
    $intent: String,
    $intentFilterActual: String,
    $expectedIntent: String,
    $correctedIntent: String,
    $actualIntent: String,
    $entity: String,
    $actualEntity: String,
    $intentConfidenceRealFrom: Float,
    $intentConfidenceRealTo: Float,
    $intentConfidenceCorrectedFrom: Float,
    $intentConfidenceCorrectedTo: Float,
    $hasNotMatchedIntent: Boolean,
    $isIncomprehension: Boolean,
    $expectedIntentIsNull: Boolean,
    $withEntityActualAndChecked: Boolean,
    $hasNotMatchedEntity: Boolean,
    $utterance: String
  ) {
    trainerUtterances(
      testSessionId: $testSessionId,
      orderBy: $orderBy,
      intent: $intent,
      intentFilterActual: $intentFilterActual,
      expectedIntent: $expectedIntent,
      correctedIntent: $correctedIntent,
      actualIntent: $actualIntent,
      entity: $entity,
      actualEntity: $actualEntity,
      intentConfidenceRealFrom: $intentConfidenceRealFrom,
      intentConfidenceRealTo: $intentConfidenceRealTo,
      intentConfidenceCorrectedFrom: $intentConfidenceCorrectedFrom,
      intentConfidenceCorrectedTo: $intentConfidenceCorrectedTo,
      hasNotMatchedIntent: $hasNotMatchedIntent,
      isIncomprehension: $isIncomprehension,
      expectedIntentIsNull: $expectedIntentIsNull,
      withEntityActualAndChecked: $withEntityActualAndChecked,
      hasNotMatchedEntity: $hasNotMatchedEntity,
      utterance: $utterance
    )
    {
    ...FullTrainerUtterance
    }
  }
  ${FULL_TRAINER_UTTERANCE_FRAGMENT}
`
export const UTTERANCES_FOR_INTENT_QUERY = gql`
  query TrainerUtterancesForIntentQuery($testSessionId: ID!, $orderBy: String, $intent: String, $expectedIntent: String, $correctedIntent: String, $actualIntent: String) {
    trainerUtterances(testSessionId: $testSessionId, orderBy: $orderBy, intent: $intent, expectedIntent: $expectedIntent, correctedIntent: $correctedIntent, actualIntent: $actualIntent) {
      id

      utterance
      utteranceKey
      step
      testCaseName
      intent {
        id
        expected
        actual
        confidenceCorrected
        confidenceReal
        confidenceDifferenceExpected
        confidenceDifferenceActual
        matches
      }
    }
  }
`
export const UTTERANCES_WITH_ACTUAL_ENTITIES_QUERY = gql`
  query TrainerUtterancesWithActualEntitiesQuery($testSessionId: ID!, $orderBy: String, $entity: String, $actualEntity: String) {
    trainerUtterances(testSessionId: $testSessionId, orderBy: $orderBy, entity: $entity, actualEntity: $actualEntity) {
      id
      utterance
      utteranceKey
      step
      testCaseName
      entity {
        ...FullTrainerEntity
      }
    }
  }
  ${FULL_TRAINER_ENTITY_FRAGMENT}
`

export const UTTERANCES_AND_ACTUAL_INTENTS_QUERY = gql`
  query TrainerUtterancesAndActualIntentsQuery($testSessionId: ID!, $intentFilterActual: String) {
    trainerUtterances(testSessionId: $testSessionId, intentFilterActual: $intentFilterActual) {
      id

      utterance
      utteranceKey
      step
      testCaseName
      intent {
        id
        expected
        actual
        confidenceCorrected
        confidenceReal
        confidenceDifferenceExpected
        confidenceDifferenceActual
        matches
        actuals {
          name
          confidence
        }
      }
    }
  }
`
export const UTTERANCE_BY_UTTERANCE_KEY_QUERY = gql`
  query TrainerUtteranceByUtteranceKeyQuery($testSessionId: ID!, $utteranceKey: String!) {
    trainerUtterance(testSessionId: $testSessionId, utteranceKey: $utteranceKey) {
      id

      utterance
      utteranceKey
      step
      testCaseName
      intent {
        id
        expected
        actual
        confidenceCorrected
        confidenceReal
        confidenceDifferenceExpected
        confidenceDifferenceActual
        matches
        actuals {
          name
          confidence
        }
      }
    }
  }
`

export const UTTERANCES_FOR_INTENT_CONFIDENCE_DISTRIBUTION_QUERY = gql`
  query TrainerUtterancesForIntentConfidenceDistributionQuery(
    $testSessionId: ID!,
    $intentConfidenceRealFrom: Float,
    $intentConfidenceRealTo: Float,
    $intentConfidenceCorrectedFrom: Float,
    $intentConfidenceCorrectedTo: Float,
    $isIncomprehension: Boolean
  ) {
    trainerUtterances(
      testSessionId: $testSessionId,
      intentConfidenceRealFrom: $intentConfidenceRealFrom,
      intentConfidenceRealTo: $intentConfidenceRealTo,
      intentConfidenceCorrectedFrom: $intentConfidenceCorrectedFrom,
      intentConfidenceCorrectedTo: $intentConfidenceCorrectedTo,
      isIncomprehension: $isIncomprehension
    ) {
      id

      utterance
      utteranceKey
      step
      testCaseName
      intent {
        id
        expected
        actual
        confidenceCorrected
        confidenceReal
        confidenceDifferenceExpected
        confidenceDifferenceActual
        matches
      }
    }
  }
`

export const UTTERANCES_FOR_CHECKED_ENTITIES = gql`
  query TrainerUtterancesForCheckedEntitiesQuery(
    $testSessionId: ID!,
    $withEntityActualAndChecked: Boolean!
  ) {
    trainerUtterances(
      testSessionId: $testSessionId,
      withEntityActualAndChecked: $withEntityActualAndChecked
    ) {
      id

      utterance
      utteranceKey
      step
      testCaseName
      entity {
        ...FullTrainerEntity
      }
    }
  }
  ${FULL_TRAINER_ENTITY_FRAGMENT}
`

export const UTTERANCES_JUST_EXPECTED_ENTITIES = gql`
  query TrainerUtterancesJustExpectedEntitiesQuery(
    $testSessionId: ID!
  ) {
    trainerUtterances(
      testSessionId: $testSessionId,
      hasNotMatchedEntity: true
    ) {
      id

      utterance
      utteranceKey
      step
      testCaseName
      entity {
        ...FullTrainerEntity
      }
    }
  }
  ${FULL_TRAINER_ENTITY_FRAGMENT}
`

export const UTTERANCES_NOT_MATCHED_INTENT_QUERY = gql`
  query TrainerUtterancesJustExpectedIntentsQuery(
    $testSessionId: ID!
  ) {
    trainerUtterances(
      testSessionId: $testSessionId,
          hasNotMatchedIntent: true
    ) {
    ...FullTrainerUtterance
    }
  }
  ${FULL_TRAINER_UTTERANCE_FRAGMENT}
`
export const UTTERANCES_INCOMPREHENSION_QUERY = gql`
  query TrainerUtterancesIncomprehensionQuery(
    $testSessionId: ID!
  ) {
    trainerUtterances(
      testSessionId: $testSessionId,
      isIncomprehension: true
    ) {
    ...FullTrainerUtterance
    }
  }
  ${FULL_TRAINER_UTTERANCE_FRAGMENT}
`

export const PER_ACTUAL_INTENTS_QUERY = gql`
  query TrainerPerActualIntentsQuery($testSessionId: ID!, $orderBy: String, $incomprehensionIsBad: Boolean) {
    trainerPerActualIntents(testSessionId: $testSessionId, orderBy: $orderBy, incomprehensionIsBad: $incomprehensionIsBad) {
      id

      name
      count
      sum
      avg
      deviation
      accuracy
      precision
      recall
      F1
    }
  }
`

export const CONFIDENCE_THRESHOLDS_QUERY = gql`
  query TrainerConfidenceThresholds($testSessionId: ID!) {
    trainerConfidenceThresholds(testSessionId: $testSessionId) {
      confidenceThreshold
      truePositive
      trueNegative
      falsePositive
      falseNegative
    }
  }
`
export const PER_CORRECTED_INTENT_QUERY = gql`
  query TrainerPerCorrectedIntentQuery($where: TrainerPerCorrectedIntentWhereInput!, $first: Int!, $orderBy: TrainerPerCorrectedIntentOrderByInput) {
    trainerpercorrectedintents(where: $where, first: $first, orderBy: $orderBy) {
      ...FullTrainerPerCorrectedIntent
    }
  }
  ${FULL_TRAINER_PER_CORRECTED_INTENT_FRAGMENT}
`
export const PER_ACTUAL_ENTITY_QUERY = gql`
  query TrainerPerActualEntityQuery($where: TrainerPerActualEntityWhereInput!, $first: Int!, $orderBy: TrainerPerActualEntityOrderByInput) {
    trainerperactualentities(where: $where, first: $first, orderBy: $orderBy) {
      ...FullTrainerPerActualEntity
    }
  }
  ${FULL_TRAINER_PER_ACTUAL_ENTITY_FRAGMENT}
`

export const INTENT_CONFIDENCE_DISTRIBUTIONS = gql`
  query TrainerConfidenceDistributions($where: TrainerConfidenceDistributionWhereInput!, $orderBy: TrainerConfidenceDistributionOrderByInput) {
    trainerConfidenceDistributions(where: $where, orderBy: $orderBy) {
      step
      stepIndex
      intent
      count
      countCorrected
      countPredictedCorrect
      countPredictedIncorrect
    }
  }
`

export const TRAINERSESSIONTREND_QUERY = gql`
  query TrainerSessionTrend($testSessionId: ID!) {
    trainersessiontrend(testSessionId: $testSessionId) {
      previousTestSessionId
      previousTestSessionName
      nextTestSessionId
      nextTestSessionName
    }
  }
`
