import React from 'react'
import {connect} from 'react-redux'
import {Form} from 'react-final-form'

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import {Mutation, compose, graphql, Query, withApollo} from 'react-apollo'
import {OnChange} from 'react-final-form-listeners'

// core components
import Button from 'components/Button/Button'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import Text from 'components/Typography/Text.jsx'
import Table from 'components/Table/AdvancedTable.jsx'
import Card from 'components/Card/Card.jsx'
import CardHeader from 'components/Card/CardHeader.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import Field from 'components/Form/OptionalField'
import {
  renderTextField,
  renderSelect,
  required,
  FormActionsToolbar, url, renderFileUpload
} from 'components/Form/Form'
import ShowIcon from 'components/Icon/ShowIcon'
import QueryStatus from 'components/Info/QueryStatus'
import LoadingIndicator from 'components/Icon/LoadingIndicator'
import Divider from 'components/Divider/Divider'
import ErrorFormat from 'components/Info/ErrorFormat'
import LinkButton from 'components/Button/LinkButton'
import LastChange from 'components/Info/LastChange'
import ConfirmationDialog from 'components/Dialog/ConfirmationDialog'

import testsetsStyle from 'assets/jss/material-dashboard-react/views/testsetsStyle.jsx'

import {setAlertSuccessMessage, setAlertErrorMessage} from 'actions/alert'
import {renderFactCheckerIndexStatus} from '../LLMprojects/LLMprojectsHelper'
import {RefetchTestSetQueries} from '../TestSets/gql'
import FileBrowser from '../Settings/FileBrowser'
import {safeGetNamespaceFilteredList} from '../helper'

import {
  FACTCHECKERPROJECTS_DROPDOWN_QUERY,
  FACTCHECKERPROJECT_QUERY, FACTCHECKERINDEXSESSION_PROGRESS_SUBSCRIPTION, SOURCE_OF_TRUTH_WIZARD
} from '../LLMprojects/gql'


class SourceOfTruthWizard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      testSetNameAutomatical: null,
      indexSessionProgress: {},
      indexSessionText: '',
      emptySourceOfTruthWizard: {
        factCheckerProjectId: 'NO_PROJECT'
      }
    }
  }

  renderIndexSessions() {
    const {indexSessionProgress} = this.state
    const items = Object.values(indexSessionProgress)
    if (!items.length) {
      return
    }
    return <>
      <Card>
        <CardHeader color="info">
          <Text header>Recent Test Results</Text>
          <Text subheader>Recent Test Session Results of this Chatbot</Text>
        </CardHeader>
        <CardBody>
          <Table
            tableHeaderColor="primary"
            tableHead={[
              {name: 'TestSet', width: 'medium'},
              {name: 'Started'},
              {name: 'Status'},
              ''
            ]}
            name={`SourceOfTruthWizardIndexSessions`}
            disableFilter
            tableData={items.map(item => {
              return [
                {
                  value: item.testSet.name,
                  href: `/testsets/view/${item.testSet.id}/dashboard`,
                  width: 160
                },
                () => <LastChange {...item} />,
                () => renderFactCheckerIndexStatus(item),
                () => <>
                  {item.log &&
                    <LinkButton onClick={() => this.setState({indexSessionText: item.log})}>Show Log</LinkButton>}
                  {item.err && <> | <LinkButton onClick={() => this.setState({indexSessionText: item.err})}>Show
                    Err</LinkButton></>}
                </>,
              ]
            })}
          />
          <ConfirmationDialog
            open={!!this.state.indexSessionText}
            onOk={() => this.setState({indexSessionText: ''})}
            title="FactCheck Index Session"
          >
            <Text pre>{this.state.indexSessionText || ''}</Text>
          </ConfirmationDialog>
        </CardBody>
      </Card>
    </>
  }

  subscribeToIndexSessionProgress(id) {
    const {client} = this.props
    const {indexSessionProgress} = this.state
    const setState = (s) => this.setState(s)

    const subscription = client
      .subscribe({
        query: FACTCHECKERINDEXSESSION_PROGRESS_SUBSCRIPTION,
        variables: {id}
      })
      .subscribe({
        next(s) {
          indexSessionProgress[s.data.factCheckerIndexSessionProgress.id] = s.data.factCheckerIndexSessionProgress
          setState(indexSessionProgress)

          if (s.data && s.data.factCheckerIndexSessionProgress && (['READY', 'FAILED'].includes(s.data.factCheckerIndexSessionProgress.status))) {
            // setAlertSuccessMessage('FactCheck Ready for Use')
            subscription.unsubscribe()
          }
        }
      })
  }

  render() {
    const {factCheckerProjectsData, setAlertErrorMessage, classes} = this.props
    const {testSetNameAutomatical, emptySourceOfTruthWizard, indexSessionProgress} = this.state

    return (<GridContainer>
      <QueryStatus queries={[factCheckerProjectsData]} query={['factcheckerprojects']}>{({factcheckerprojects}) => {

        return <Mutation
          mutation={SOURCE_OF_TRUTH_WIZARD}
          refetchQueries={() => {
            return [
              ...RefetchTestSetQueries()
            ]
          }}
        >
          {(mutation, {loading, error}) => (
            <Form
              onSubmit={async (values, form) => {
                try {
                  const res = await mutation({
                    variables: {
                      factCheckerProjectId: values.factCheckerProjectId !== 'NO_PROJECT' ? values.factCheckerProjectId : null,
                      testSetName: values.testSetName,
                      files: values.filename ? [{
                        filename: values.filename,
                        filecontent: values.filecontent
                      }] : [],
                      urls: values.url ? [values.url] : []
                    }
                  })

                  form.initialize(emptySourceOfTruthWizard)
                  this.setState({
                    indexSessionProgress: {
                      ...this.state.indexSessionProgress,
                      [res.data.sourceOfTruthWizard.id]: res.data.sourceOfTruthWizard
                    }
                  })
                  this.subscribeToIndexSessionProgress(res.data.sourceOfTruthWizard.id)
                } catch (error) {
                  setAlertErrorMessage(`Source of Truth wizard failed`, error)
                }
              }}
              initialValues={emptySourceOfTruthWizard}
              render={({form, handleSubmit, submitting, invalid, values, form: {change}}) => (
                <form onSubmit={handleSubmit}>
                  <GridContainer>

                    {!!Object.keys(indexSessionProgress).length && <GridItem xs={12}>
                      <Divider/>
                    </GridItem>}
                    <GridItem md={6}>
                      <Field
                        name="factCheckerProjectId"
                        component={renderSelect}
                        label="Select a Fact Checker Project"
                        data-unique={`selSourceOfTruthWizardRegisterProject`}
                        disabled={values.filename || values.url}
                        defaultValueInit={'NO_PROJECT'}
                        items={[{
                          key: 'NO_PROJECT',
                          label: 'Choose a Project'
                        }, ...(safeGetNamespaceFilteredList(factcheckerprojects, this.props.namespace).map(p => {
                          return {
                            key: p.id,
                            label: p.name,
                            value: p
                          }
                        }))]}
                      />
                      <OnChange name="factCheckerProjectId">
                        {async (value) => {
                          if (value && value !== 'NO_PROJECT' && (!values.testSetName || values.testSetName === testSetNameAutomatical)) {
                            const testProjectName = factcheckerprojects.find(p => p.id === value).name
                            if (testProjectName) {
                              this.setState({testSetNameAutomatical: testProjectName})
                              change('testSetName', testProjectName)
                            }
                          }
                        }}
                      </OnChange>
                    </GridItem>
                    <GridItem md={6}>
                      <Field
                        name="testSetName"
                        component={renderTextField}
                        placeholder="Enter Test Set Name"
                        label="New Test Set Name"
                        validate={required}
                        data-unique={`selSourceOfTruthWizardTestSetName`}
                      />
                    </GridItem>

                    <GridItem md={12}>
                      {values.factCheckerProjectId && values.factCheckerProjectId !== 'NO_PROJECT' && <>
                        <Divider/>

                        <Query query={FACTCHECKERPROJECT_QUERY} variables={{id: values.factCheckerProjectId}}>
                          {({loading, error, data}) => {
                            if (loading) {
                              return <LoadingIndicator large/>
                            }
                            if (error) {
                              return <ErrorFormat err={error}/>
                            }
                            return <>
                              <GridItem xs={12}>
                                <Text submitting bottomMarginsm bold500 labelText>Included Files</Text>
                              </GridItem>
                              <GridItem xs={12}>
                                <FileBrowser disableFileUpload
                                             disableAudioRecording
                                             disableToolbar
                                             disableServerChanges
                                             currentPath={data.factcheckerproject.documentBaseDir}
                                             basePath={data.factcheckerproject.documentBaseDir}
                                />
                              </GridItem>
                            </>
                          }}
                        </Query>

                      </>}
                    </GridItem>
                    <GridItem md>
                      <Divider text="or" orientation="horizontal"/>
                    </GridItem>
                    <GridItem md={12}>
                      <Field
                        name="url"
                        component={renderTextField}
                        label="Download source of truth"
                        validate={url}
                        disabled={values.factCheckerProjectId && values.factCheckerProjectId !== 'NO_PROJECT'}
                        data-unique="txFactCheckerProjectRegisterUrl"
                        helperText="Botium FactCheck will download the source of truth from this website"
                      />
                    </GridItem>
                    <GridItem md={12}>
                      <Field
                        name="fileupload"
                        component={renderFileUpload}
                        data-unique={`uploadFactCheckerProjectRegisterFileupload`}
                        disabled={values.factCheckerProjectId && values.factCheckerProjectId !== 'NO_PROJECT'}
                        label={
                          <span
                            className={values.factCheckerProjectId && values.factCheckerProjectId !== 'NO_PROJECT' ? classes.customFileUploadTitleDisabled : null}>
                            {'Upload source of truth (.pdf, .docx, .html, .txt) - max. 50 MB per file allowed'}
                          </span>
                        }
                        values={values}
                        change={change}
                        accept=".txt,.pdf,.html,.docx,.doc"
                        processingFiles={submitting}
                        onFileLoaded={(filename, filecontent) => {
                          if (!values.testSetName || values.testSetName === testSetNameAutomatical) {
                            const testSetName = filename.split('.').slice(0, -1).join('.')
                            this.setState({testSetNameAutomatical: testSetName})
                            change('testSetName', testSetName)
                          }

                          change('filename', filename)
                          change('filecontent', filecontent)
                        }}
                      />
                    </GridItem>
                    <GridItem md={12} largePadding>
                      <FormActionsToolbar rightButtons={
                        <Button
                          type="submit"
                          disabled={submitting || invalid}
                          data-unique="btnTestSetDownloadTestSetAndHumanification"
                        >
                          <><ShowIcon icon="play-circle"/>Start</>
                        </Button>
                      }/>
                    </GridItem>
                    <GridItem md={12}>
                      {!!Object.keys(indexSessionProgress).length && this.renderIndexSessions()}
                    </GridItem>
                  </GridContainer>
                </form>
              )}
            />
          )}
        </Mutation>
      }}</QueryStatus>
    </GridContainer>)
  }
}

export default compose(
  withApollo,
  withStyles(testsetsStyle),
  connect(
    state => ({user: state.token.user, license: state.settings.license, settings: state.settings}),
    {setAlertSuccessMessage, setAlertErrorMessage},
  ),
  graphql(FACTCHECKERPROJECTS_DROPDOWN_QUERY, {
    props: ({data}) => ({
      factCheckerProjectsData: data,
    })
  }),
)(SourceOfTruthWizard)
