import React from 'react'
import { connect } from 'react-redux'
import download from 'downloadjs'
import { NavLink } from 'react-router-dom'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import { Form } from 'react-final-form'
import Field from 'components/Form/OptionalField'
import { OnChange } from 'react-final-form-listeners'
import RefreshIcon from '@material-ui/icons/Refresh'
// apollo
import { Query, Mutation, withApollo } from 'react-apollo'
import { gql } from 'apollo-boost'
// core components
import Button from 'components/Button/Button'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import Chip from 'components/Chip/Chip'
import { renderTextField, renderCheckbox, renderPasswordField, renderCodeArea, required, json, url, composeValidators, prettyPrintJson, FormActionsToolbar } from 'components/Form/Form'
import { setAlertSuccessMessage, setAlertErrorMessage } from 'actions/alert'
import ErrorFormat from 'components/Info/ErrorFormat'
import UnsavedFormSpy from 'components/Form/UnsavedFormSpy'

import ShowIcon from 'components/Icon/ShowIcon'

import testsetsStyle from 'assets/jss/material-dashboard-react/views/testsetsStyle.jsx'

import { RefetchTestSetQueries } from './gql'
import { getShortenedArray } from './helper'

import { hasAnyPermission } from 'botium-box-shared/security/permissions'
import Text from 'components/Typography/Text'
import LoadingIndicator from 'components/Icon/LoadingIndicator'

const FULL_TESTSETDOWNLOADLINK_FRAGMENT = gql`
  fragment FullTestSetDownloadLink on TestSetDownloadLink {
    id
    createdAt
    updatedAt
    name
    url
    headers
    skip
    testSet {
      id
      name
    }
  }
`

const TESTSETDOWNLOADLINK_QUERY = gql`
  query TestSetDownloadLinkQuery($id: ID!) {
    testsetdownloadlink(id: $id) {
      ...FullTestSetDownloadLink
    }
  }
  ${FULL_TESTSETDOWNLOADLINK_FRAGMENT}
`

const TESTSETDOWNLOADLINKCONTENT_QUERY = gql`
  query TestSetDownloadLinkContentQuery($id: ID!) {
    testsetdownloadlinkcontent(id: $id) {
      filename contentType content
    }
  }
`

const CREATE_TESTSETDOWNLOADLINK = gql`
  mutation CreateTestSetDownloadLink($testSetDownloadLink: TestSetDownloadLinkCreateInput!) {
    createTestSetDownloadLink(testSetDownloadLink: $testSetDownloadLink) {
      ...FullTestSetDownloadLink
    }
  }
  ${FULL_TESTSETDOWNLOADLINK_FRAGMENT}
`

const UPDATE_TESTSETDOWNLOADLINK = gql`
  mutation UpdateTestSetDownloadLink(
    $id: ID!
    $testSetDownloadLink: TestSetDownloadLinkUpdateInput!
  ) {
    updateTestSetDownloadLink(id: $id, testSetDownloadLink: $testSetDownloadLink) {
      ...FullTestSetDownloadLink
    }
  }
  ${FULL_TESTSETDOWNLOADLINK_FRAGMENT}
`

const DELETE_TESTSETDOWNLOADLINK = gql`
  mutation DeleteTestSetDownloadLink($id: ID!) {
    deleteTestSetDownloadLink(id: $id)
  }
`

const VALIDATEDOWNLOADLINK_QUERY = gql`
  query ValidateDownloadlinkQuery(
    $testSetId: ID!
    $url: String!
    $headers: String
  ) {
    validatedownloadlink(testSetId: $testSetId, url: $url, headers: $headers) {
      convoCount
      convoNames
      partialConvoCount
      partialConvoNames
      uttCount
      uttNames
      scriptingMemoryCount
      scriptingMemoryNames
      err
    }
  }
`

class TestSetDownloadLink extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      downloadRunning: false,
      validateResult: null,
      fileContentLoading: false
    }
  }

  hasWritePermission() {
    const { user } = this.props
    return hasAnyPermission(user, ['TESTSETS_CREATE', 'TESTSETS_UPDATE'])
  }

  tryLoadFileContent(testSetId, url, headers) {
    const { client } = this.props

    if (url) {
      this.setState({fileContentLoading: true})
      client.query({
        query: VALIDATEDOWNLOADLINK_QUERY,
        variables: {
          testSetId,
          url,
          headers
        }
      })
        .then(({data}) => {
          this.setState({validateResult: data.validatedownloadlink, fileContentLoading: false})
        })
        .catch(err => {
          this.setState({validateResult: {err: err.message}, fileContentLoading: false})
        })
    } else {
      this.setState({validateResult: {err: 'URL not set'}})
    }
  }

  _isGenericUrl(url) {
    return !this._isGoogleSheet(url) && !this._isSharepoint(url)
  }

  _isGoogleSheet(url) {
    return url && url.startsWith('https://docs.google.com/spreadsheets/d/')
  }

  _isSharepoint(url) {
    return url && url.indexOf('.sharepoint.com/') > 0
  }

  renderForm(testsetDownloadLink, testSetId) {
    const { setAlertSuccessMessage, setAlertErrorMessage, history, license } = this.props
    const { validateResult, fileContentLoading } = this.state

    return (
      <Mutation
        mutation={
          testsetDownloadLink.id ? UPDATE_TESTSETDOWNLOADLINK : CREATE_TESTSETDOWNLOADLINK
        }
        refetchQueries={[
          ...RefetchTestSetQueries(testSetId, license)
        ]}
      >
        {(mutateTestSetDownloadLink, { loading, error }) => (
          <Form
            onSubmit={async (values, form) => {
              if (testsetDownloadLink.id) {
                try {
                  const res = await mutateTestSetDownloadLink({
                    variables: {
                      id: values.id,
                      testSetDownloadLink: {
                        name: values.name,
                        url: values.url,
                        headers: values.headers || null,
                        skip: !!values.skip
                      },
                    },
                  })
                  form.initialize(res.data.updateTestSetDownloadLink)
                  history.push(`/testsets/view/${testSetId}/settings/remote/viewdownloadlink/${res.data.updateTestSetDownloadLink.id}`)
                  setAlertSuccessMessage('Download Link ready for use')
                } catch(error) {
                  setAlertErrorMessage(`Download Link update failed`, error)
                }
              } else {
                try {
                  const res = await mutateTestSetDownloadLink({
                    variables: {
                      testSetDownloadLink: {
                        name: values.name,
                        url: values.url,
                        headers: values.headers || null,
                        skip: !!values.skip,
                        testSet: {
                          connect: {
                            id: testSetId,
                          },
                        },
                      },
                    },
                  })
                  form.initialize(res.data.createTestSetDownloadLink)
                  history.push(`/testsets/view/${testSetId}/settings/remote/viewdownloadlink/${res.data.createTestSetDownloadLink.id}`)
                  setAlertSuccessMessage('Download Link ready for use')
                }catch(error) {
                  setAlertErrorMessage(`Download Link registration failed`, error)
                }
              }
            }}
            initialValues={testsetDownloadLink}
            render={({
              handleSubmit,
              submitting,
              invalid,
              values,
              form: {
                change
              }
            }) => (
              <form onSubmit={handleSubmit}>
                <UnsavedFormSpy />
                <GridContainer>
                  <GridItem xs={12} sm={6}>
                    <Field
                      name="name"
                      component={renderTextField}
                      label="Download Link Name"
                      validate={required}
                      disabled={!this.hasWritePermission()}
                      data-unique="txtTestSetDownloadLinkName"
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <Field
                      name="skip"
                      component={renderCheckbox}
                      label="Ignore when running Test Sessions"
                      type="checkbox"
                      disabled={!this.hasWritePermission()}
                      data-unique="chkTestSetDownloadLinkSkip"
                    />                    
                  </GridItem>
                  <GridItem xs={12}>
                    <Field
                      name="url"
                      component={renderTextField}
                      label="Download Link or Google Sheets Link"
                      validate={composeValidators(required, url)}
                      disabled={!this.hasWritePermission()}
                      data-unique="txtTestSetDownloadLinkUrl"
                    />
                  </GridItem>
                  <OnChange name="url">
                    {async (value, previous) => {
                      if (value && !this._isGoogleSheet(value)) {
                        if (!values.name) {
                          try {
                            const url = new URL(value)
                            const pathComponents = url.pathname.split('/').filter(s => s)
                            if (pathComponents && pathComponents.length > 0) {
                              const lastPathComponent = pathComponents[pathComponents.length - 1]
                              if (lastPathComponent) change('name', lastPathComponent)
                            }
                          } catch (err) {}
                        }
                      }
                    }}
                  </OnChange>
                  <GridItem xs={12}>
                    {this._isGenericUrl(values.url) && <Text info>You can add authorization headers here and any other HTTP headers the download server requires. For HTTP Basic Authentication, use the input fields below to generate the authorization header.</Text>}
                    {this._isGoogleSheet(values.url) && <Text info>Create a Google Cloud project with an IAM service account, download credentials as JSON and paste here. Assign read permissions to the spreadsheet for the client_email from the service account credentials. See <a href="https://support.botium.ai/hc/en-us/articles/10736115595919-Can-I-use-Google-Sheets-to-manage-my-test-cases" target="_blank" rel="noopener noreferrer">Knowledge Center</a>.</Text>}
                    {this._isSharepoint(values.url) && <Text info>Create a Sharepoint App Registration (see <a href="https://docs.microsoft.com/en-us/sharepoint/dev/solution-guidance/security-apponly-azureacs" target="_blank" rel="noopener noreferrer">here</a>) and add JSON with clientId and clientSecret attributes here. Assign read permissions.</Text>}
                  </GridItem>
                  {this.hasWritePermission() && this._isGenericUrl(values.url) &&
                    <GridItem xs={8}>
                      <GridContainer>
                        <GridItem xs={4}>
                          <Field
                            name="username"
                            component={renderTextField}
                            label="Username"
                            disabled={!this.hasWritePermission()}
                            data-unique="txtTestSetDownloadLinkUsername"
                          />
                        </GridItem>
                        <GridItem xs={4}>
                          <Field
                            name="password"
                            component={renderPasswordField}
                            label="Password"
                            disabled={!this.hasWritePermission()}
                            data-unique="pwTestSetDownloadLinkPassword"
                          />
                        </GridItem>
                        <GridItem xs={4} bottom>
                          <Button secondary data-unique="btnTestSetDownloadLinkAddBasicAuthHeaders" onClick={() => {
                            let headers = {}
                            if (values.headers) {
                              try {
                                headers = JSON.parse(values.headers)
                              } catch (err) {}
                            }
                            headers.Authorization = `Basic ${btoa(`${values.username}:${values.password}`)}`
                            change('headers', JSON.stringify(headers, null, 2))
                          }}>
                            Set Basic Auth Headers
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  }
                  {this.hasWritePermission() && this._isSharepoint(values.url) &&
                    <GridItem xs={8}>
                      <GridContainer>
                        <GridItem xs={4}>
                          <Field
                            name="clientId"
                            component={renderTextField}
                            label="Sharepoint App Client Id"
                            disabled={!this.hasWritePermission()}
                            data-unique="txtTestSetDownloadLinkClientId"
                          />
                        </GridItem>
                        <GridItem xs={4}>
                          <Field
                            name="clientSecret"
                            component={renderTextField}
                            label="Sharepoint App Client Secret"
                            disabled={!this.hasWritePermission()}
                            data-unique="pwTestSetDownloadLinkClientSecret"
                          />
                        </GridItem>
                        <GridItem xs={4} bottom>
                          <Button secondary data-unique="btnTestSetDownloadLinkAddSharepoint" onClick={() => {
                            let headers = {}
                            if (values.headers) {
                              try {
                                headers = JSON.parse(values.headers)
                              } catch (err) {}
                            }
                            if (values.clientId) headers.clientId = values.clientId
                            if (values.clientSecret) headers.clientSecret = values.clientSecret
                            change('headers', JSON.stringify(headers, null, 2))
                          }}>
                            Set Sharepoint Client Headers
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  }
                  <GridItem xs={12}>
                    <Field
                      className="CapabilitiesShort"
                      name="headers"
                      component={renderCodeArea}
                      options={{ mode: 'application/json' }}
                      label={this._isGoogleSheet(values.url) ? 'Google Service Account Credentials (as JSON)' : this._isSharepoint(values.url) ? 'Sharepoint clientId and clientSecret (as JSON)' : 'HTTP Headers (as JSON)'}
                      codeFormat={prettyPrintJson}
                      validate={json}
                      disabled={!this.hasWritePermission()}
                      data-unique="codeTestSetDownloadLinkHeaders"
                    />
                  </GridItem>
                  {validateResult &&
                    <GridItem xs={12}>
                      {validateResult.convoCount > 0 && <Chip data-unique="lblConvoCount" variant="convo" label="Convos" badge={validateResult.convoCount} tooltip={`${validateResult.convoCount} Convos: ${getShortenedArray(validateResult.convoNames, 10).join(' | ')}`} />}
                      {validateResult.partialConvoCount > 0 && <Chip data-unique="lblPartialConvoCount" variant="convo" label="Partial Convos" badge={validateResult.convoCount} tooltip={`${validateResult.partialConvoCount} Partial Convos: ${getShortenedArray(validateResult.partialConvoNames, 10).join(' | ')}`} />}
                      {validateResult.uttCount > 0 && <Chip data-unique="lblUttCount" variant="utterance" label="Utterance Lists" badge={validateResult.uttCount} tooltip={`${validateResult.uttCount} Utterance Lists: ${getShortenedArray(validateResult.uttNames, 10).join(' | ')}`} />}
                      {validateResult.scriptingMemoryCount > 0 && <Chip data-unique="lblScriptingMemoryCount" variant="scripting" label="Test Parameter Stores" badge={validateResult.scriptingMemoryCount} tooltip={`${validateResult.scriptingMemoryCount} Test Parameter Stores: ${getShortenedArray(validateResult.scriptingMemoryNames, 10).join(' | ')}`} />}
                      {validateResult.convoCount === 0 && validateResult.partialConvoCount === 0 && validateResult.uttCount === 0 && validateResult.scriptingMemoryCount === 0 &&
                        <Text danger data-unique="txtTestSetDownloadLinkValidateEmpty">No Botium content identified. Please check the <NavLink to={`/testsets/view/${testSetId}/settings/excel`}>worksheet names and the table structure!</NavLink></Text>
                      }
                      {validateResult.err && <Text danger data-unique="txtTestSetDownloadLinkValidateErr">{validateResult.err}</Text>}
                    </GridItem>
                  }
                  <GridItem xs={12} largePadding>
                    <FormActionsToolbar
                      leftButtonsXs={8} rightButtonsXs={4}
                      leftButtons={<>
                        {testsetDownloadLink.id &&
                          <Button
                            secondary
                            onClick={() => this.download(testsetDownloadLink.id)}
                            disabled={this.state.downloadRunning}
                            data-unique="btnTestSetDownloadLinkDownload"
                          >
                            {this.state.downloadRunning && <><LoadingIndicator alt /> Downloading ...</>}
                            {!this.state.downloadRunning && <><ShowIcon icon="cloud-download-alt" /> Download</>}
                          </Button>
                        }
                        <Button
                          secondary
                          onClick={() => this.tryLoadFileContent(testSetId, values.url, values.headers)}
                          disabled={!values.url || fileContentLoading}
                          data-unique="btnTestSetDownloadLinkFetchFileContent"
                        >
                          {fileContentLoading && <><LoadingIndicator alt /> Analyzing file content...</>}
                          {!fileContentLoading && <><RefreshIcon /> Analyze file content</>}
                        </Button>
                        {testsetDownloadLink.id && this.hasWritePermission() && (
                          <Mutation
                            mutation={DELETE_TESTSETDOWNLOADLINK}
                            onCompleted={data => {
                              this.props.history.push(`/testsets/view/${testSetId}/settings/remote`)
                              setAlertSuccessMessage('Download Link deleted')
                            }}
                            onError={error => {
                              setAlertErrorMessage(
                                `Download Link deletion failed`,
                                error,
                              )
                            }}
                            refetchQueries={[
                              ...RefetchTestSetQueries(testSetId, license)
                            ]}
                          >
                            {(deleteTestSetDownloadLink, { loading, error }) => (
                              <Button
                                secondary
                                danger
                                onClick={() => {
                                  deleteTestSetDownloadLink({
                                    variables: { id: testsetDownloadLink.id },
                                  })
                                }}
                                data-unique="btnTestSetDownloadLinkUnregister"
                              >
                                <ShowIcon icon="trash" />
                                Delete Download Link
                              </Button>
                            )}
                          </Mutation>
                        )}                        
                      </>}
                      rightButtons={this.hasWritePermission() &&
                        <Button
                          type="submit"
                          disabled={invalid || submitting}
                          data-unique="btnTestSetDownloadLinkSave"
                        >
                          {submitting && <LoadingIndicator alt />}
                          {!submitting && <ShowIcon icon="save" />}                        
                          Save
                        </Button>
                      }
                    />
                  </GridItem>
                </GridContainer>
              </form>
            )}
          />
        )}
      </Mutation>
    )
  }

  async download(id) {
    const { setAlertSuccessMessage, setAlertErrorMessage, client } = this.props

    this.setState({ downloadRunning: true })
    try {
      const { data } = await client.query({
        query: TESTSETDOWNLOADLINKCONTENT_QUERY,
        variables: { id },
        fetchPolicy: 'network-only'
      })
      download(atob(data.testsetdownloadlinkcontent.content), data.testsetdownloadlinkcontent.filename, data.testsetdownloadlinkcontent.contentType)
      setAlertSuccessMessage('Downloading file ready')
    } catch (err) {
      setAlertErrorMessage('Downloading file failed', err)
    }
    this.setState({ downloadRunning: false })
  }

  render() {
    const { match } = this.props
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          {match.params &&
            match.params.id && (
              <Query
                query={TESTSETDOWNLOADLINK_QUERY}
                variables={{ id: match.params.id }}
              >
                {({ loading, error, data }) => {
                  if (loading) return <LoadingIndicator />
                  if (error) return <ErrorFormat err={error} />

                  return this.renderForm(
                    data.testsetdownloadlink,
                    data.testsetdownloadlink.testSet.id,
                  )
                }}
              </Query>
            )}
          {(!match.params || !match.params.id) &&
            this.renderForm({ gitbranch: 'master' }, match.params.testSetId)}
        </GridItem>
      </GridContainer>
    )
  }
}

export default connect(
  state => ({ user: state.token.user, license: state.settings.license }),
  { setAlertSuccessMessage, setAlertErrorMessage },
)(withStyles(testsetsStyle)(withApollo(TestSetDownloadLink)))
