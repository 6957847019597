import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import ShowIcon from 'components/Icon/ShowIcon'
import {
  warningColor,
  infoColor
} from 'assets/jss/material-dashboard-react.jsx'
import Text from '../Typography/Text'

function MessageBox({ variant, title, text, link, iconComponent, classes, ...props }) {

  variant = variant ? variant : 'warning'

  const getIcon = () => {
    if(variant === 'warning') return 'exclamation-triangle'
    if(variant === 'warninginline') return 'exclamation-triangle'
    if(variant === 'info') return 'question-circle'
    if(variant === 'infoblue') return 'question-circle'
  }

  const renderTitle = () => {
    if (variant === 'warning') return <Text bold><strong>{title}</strong></Text>
    if (variant === 'warninginline') return <Text warning><strong>{title}</strong></Text>
    if (variant === 'info') return <Text info><strong>{title}</strong></Text>
    if (variant === 'infoblue') return <Text info><strong>{title}</strong></Text>
    if (variant === 'process') return <Text info><strong>{title}</strong></Text>
    if (variant === 'failed') return <Text info><strong>{title}</strong></Text>
  }

  const getContainerClass = () => {
    if (variant === 'warning') return classes.messageBoxContainerWarning
    if (variant === 'warninginline') return classes.messageBoxContainerWarningInline
    if (variant === 'info') return classes.messageBoxContainerInfo
    if (variant === 'infoblue') return classes.messageBoxContainerInfoBlue
    if (variant === 'process') return classes.messageBoxContainerProcess
    if (variant === 'failed') return classes.messageBoxContainerFailed
  }

  const getIconClass = () => {
    if (variant === 'warning') return classes.messageBoxIconWarning
    if (variant === 'warninginline') return classes.messageBoxIconWarning
    if (variant === 'info') return classes.messageBoxIconInfo
    if (variant === 'infoblue') return classes.messageBoxIconInfoBlue
    if (variant === 'process') return classes.messageBoxIconProcess
  }

  return <div className={classes.messageBoxContainer + ' ' + getContainerClass()}>
    {iconComponent || <ShowIcon icon={getIcon()} size="lg" className={classes.messageBoxIcon + ' ' + getIconClass()} />}
    <div style={{width: 'auto%', textAlign: 'left', margin: 10 }}>
      {renderTitle()}
      <Text>{text}</Text>
    </div>
    <div style={{float: 'right', margin: '10px 10px 10px 40px'   }}>
      <Text >{link}</Text>
    </div>
  </div>
}

export default withStyles(theme => ({
  messageBoxContainer: {
    borderRadius: 5,
    borderWidth: 2,
    borderStyle: 'solid',
    padding: 0,
    display: 'flex', 
    alignItems: 'center', 
    marginTop: 15,
    marginBottom: 21,
  },
  messageBoxIcon: {
    margin: 10
  },
  messageBoxContainerWarning: {
    borderColor: warningColor
  },
  messageBoxContainerWarningInline: {
    borderColor: warningColor,
    display: 'inline-flex'
  },
  messageBoxContainerInfo: {
    borderColor: infoColor
  },
  messageBoxContainerInfoBlue: {
    borderColor: theme.colors.borderColor
  },
  messageBoxIconWarning: {
    color: warningColor
  },
  messageBoxIconInfo: {
    color: infoColor,
  },
  messageBoxIconInfoBlue: {
    color: theme.colors.primary,
  },
  messageBoxIconProcess: {
    color: warningColor,
    animation: 'spin 4s ease 1s infinite normal forwards',
  },
  messageBoxContainerProcess: {
    borderColor: 'transparent',
    marginTop: 21,
  },
  messageBoxContainerFailed: {
    borderColor: 'transparent',
    marginTop: 21
  },

  

}))(MessageBox)
