import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
// apollo
import { compose, graphql } from 'react-apollo'
// core components
import ServerSidePagingTable from 'components/Table/ServerSidePagingTable.jsx'
import DropdownButton from 'components/Button/DropdownButton'
import { setAlertSuccessMessage, setAlertErrorMessage } from 'actions/alert'

import {
  START_FACTCHECKERPROJECT,
  START_FACTCHECKERPROJECTS,
  FACTCHECKERPROJECTS_PAGINATED_QUERY,
  FACTCHECKERPROJECTS_COUNT_QUERY,
  DeleteFactCheckerListsFromCache
} from './gql'

import { RefetchTestSessionQueries, DeleteTestSessionListsFromCache } from '../TestSessions/gql'

import { hasPermission, canReadNamespace, canWriteNamespace } from 'botium-box-shared/security/permissions'

import testsessionsStyle from 'assets/jss/material-dashboard-react/views/testsessionsStyle.jsx'
import { getConnector } from 'actions/settings'
import { isDarkmode } from 'components/Darkmode/helper'
import DateFormat from 'components/Info/DateFormat'
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min'
import {NewResultStats} from '../TestSessions/helper'
import { TestSessionType } from 'views/TestSessions/helper.jsx'
import { NewResultIcon }  from 'views/TestSessions/helper.jsx'
import Text from 'components/Typography/Text'
import ShowIcon from 'components/Icon/ShowIcon'

class FactCheckerProjectsEmbeddedTable extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      selectedItems: [],
      newTestSessionCount: 0
    }
  }

  handleSelection(id) {
    const { selectedItems } = this.state
    const index = selectedItems.indexOf(id)
    if (index > -1) {
      selectedItems.splice(index, 1)
    } else {
      selectedItems.push(id)
    }
    this.setState({ selectedItems })
  }

  render() {
    const { classes, name, user, mutateStartFactCheckerProject, mutateStartFactCheckerProjects, history, namespace, ...rest } = this.props

    const _gqlVariables = (filterString) => {
      filterString = filterString && filterString.replace('_', '\\_')
      const where = {}
      if (filterString) {
        where.name = filterString
      }
      if (namespace?.selected?.name) {
        where.namespace = namespace?.selected?.name
      }
      return { where }
    }

    const _link = (ts) => {
      return `/factcheck/projects/view/${ts.factCheckerSession.project.id}/results/${ts.id}`
    }



    return <ServerSidePagingTable
      className={classes.projectTable}
      key={`${name}_${namespace?.selected?.name}_${isDarkmode() ? 'dark' : 'light'}_${this.state.newTestSessionCount}`}
      name={name}
      gqlQuery={{
        query: FACTCHECKERPROJECTS_PAGINATED_QUERY,
        notifyOnNetworkStatusChange: true
      }}
      rowClassName={(row) => 'projectTable'}
      gqlCountQuery={{
        query: FACTCHECKERPROJECTS_COUNT_QUERY,
        countValue: data => data.factcheckerprojectsCount
      }}
      gqlVariables={(filterString) => _gqlVariables(filterString)}
      tableHeaderColor="primary"
      noDataAllComponent="Get started creating test will be displayed on this page."
      tableHead={[
        { name: 'Project Name', orderByField: 'name', orderByDefault: 'updatedAt', },
        { name: 'Actions', right: true },
        { name: 'Latest Test', orderByField: 'name', orderByDefault: 'updatedAt',},
        ' ',
        ' ',

      ]}
      mapSelectIds={data => data && data.factcheckerprojectswithlastfactcheckersession && data.factcheckerprojectswithlastfactcheckersession.map(t => t.id)}
      onSelectionChange={selectedItems => this.setState({ selectedItems })}
      customActionsRight={
        <DropdownButton
          className={classes.dropdownButtonPosition}
          customSelectType
          disabled={this.state.selectedItems.length === 0}
          data-unique="btnFactCheckerProjectsBulkAction"
          items={[
            {
              id: 'start',
              icon: 'play',
              name: 'Start FactCheck Test Sessions',
              dataUnique: 'btnFactCheckerProjectsBulkActionStart',
              onClick: async () => {
                await mutateStartFactCheckerProjects({
                  variables: {factCheckerProjectIds: this.state.selectedItems, debug: false}
                })
                this.setState({newTestSessionCount: this.state.newTestSessionCount + 1})
              }
            },
            {
              id: 'start_debug',
              icon: 'bug',
              name: 'Start FactCheck Test Sessions (Extended Logging)',
              dataUnique: 'btnFactCheckerProjectsBulkActionStartDebug',
              onClick: async () => {
                await mutateStartFactCheckerProjects({
                  variables: {factCheckerProjectIds: this.state.selectedItems, debug: true}
                })
                this.setState({newTestSessionCount: this.state.newTestSessionCount + 1})
              }
            },
          ]}
        >
          <ShowIcon icon="redo" /> Bulk Action
        </DropdownButton>}

      tableData={data =>
        data.factcheckerprojectswithlastfactcheckersession &&
        data.factcheckerprojectswithlastfactcheckersession.map((t, tIndex) => [
          {
            value: <>
              <div><NavLink className={classes.projectLink} to={`/factcheck/projects/view/${t.id}/dashboard`}>{t.name}</NavLink></div>
              {t.namespace ? <NavLink to={`/namespaces/${t.namespace}`}><div className={classes.namespace}>{t.namespace}</div></NavLink> : <div className={classes.namespaceempty}></div>}
              </>
          },
          () => <DropdownButton
            dots
            aria-label="Actions"
            tooltipTitle="Actions"
            items={[
              {
                id: 'open',
                icon: 'infinity',
                name: 'Open',
                onClick: () => history.push(`/factcheck/projects/view/${t.id}/dashboard`)
              },
              canReadNamespace(user, user.namespacePermissions, t.namespace) && {
                id: 'settings',
                icon: 'cog',
                name: 'Configuration',
                onClick: () => history.push(`/factcheck/projects/view/${t.id}/settings`)
              },
              ...(hasPermission(user, 'TESTSESSIONS_CREATE') && canWriteNamespace(user, user.namespacePermissions, t.namespace) ? [
                {
                  id: 'start',
                  icon: 'play',
                  name: 'Start FactCheck Test Session',
                  disabled: t.lastIndexSession && t.lastIndexSession.status === 'FAILED',
                  onClick: () => {
                    mutateStartFactCheckerProject({
                      variables: { id: t.id, debug: false }
                    })
                    this.setState({newTestSessionCount: this.state.newTestSessionCount + 1})
                  }
                },
                {
                  id: 'start_debug',
                  icon: 'bug',
                  name: 'Start FactCheck Test Session (Extended Logging)',
                  disabled: t.lastIndexSession && t.lastIndexSession.status === 'FAILED',
                  onClick: () => {
                    mutateStartFactCheckerProject({
                      variables: { id: t.id, debug: true }
                    })
                    this.setState({newTestSessionCount: this.state.newTestSessionCount + 1})
                  }
                }
              ] : [])
            ].filter(t => t)}>
          </DropdownButton>,
          () => t.lastFactCheckerSession?.testSession && <><div className={classes.resultListTestType}><TestSessionType ts={t.lastFactCheckerSession?.testSession} /></div> <div className={classes.newResultIcon}><NewResultIcon ts={t.lastFactCheckerSession.testSession} /></div></>,
          () => t.lastFactCheckerSession?.testSession && <><Text regular smLineHeight>Latest Test Run</Text><NavLink className={classes.projectLink} key={`${tIndex}`} to={_link(t.lastFactCheckerSession.testSession)} data-unique={`btnFactCheckerProjectMoveto_${tIndex}`}><DateFormat>{t.lastFactCheckerSession.testSession.updatedAt}</DateFormat></NavLink></>,
          t.lastFactCheckerSession?.testSession ? {
            value: <div><NewResultStats ts={t.lastFactCheckerSession?.testSession} type={'factchecker'} /></div>
          } : null,

        ])}
      {...rest}
    />
  }
}

export default compose(
  withRouter,
  withStyles(testsessionsStyle),
  connect(
    state => ({
      user: state.token.user,
      license: state.settings.license,
      namespace: state.namespace
    }),
    { getConnector, setAlertSuccessMessage, setAlertErrorMessage }
  ),
  graphql(START_FACTCHECKERPROJECT, {
    props: ({ mutate }) => ({
      mutateStartFactCheckerProject: args => mutate(args),
    }),
    options: (props) => ({
      onCompleted: (data) => {
        const testSessionId = data.startFactCheckerProject.id
        props.setAlertSuccessMessage('FactCheck Test session started ...')
        props.history.push('/factcheck/projects/view/' + data.startFactCheckerProject.factCheckerSession.project.id + '/results/' + testSessionId)
      },
      onError: (error) => {
        props.setAlertErrorMessage('FactCheck Test session failed', error)
      },
      refetchQueries: ({ data }) => [
        ...RefetchTestSessionQueries(data.startFactCheckerProject.id),
        ...(props.refetchQueriesForTestSession ? props.refetchQueriesForTestSession(data.startFactCheckerProject.id) : [])
      ],
      update: DeleteTestSessionListsFromCache
    })
  }),
  graphql(START_FACTCHECKERPROJECTS, {
    props: ({ mutate }) => ({
      mutateStartFactCheckerProjects: args => mutate(args),
    }),
    options: (props) => ({
      onCompleted: (data) => {
        props.setAlertSuccessMessage('FactCheck Sessions started ...')
      },
      onError: (error) => {
        props.setAlertErrorMessage('Starting FactCheck Sessions failed', error)
      },
      update: DeleteFactCheckerListsFromCache
    })
  }),
)(FactCheckerProjectsEmbeddedTable)
